import { createContext, useContext } from 'react';
import { Provider } from 'react-redux';

import { getGlobals } from '../../store/index.js';
import { CFFetcher } from '../cloud-functions.js';
import { Logger } from '../logger.js';

export interface GlobalsContextValue {
    logger: Logger;
    cff: CFFetcher;
}

export interface GlobalsProviderProps {
    globals: ReturnType<typeof getGlobals>;
    serverState?: any;
}

export const GlobalsContext = createContext<GlobalsContextValue>(null);

export const GlobalsProvider: FC<GlobalsProviderProps> = ({ children, globals, serverState }) => {
    const { logger, cff, store } = globals;

    return (
        <GlobalsContext.Provider value={{ logger, cff }}>
            <Provider store={store} stabilityCheck="never" serverState={serverState}>
                {children}
            </Provider>
        </GlobalsContext.Provider>
    );
};

export const useLogger = (): Logger => {
    return useContext(GlobalsContext).logger;
};

export const useFetcher = (): CFFetcher => {
    return useContext(GlobalsContext).cff;
};
