import { createAssetGetter } from '@labradorsports/assets';
import { footballlab } from '@labradorsports/constants';

export const Logo = createAssetGetter('img/football/logo.svg');

export const SiteConfig = {
    ...footballlab.SiteConfig,
    AppStoreUrl: 'https://apps.apple.com/il/app/hoops-lab/id1576985969',
    GooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.labradorsports.hoopslab&hl=en_US&gl=US',
    Logo,
    PlaybookDescription:
        'Free play designer to create and share your basketball playbook. Create offense, defense and clearing plays. Add moves, passes, and comments to help guide your players.',
};

export const GoogleAnalyticsID = 'G-G0V8KFQBPX';

export const Sports = footballlab.Sports;

export const SportsNames = {
    [Sports.HighSchoolFootball]: 'High-School Football',
    [Sports.CollegeFootball]: 'College Football',
};

export const PlayerPositions = {
    QUARTERBACK: 'QUARTERBACK',
    WIDE_RECEIVER: 'WIDE_RECEIVER',
    RUNNING_BACK: 'RUNNING_BACK',
    TIGHT_END: 'TIGHT_END',
    OFFENSIVE_LINEMAN: 'OFFENSIVE_LINEMAN',
    DEFENSIVE_LINEMAN: 'DEFENSIVE_LINEMAN',
    LINEBACKER: 'LINEBACKER',
    DEFENSIVE_BACK: 'DEFENSIVE_BACK',
    CENTER: 'CENTER',
    LEFT_TACKLE: 'LEFT_TACKLE',
    LEFT_GUARD: 'LEFT_GUARD',
    RIGHT_TACKLE: 'RIGHT_TACKLE',
    RIGHT_GUARD: 'RIGHT_GUARD',
};

export const PlayerPositionNames = {
    [PlayerPositions.QUARTERBACK]: 'Quarterback',
    [PlayerPositions.WIDE_RECEIVER]: 'Wide Receiver',
    [PlayerPositions.RUNNING_BACK]: 'Running Back',
    [PlayerPositions.OFFENSIVE_LINEMAN]: 'Offensive Lineman',
    [PlayerPositions.DEFENSIVE_LINEMAN]: 'Defensive Lineman',
    [PlayerPositions.LINEBACKER]: 'Linebacker',
    [PlayerPositions.DEFENSIVE_BACK]: 'Defensive Back',
    [PlayerPositions.TIGHT_END]: 'Tight End',
    [PlayerPositions.CENTER]: 'Center',
    [PlayerPositions.LEFT_TACKLE]: 'Left Tackle',
    [PlayerPositions.LEFT_GUARD]: 'Left Guard',
    [PlayerPositions.RIGHT_TACKLE]: 'Right Tackle',
    [PlayerPositions.RIGHT_GUARD]: 'Right Guard',
};

const footballPositions = {
    QUARTERBACK: 'QUARTERBACK',
    WIDE_RECEIVER: 'WIDE_RECEIVER',
    RUNNING_BACK: 'RUNNING_BACK',
    TIGHT_END: 'TIGHT_END',
    OFFENSIVE_LINEMAN: 'OFFENSIVE_LINEMAN',
    DEFENSIVE_LINEMAN: 'DEFENSIVE_LINEMAN',
    LINEBACKER: 'LINEBACKER',
    DEFENSIVE_BACK: 'DEFENSIVE_BACK',
};

export const SportPositions = {
    [Sports.HighSchoolFootball]: Object.values(footballPositions),
    [Sports.CollegeFootball]: Object.values(footballPositions),
};

export const PlayerRatingCategories = {};

export const PlayerRatingWeights = {};

export const PlayerRatingSections = [];

export const PlayTags = ['Offense', 'Defense', 'Press', 'Press Break', 'Out-of-Bounds'];

export const DrillSkillTags = ['Ball Handling', 'Read-and-React', 'Transition'];

export const DepthSections = {
    starters: 'starters',
};

export const DepthConfig: any = {
    [Sports.HighSchoolFootball]: {
        [DepthSections.starters]: {
            name: 'Starters',
            starters: 5,
        },
    },
};

export const DepthChartLayout = {
    [Sports.HighSchoolFootball]: [[DepthSections.starters]],
};

// No variation from roster display names
export const PlayerRoleNames = {
    [PlayerPositions.QUARTERBACK]: 'Quarterback',
    [PlayerPositions.WIDE_RECEIVER]: 'Wide Receiver',
    [PlayerPositions.RUNNING_BACK]: 'Running Back',
    [PlayerPositions.TIGHT_END]: 'Tight End',
    [PlayerPositions.CENTER]: 'Center',
    [PlayerPositions.LEFT_TACKLE]: 'Left Tackle',
    [PlayerPositions.LEFT_GUARD]: 'Left Guard',
    [PlayerPositions.RIGHT_TACKLE]: 'Right Tackle',
    [PlayerPositions.RIGHT_GUARD]: 'Right Guard',
};

export const TeamSizes = footballlab.TeamSizes;

export const AddonConfig = footballlab.AddonConfig;

export const ScoutingReportStats = {
    GAMES: 'GAMES',
    TOTAL_POINTS: 'TOTAL_POINTS',
    POINTS_PER_GAME: 'POINTS_PER_GAME',
    SCORING_MARGIN: 'SCORING_MARGIN',
    FG_MADE_ATTEMPTED: 'FG_MADE_ATTEMPTED',
    FG_PCT: 'FG_PCT',
    FG_PER_GAME: 'FG_PER_GAME',
    _3PT_MADE_ATTEMPTED: '_3PT_MADE_ATTEMPTED',
    _3PT_PCT: '_3PT_PCT',
    _3PT_PER_GAME: '_3PT_PER_GAME',
    FT_MADE_ATTEMPTED: 'FT_MADE_ATTEMPTED',
    FT_PCT: 'FT_PCT',
    FT_PER_GAME: 'FT_PER_GAME',
    REBOUND_TOTAL: 'REBOUND_TOTAL',
    REBOUND_PER_GAME: 'REBOUND_PER_GAME',
    REBOUND_MARGIN: 'REBOUND_MARGIN',
    ASSISTS_TOTAL: 'ASSISTS_TOTAL',
    ASSISTS_PER_GAME: 'ASSISTS_PER_GAME',
    TURNOVERS_TOTAL: 'TURNOVERS_TOTAL',
    TURNOVERS_PER_GAME: 'TURNOVERS_PER_GAME',
    TURNOVERS_MARGIN: 'TURNOVERS_MARGIN',
    ASSIST_TURNOVER_RATIO: 'ASSIST_TURNOVER_RATIO',
    POINTS_OFF_TURNOVERS: 'POINTS_OFF_TURNOVERS',
};

export const ScoutingReportStatDetails = {
    [ScoutingReportStats.GAMES]: {
        key: ScoutingReportStats.GAMES,
        name: 'Games',
        shortKey: 'GP',
    },
    [ScoutingReportStats.TOTAL_POINTS]: {
        key: ScoutingReportStats.TOTAL_POINTS,
        shortKey: 'PTS',
        name: 'Total Points',
    },
    [ScoutingReportStats.POINTS_PER_GAME]: {
        key: ScoutingReportStats.POINTS_PER_GAME,
        name: 'Points Per Game',
        formula: [ScoutingReportStats.TOTAL_POINTS, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.SCORING_MARGIN]: {
        key: ScoutingReportStats.SCORING_MARGIN,
        name: 'Scoring Margin',
    },
    [ScoutingReportStats.FG_MADE_ATTEMPTED]: {
        key: ScoutingReportStats.FG_MADE_ATTEMPTED,
        name: 'FG: Made-Attempted',
        format: '-',
    },
    [ScoutingReportStats.FG_PCT]: {
        key: ScoutingReportStats.FG_PCT,
        name: 'FG: Percentage',
        formula: ['a-b', ScoutingReportStats.FG_MADE_ATTEMPTED, 'a', '/', 'b'],
    },
    [ScoutingReportStats.FG_PER_GAME]: {
        key: ScoutingReportStats.FG_PER_GAME,
        name: 'FG: Per Game',
        formula: ['a-b', ScoutingReportStats.FG_MADE_ATTEMPTED, 'a', '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats._3PT_MADE_ATTEMPTED]: {
        key: ScoutingReportStats._3PT_MADE_ATTEMPTED,
        name: '3PT: Made-Attempted',
        format: '-',
    },
    [ScoutingReportStats._3PT_PCT]: {
        key: ScoutingReportStats._3PT_PCT,
        name: '3PT: Percentage',
        formula: ['a-b', ScoutingReportStats._3PT_MADE_ATTEMPTED, 'a', '/', 'b'],
    },
    [ScoutingReportStats._3PT_PER_GAME]: {
        key: ScoutingReportStats._3PT_PER_GAME,
        name: '3PT: Per Game',
        formula: ['a-b', ScoutingReportStats._3PT_MADE_ATTEMPTED, 'a', '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.FT_MADE_ATTEMPTED]: {
        key: ScoutingReportStats.FT_MADE_ATTEMPTED,
        name: 'FT: Made-Attempted',
        format: '-',
    },
    [ScoutingReportStats.FT_PCT]: {
        key: ScoutingReportStats.FT_PCT,
        name: 'FT: Percentage',
        formula: ['a-b', ScoutingReportStats.FT_MADE_ATTEMPTED, 'a', '/', 'b'],
    },
    [ScoutingReportStats.FT_PER_GAME]: {
        key: ScoutingReportStats.FT_PER_GAME,
        name: 'FT: Per Game',
        formula: ['a-b', ScoutingReportStats.FT_MADE_ATTEMPTED, 'a', '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.REBOUND_TOTAL]: {
        key: ScoutingReportStats.REBOUND_TOTAL,
        shortKey: 'RB',
        name: 'Total Rebounds',
    },
    [ScoutingReportStats.REBOUND_PER_GAME]: {
        key: ScoutingReportStats.REBOUND_PER_GAME,
        name: 'Rebounds Per Game',
        formula: [ScoutingReportStats.REBOUND_TOTAL, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.REBOUND_MARGIN]: {
        key: ScoutingReportStats.REBOUND_MARGIN,
        name: 'Margin',
    },
    [ScoutingReportStats.ASSISTS_TOTAL]: {
        key: ScoutingReportStats.ASSISTS_TOTAL,
        shortKey: 'AST',
        name: 'Total Assists',
    },
    [ScoutingReportStats.ASSISTS_PER_GAME]: {
        key: ScoutingReportStats.ASSISTS_PER_GAME,
        name: 'Assists Per Game',
        formula: [ScoutingReportStats.ASSISTS_TOTAL, '/', ScoutingReportStats.GAMES],
    },
    [ScoutingReportStats.TURNOVERS_TOTAL]: {
        key: ScoutingReportStats.TURNOVERS_TOTAL,
        shortKey: 'TO',
        name: 'Total Turnovers',
        lowerIsBetter: true,
    },
    [ScoutingReportStats.TURNOVERS_PER_GAME]: {
        key: ScoutingReportStats.TURNOVERS_PER_GAME,
        name: 'Turnovers Per Game',
        formula: [ScoutingReportStats.TURNOVERS_TOTAL, '/', ScoutingReportStats.GAMES],
        lowerIsBetter: true,
    },
    [ScoutingReportStats.TURNOVERS_MARGIN]: {
        key: ScoutingReportStats.TURNOVERS_MARGIN,
        name: 'Margin',
    },
    [ScoutingReportStats.ASSIST_TURNOVER_RATIO]: {
        key: ScoutingReportStats.ASSIST_TURNOVER_RATIO,
        name: 'Assist/Turnover Ratio',
        formula: [ScoutingReportStats.ASSISTS_TOTAL, '/', ScoutingReportStats.TURNOVERS_TOTAL],
    },
    [ScoutingReportStats.POINTS_OFF_TURNOVERS]: {
        key: ScoutingReportStats.POINTS_OFF_TURNOVERS,
        name: 'Points Off Turnovers',
    },
};

const bballScoutingReportStats = [
    {
        categoryName: 'PREVIEW',
        stats: [ScoutingReportStats.GAMES, ScoutingReportStats.TOTAL_POINTS, ScoutingReportStats.POINTS_PER_GAME],
    },
    {
        categoryName: 'Scoring',
        stats: [
            ScoutingReportStats.GAMES,
            ScoutingReportStats.TOTAL_POINTS,
            ScoutingReportStats.POINTS_PER_GAME,
            ScoutingReportStats.SCORING_MARGIN,
        ],
    },
    {
        categoryName: 'Shooting',
        stats: [
            ScoutingReportStats.FG_MADE_ATTEMPTED,
            ScoutingReportStats.FG_PCT,
            ScoutingReportStats.FG_PER_GAME,
            ScoutingReportStats._3PT_MADE_ATTEMPTED,
            ScoutingReportStats._3PT_PCT,
            ScoutingReportStats._3PT_PER_GAME,
            ScoutingReportStats.FT_MADE_ATTEMPTED,
            ScoutingReportStats.FT_PCT,
            ScoutingReportStats.FT_PER_GAME,
        ],
    },
    {
        categoryName: 'Rebounding',
        stats: [
            ScoutingReportStats.REBOUND_TOTAL,
            ScoutingReportStats.REBOUND_PER_GAME,
            ScoutingReportStats.REBOUND_MARGIN,
        ],
    },
    {
        categoryName: 'Assists',
        stats: [ScoutingReportStats.ASSISTS_TOTAL, ScoutingReportStats.ASSISTS_PER_GAME],
    },
    {
        categoryName: 'Turnovers',
        stats: [
            ScoutingReportStats.TURNOVERS_TOTAL,
            ScoutingReportStats.TURNOVERS_PER_GAME,
            ScoutingReportStats.TURNOVERS_MARGIN,
            ScoutingReportStats.ASSIST_TURNOVER_RATIO,
            ScoutingReportStats.POINTS_OFF_TURNOVERS,
        ],
    },
];

export const ScoutingReportStatDisplay = {
    [Sports.HighSchoolFootball]: bballScoutingReportStats,
};

export const PersonnelQuickStats = [
    ScoutingReportStats.GAMES,
    ScoutingReportStats.TOTAL_POINTS,
    ScoutingReportStats.ASSISTS_TOTAL,
    ScoutingReportStats.REBOUND_TOTAL,
    ScoutingReportStats.TURNOVERS_TOTAL,
];

export const Features = footballlab.Features;

const Config: ConfigSpec = {
    SiteConfig,
    GoogleAnalyticsID,
    Sports,
    PlayTags,
    DrillSkillTags,
    SportsNames,
    PlayerPositions,
    PlayerRatingWeights,
    DepthSections,
    DepthConfig,
    DepthChartLayout,
    PlayerPositionNames,
    PlayerRatingCategories,
    PlayerRatingSections,
    SportPositions,
    PlayerRoleNames,
    TeamSizes,
    AddonConfig,
    ScoutingReportStats,
    ScoutingReportStatDetails,
    ScoutingReportStatDisplay,
    PersonnelQuickStats,
    Features,
};

export default Config;
