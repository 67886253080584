import { createSlice } from '@reduxjs/toolkit';

export type MessagingState = any;

const messagingDefaultState: MessagingState = {};

const { reducer: messagingReducer, actions: messagingActions } = createSlice({
    name: 'messaging',
    initialState: messagingDefaultState,
    reducers: {},
});

export { messagingReducer, messagingActions, messagingDefaultState };
