import { HighlightedSearchText } from '@labradorsports/components';
import { findMatchedField } from '@labradorsports/utils';

import { ProfilePicture, useNavigateWithQuery } from '../../../shared/index.js';

interface Props {
    result: any;
    onClick: (result: any) => void;
}

const mappedFieldName = {
    email: 'Author Email',
    streetAddress: 'Event Address',
    city: 'Event City',
    state: 'Event State',
    zipCode: 'Event Zip',
    locationName: 'Event Location',
};

const getMatchedField = (highlightResult: any) => {
    const matchedField = findMatchedField(highlightResult);

    if (!matchedField) {
        return null;
    }

    const showMatchedField = !['firstName', 'lastName', 'date', 'content'].includes(matchedField.key);

    if (!showMatchedField) {
        return null;
    }

    return {
        name: mappedFieldName[matchedField.key],
        value: matchedField.entry.value,
    };
};

const PostSearchResult: FC<Props> = ({ result, onClick }) => {
    const navigate = useNavigateWithQuery();

    const showEvent = () => {
        onClick(result);
        navigate('/feed/post', {
            activeTeam: result.teamId,
            viewPostId: result.postId,
        });
    };

    const matchedField = getMatchedField(result._highlightResult);

    return (
        <div className="post-search-result search-result" onClick={showEvent}>
            <ProfilePicture uid={result.author} />
            <div className="flex-grow-1">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <HighlightedSearchText text={result._highlightResult.firstName.value} />{' '}
                        <HighlightedSearchText text={result._highlightResult.lastName.value} />
                    </div>
                    <div>
                        <HighlightedSearchText text={result._highlightResult.date.value} />
                    </div>
                </div>
                <div>
                    <HighlightedSearchText text={result._highlightResult.content.value} />
                </div>
                {matchedField ? (
                    <div>
                        {matchedField.name}: <HighlightedSearchText text={matchedField.value} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default PostSearchResult;
