import { useEffect, useRef, useState } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { Checkbox, StyledTextField } from '@labradorsports/components';
import { getAllowedFolderOperations, getAllowedPlayOperations, noPropagation } from '@labradorsports/utils';

import { PlayType } from '../../shared/constants.js';
import { useDispatcher } from '../../shared/hooks/index.js';
import {
    RootState,
    Selectors,
    ShareConfig,
    playbookActions,
    useRenameFolderMutation,
    useRenamePlayMutation,
} from '../../store/index.js';
import PlayListFolder from '../play-list-folder/play-list-folder.js';
import PlayListPlay from '../play-list-play/play-list-play.js';

interface Props {
    item: any;
    readOnly: boolean;
    playlist: any[];
    onClick?: AnyFunction;
    unshareClick?: AnyFunction;
    moveToFolder?: AnyFunction;
    deleteClick?: AnyFunction;
    activeTags?: string[];
    playbookType?: string;
    copyPlay?: AnyFunction;
    onSelect?: AnyFunction;
    selectedItems?: string[];
    hideDropdown?: boolean;
    itemDrop?: (item: any, position: string) => void;
    startShare?: (shareConfig?: ShareConfig) => void;
}

const PlayListItem: FC<Props> = ({
    item,
    readOnly,
    onClick,
    deleteClick,
    copyPlay,
    moveToFolder,
    playbookType,
    activeTags,
    onSelect,
    selectedItems,
    hideDropdown,
    unshareClick,
    playlist,
    itemDrop,
    startShare,
}) => {
    const [renamePlay] = useRenamePlayMutation();
    const [renameFolder] = useRenameFolderMutation();
    const renameItemId = useSelector((state: RootState) => state.playbook.renameItemId);
    const openPlayId = useSelector((state: RootState) => state.playEditor.play?.id);
    const setRenameItemId = useDispatcher(playbookActions.SetRenameItemId);
    const team = useSelector(Selectors.currentTeam);
    const teamRole = useSelector(Selectors.currentUserTeamRole);

    const ref = useRef<HTMLDivElement>(null);
    const nameRef = useRef<HTMLInputElement>();
    const dropdownMenuRef = useRef<HTMLDivElement>(null);
    const [hover, setHover] = useState(null);
    const [editName, setEditName] = useState(item.name);
    const [hasError, setHasError] = useState(false);
    const [pressHeld, setPressHeld] = useState(false);
    const pressHeldTimer = useRef<number>();

    const isPlay = Boolean(item.sport);

    const canDrag = pressHeld && itemDrop && !readOnly;

    const [dragProps, drag, preview] = useDrag({
        type: PlayType,
        item: () => {
            if (selectedItems.length > 0 && selectedItems.includes(item.id)) {
                return {
                    selectedItems,
                };
            }

            return {
                ...item,
            };
        },
        canDrag,
        end: () => {
            setPressHeld(false);
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const getHoverPosition = (offset: XYCoord) => {
        const elOffset = ref.current.getBoundingClientRect();
        const hoverY = offset.y - elOffset.top;

        if (isPlay) {
            const above = hoverY < elOffset.height / 2;

            return above ? 'top' : 'bottom';
        } else {
            const portion = Math.floor((hoverY * 3) / elOffset.height);

            return ['top', 'middle', 'bottom'][portion];
        }
    };

    const onHover = (offset: XYCoord, item: any) => {
        setHover(getHoverPosition(offset));
    };

    const [dropProps, drop] = useDrop({
        accept: PlayType,
        drop: (dropItem: any, monitor) => {
            itemDrop(dropItem, getHoverPosition(monitor.getClientOffset()));

            return {};
        },
        hover: (dropItem, monitor) => {
            if (monitor.canDrop()) {
                onHover(monitor.getClientOffset(), monitor.getItem());
            }
        },
        canDrop: (dropItem) => {
            if (dropItem.selectedItems) {
                return !dropItem.selectedItems.includes(item.id);
            }

            return dropItem.id !== item.id;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    useEffect(() => {
        preview(getEmptyImage());
    }, []);

    useEffect(() => {
        // Remove hover state if we aren't over this container
        if (hover && !dropProps.isOver) {
            setHover(null);
        }
    }, [dropProps.isOver, hover]);

    const classNames = ['play-list-item', 'list-item'];

    if (openPlayId === item.id) {
        classNames.push('active');
    }

    if (hover) {
        classNames.push(`hovered-${hover}`);
    }

    if (canDrag && !dragProps.isDragging) {
        classNames.push('dragging');
    }

    const nameFocus = () => {
        if (nameRef.current) {
            nameRef.current.select();
        }
    };

    const multiRef = (el) => {
        ref.current = el;
        drag(el);
        drop(el);
    };

    const validateName = (name = '') => {
        return name.length > 0 && name.length < 40;
    };

    const onRename = () => {
        setRenameItemId(item.id);
    };

    const cancelRename = () => {
        setRenameItemId();
    };

    const trySubmit = async () => {
        setHasError(false);
        if (validateName(editName)) {
            if (isPlay) {
                await renamePlay({ playId: item.id, name: editName });
            } else {
                await renameFolder({
                    folderId: item.id,
                    folderName: editName,
                });
            }

            cancelRename();
        } else {
            setHasError(true);
        }
    };

    const pointerDown = (evt) => {
        setPressHeld(false);

        const dropdownMenu = ref.current.querySelector('.dropdown-menu-container');

        if (!dropdownMenu?.contains(evt.target)) {
            pressHeldTimer.current = window.setTimeout(() => {
                setPressHeld(true);

                pressHeldTimer.current = null;
            }, 250);
        }
    };

    const pointerUp = () => {
        if (pressHeldTimer.current) {
            window.clearTimeout(pressHeldTimer.current);
        }

        setPressHeld(false);
    };

    const pointerMove = (evt) => {
        const { movementX, movementY } = evt.nativeEvent;
        if (pressHeldTimer.current && !dragProps.isDragging && (Math.abs(movementX) > 2 || Math.abs(movementY) > 2)) {
            pointerUp();
        }
    };

    const getMenuPosition = (menuHeight: number) => {
        const el = ref.current;
        if (el) {
            const { parentElement } = el;
            const parentTop = parentElement.offsetTop;
            const elTop = el.getBoundingClientRect().top;
            const halfVisibleHeight = document.body.offsetHeight / 2 - parentTop;
            const elementVisibleMidpoint = elTop + el.offsetHeight / 2;
            const relativeTop = el.offsetTop - parentElement.offsetTop;

            // Force bottom position if top would overflow the container at the top
            if (relativeTop < menuHeight) {
                return 'bottom';
            }

            if (elementVisibleMidpoint > halfVisibleHeight) {
                return 'top';
            }
        }

        return 'bottom';
    };

    if (Boolean(renameItemId) && item.id === renameItemId) {
        return (
            <div className="play-list-item renaming p-2">
                <StyledTextField
                    label={`${isPlay ? 'Play' : 'Folder'}  Name`}
                    value={editName}
                    onChange={(evt: any) => setEditName(evt.target.value)}
                    helperText={hasError && 'Enter a name less than 40 characters long'}
                    error={hasError}
                    inputRef={nameRef}
                    onFocus={nameFocus}
                    fullWidth
                />
                <div className="text-end">
                    <Button className="me-2" onClick={cancelRename}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={trySubmit}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }

    const checkboxClick = noPropagation(() => {
        onSelect();
    });

    const sharedProps = {
        playbookType,
        readOnly,
        onClick,
        deleteClick,
        hideDropdown,
        moveToFolder,
        onRename,
        getMenuPosition,
        dropdownMenuRef,
        startShare,
        unshareClick,
    };

    const childEl = item.sport ? (
        <PlayListPlay play={item} copyPlay={copyPlay} {...sharedProps} />
    ) : (
        <PlayListFolder folder={item} playlist={playlist} activeTags={activeTags} {...sharedProps} />
    );

    const permissionsForItem = item.sport
        ? getAllowedPlayOperations(team, item, readOnly, playbookType, teamRole)
        : getAllowedFolderOperations(team, readOnly, playbookType, teamRole);

    const showCheckbox = Object.values(permissionsForItem).some((perm) => perm);

    return (
        <div
            className={classNames.join(' ')}
            ref={multiRef}
            onPointerDown={pointerDown}
            onPointerUp={pointerUp}
            onPointerMove={pointerMove}
        >
            {onSelect && showCheckbox ? (
                <div onClick={checkboxClick} className="checkbox-wrapper">
                    <Checkbox checked={selectedItems?.includes(item.id)} />
                </div>
            ) : null}
            {childEl}
        </div>
    );
};

export default PlayListItem;
