import { createAssetGetter } from '@labradorsports/assets';
import * as Constants from '@labradorsports/constants';
import { Sites } from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';

import BoxGoal from './box-goal.js';
import BoxFullFieldBackdrop from './field-backdrop/box-full-field-backdrop.js';
import MensFullFieldBackdrop from './field-backdrop/mens-full-field-backdrop.js';
import WomensFullFieldBackdrop from './field-backdrop/womens-full-field-backdrop.js';
import Goal from './goal.js';

const { Sports, PlayerPositions } = SportConfigs[Sites.lacrosselab];

const HotIconWhite = createAssetGetter('img/lacrosse/HotIcon.svg');
const HotIcon = createAssetGetter('img/lacrosse/HotSlide.svg');

const FieldDescriptor = 'Field';

const PlayTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    DRILL: 'DRILL',
};

const FieldTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    CUSTOM: 'CUSTOM',
};

const FieldBackdropMap = {
    [Sports.MensLax]: MensFullFieldBackdrop,
    [Sports.WomensLax]: WomensFullFieldBackdrop,
    [Sports.BoxLax]: BoxFullFieldBackdrop,
};

const GoalDesign = {
    [Sports.MensLax]: Goal,
    [Sports.WomensLax]: Goal,
    [Sports.BoxLax]: BoxGoal,
};

const Formations = {
    _231: '_231',
    _33: '_33',
    _222: '_222',
    _141: '_141',
    Circle: 'Circle',

    WomensCircle: 'WomensCircle',
    _232: '_232',

    _3out2in: '_3out2in',
    _4out1in: '_4out1in',
    _5out: '_5out',
};

const ToolTypes = {
    ...Constants.ToolTypes,
    PICK: 'PICK',
    GROUNDBALL: 'GROUNDBALL',
    MOVE2: 'MOVE2',

    ...Formations,

    // Included for legacy plays
    /** @deprecated */
    ROLLBACK: 'ROLLBACK',
    /** @deprecated */
    DODGE: 'DODGE',
};

const FormationDefinitions = {
    [Formations._231]: {
        name: '2-3-1',
        sport: Sports.MensLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['offensive', 'structured', 'traditional', 'intermediate', 'settled'],
        description:
            "A flexible offensive formation with 5 players spaced around the perimeter and one inside 'post' player. This forms several triangles in the formation, most prominently a triangle of midfielders up top and a triangle of attackmen down low.",
        initial: [
            { origin: { x: -12.5, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 12.5, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -20, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: 10 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._33]: {
        name: '3-3',
        sport: Sports.MensLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['balanced', 'versatile', 'traditional', 'intermediate', 'settled'],
        description:
            'An offensive formation where all 6 players on the offense are above the goal line and are offensive threats. This is most commonly used as an entry or finishing formation on offensive power-plays.',
        initial: [
            { origin: { x: -20, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -20, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -5 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._222]: {
        name: '2-2-2',
        sport: Sports.MensLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['structured', 'methodical', 'possession', 'advanced', 'settled'],
        description:
            "Also commonly referred to as 'dueces', is a formation with a box perimeter, and two inside 'post' men. The formation allows for a variety of 2-man game set-ups.",
        initial: [
            { origin: { x: -15, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 15, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -5, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 5, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -15, y: 10 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 15, y: 10 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._141]: {
        name: '1-4-1',
        sport: Sports.MensLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['midfield-focused', 'dynamic', 'modern', 'advanced', 'settled'],
        description:
            "An offensive formation emphasizing midfield play, shaped as a diamond on the perimeter and two 'post' players. With 4 points on the perimeter, there is space to dodge from all over the field.",
        initial: [
            { origin: { x: 0, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -20, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -5, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 5, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: 10 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations.Circle]: {
        name: "Men's Circle",
        sport: Sports.MensLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['motion', 'fluid', 'creative', 'expert', 'settled'],
        description:
            'A fluid offensive formation with players arranged around the offensive zone and no interior players. This allows for easy adjacent passes and cutting opportunities. It is a good change of pace look to force adjacent slides.',
        initial: [
            { origin: { x: -20, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -20, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: 10 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -5 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations.WomensCircle]: {
        name: "Women's Circle",
        sport: Sports.WomensLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['motion', 'spacing', 'creative', 'expert', 'settled'],
        description:
            'A fluid offensive formation with players arranged around the offensive zone and no interior players. This allows for easy adjacent passes and cutting opportunities. It is a good change of pace look to force adjacent slides.',
        initial: [
            { origin: { x: 0, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -10, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 10, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -15, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 15, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -7.5, y: 5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 7.5, y: 5 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._232]: {
        name: '2-3-2',
        sport: Sports.WomensLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['balanced', 'structured', 'traditional', 'intermediate', 'settled'],
        description:
            "A balanced formation for women's lacrosse with a circle around the perimeter and one 'post' player, improving adjacent dodging space.",
        initial: [
            { origin: { x: -10, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 10, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -15, y: -10 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 15, y: -10 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -10, y: 5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 10, y: 5 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._3out2in]: {
        name: '3 out 2 in',
        sport: Sports.BoxLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['box-specific', 'inside-out', 'power', 'intermediate', 'settled'],
        description:
            'A box lacrosse formation featuring three outside players and two inside players, designed to create both perimeter and interior scoring opportunities. This formation is particularly effective for teams with strong inside finishers and outside shooters.',
        initial: [
            { origin: { x: 0, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -10, y: -6 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -6 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 10, y: -6 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: 3 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._4out1in]: {
        name: '4 out 1 in',
        sport: Sports.BoxLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['box-specific', 'perimeter-oriented', 'modern', 'advanced', 'settled'],
        description:
            'A modern box lacrosse formation with four perimeter players and one inside player, maximizing spacing in the tight box environment. This formation is ideal for teams with quick ball movement and strong outside shooting, while maintaining an inside threat.',
        initial: [
            { origin: { x: -7.5, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 7.5, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -10 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -10, y: -3 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 10, y: -3 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._5out]: {
        name: '5 out',
        sport: Sports.BoxLax,
        anchorType: ToolTypes.GOAL,
        attributes: ['box-specific', 'motion', 'spread', 'expert', 'settled'],
        description:
            'An aggressive box lacrosse formation with all five players on the perimeter, creating maximum spacing and movement opportunities. This formation requires highly skilled players who can shoot and create from the outside, while also being able to cut and finish inside.',
        initial: [
            { origin: { x: 0, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -10, y: -10 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 10, y: -10 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -12, y: -3 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 12, y: -3 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
};

// Dimensions in yards
const FieldSettings: DefaultFieldConfig = {
    [Sports.MensLax]: {
        width: 60,
        height: 110,
        sideline: {
            right: 2,
        },
        center: {
            x: 30,
            y: 55,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [
                {
                    origin: { x: 30, y: 40 },
                    rotation: 0,
                    goalieVisible: true,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            [FieldTypes.FULL_FIELD]: [
                {
                    origin: { x: 30, y: 95 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 30, y: 15 },
                    rotation: 180,
                    goalieVisible: true,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
        },
        zoomLevels: [1, 2, 3],
    },
    [Sports.WomensLax]: {
        width: 70,
        height: 120,
        sideline: {
            right: 2,
        },
        center: {
            x: 35,
            y: 60,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [
                {
                    origin: { x: 35, y: 45 },
                    rotation: 0,
                    goalieVisible: true,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            [FieldTypes.FULL_FIELD]: [
                {
                    origin: { x: 35, y: 105 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 35, y: 15 },
                    rotation: 180,
                    goalieVisible: true,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
        },
        zoomLevels: [1, 2, 3],
    },
    [Sports.BoxLax]: {
        width: 30,
        height: 66,
        sideline: {
            right: 2,
        },
        center: {
            x: 15,
            y: 33,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [
                {
                    origin: { x: 15, y: 28 },
                    rotation: 0,
                    goalieVisible: true,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            [FieldTypes.FULL_FIELD]: [
                {
                    origin: { x: 15, y: 61 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 15, y: 5 },
                    rotation: 180,
                    goalieVisible: true,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
        },
        zoomLevels: [1, 2, 3],
    },
};

const FeatureFlags = {
    EnableGoalie: true,
    EnableCustomField: true,
    OnionSkin: false,
};

const NewPlaySettings = {
    FrameCount: 1,
};

const FrameRoles = {
    HOT: 'HOT',
    ONBALL: 'ONBALL',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TWO: 'TWO',
    THREE: 'THREE',
};

const MoveConfig: MoveConfigType = {
    ...Constants.MoveConfig,
    [ToolTypes.PICK]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER],
        head: 'line',
    },
    [ToolTypes.GROUNDBALL]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        strokeDashArray: '2, 4',
        shorten: 12,
    },
    [ToolTypes.MOVE2]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        pattern: 'WAVE',
    },
};

const RoleDisplay = {
    [FrameRoles.ONBALL]: 'B',
    [FrameRoles.LEFT]: 'L',
    [FrameRoles.RIGHT]: 'R',
    [FrameRoles.TWO]: '2',
    [FrameRoles.THREE]: '3',
    [FrameRoles.HOT]: {
        url: HotIcon,
        urlWhite: HotIconWhite,
    },

    [PlayerPositions.ATTACK]: 'A',
    [PlayerPositions.DEFENSE]: 'D',
    [PlayerPositions.GOALKEEPER]: 'G',
    [PlayerPositions.LSM]: 'LS',
    [PlayerPositions.MIDFIELD]: 'M',
    [PlayerPositions.FACEOFF]: 'FO',
};

const FrameRoleNames = {
    [ToolTypes.ORANGEPLAYER]: {
        [FrameRoles.ONBALL]: 'On-Ball',
        [FrameRoles.HOT]: 'Hot Slide',
        [FrameRoles.LEFT]: 'Left',
        [FrameRoles.RIGHT]: 'Right',
        [FrameRoles.TWO]: '2-Slide',
        [FrameRoles.THREE]: '3-Slide',
    },
};

const FieldLines = {
    GOAL: 'Goal',
    FOOTBALL: 'Football',
};

const PlayConfig: PlayConfigSpec = {
    Site: Sites.lacrosselab,
    PlayTypes,
    FieldTypes,
    FieldDescriptor,
    GoalDesign,
    FieldSettings,
    FeatureFlags,
    NewPlaySettings,
    ToolTypes,
    MoveConfig,
    FrameRoles,
    FrameRoleNames,
    RoleDisplay,
    FieldLines,
    Formations,
    FieldBackdropMap,
    MovementSpeed: 10,
    FormationDefinitions,
};

export default PlayConfig;
