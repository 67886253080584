import { applyTimezoneOffset, objectPathMatches, processObjectPaths } from './utils.js';

export const serializeDate = (dateStr: string) => applyTimezoneOffset(new Date(dateStr));

export const deserializeDate = (value) => applyTimezoneOffset(new Date(value), 240);

const processDate = { serialize: serializeDate, deserialize: deserializeDate };

const fieldProcessors = {
    'api.queries.getCommunityPlay(*).data.sharedOn': processDate,
    'api.queries.getCommunityPlay(*).data.createdDate': processDate,
    'api.queries.getCommunityProfile(*).data.sharedPlays[*].sharedOn': processDate,
    'api.queries.getCommunityProfile(*).data.sharedPlays[*].createdDate': processDate,
    'api.queries.getCommunityPlayComments(*).data[*].createdDate': processDate,
    'api.queries.getCommunityPlaybook(*).data.plays[*].sharedOn': processDate,
    'api.queries.getCommunityPlaybook(*).data.plays[*].createdDate': processDate,
    'api.queries.getCommunityHomepage(*).data.newPlays[*].sharedOn': processDate,
    'api.queries.getCommunityHomepage(*).data.newPlays[*].createdDate': processDate,
    'api.queries.getCommunityHomepage(*).data.popularPlays[*].sharedOn': processDate,
    'api.queries.getCommunityHomepage(*).data.popularPlays[*].createdDate': processDate,
    'api.queries.getCommunityHomepage(*).data.trendingPlays[*].sharedOn': processDate,
    'api.queries.getCommunityHomepage(*).data.trendingPlays[*].createdDate': processDate,
};

export const stateSerializers = Object.fromEntries(
    Object.entries(fieldProcessors).map(([k, { serialize }]) => [k, serialize])
);
export const stateDeserializers = Object.fromEntries(
    Object.entries(fieldProcessors).map(([k, { deserialize }]) => [k, deserialize])
);

// Manipulates the object argument directly!
export function serializeObjectPaths(
    object: any,
    serializers: {
        [k: string]: (value: any) => any;
    }
) {
    const serializersArray = Object.entries(serializers);

    return processObjectPaths(object, (val, pathParts) => {
        const [, serializer] = serializersArray.find(([pathSpec]) => objectPathMatches(pathSpec, pathParts)) ?? [];

        if (serializer) {
            return serializer(val);
        }

        return val;
    });
}
