import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authActions } from '../auth/index.js';

export interface TeamsState {
    tempTeams: any[];
    tempRatings: any;
    activeRatingId: string;
    depthChartModified: boolean;
}

const teamsDefaultState: TeamsState = {
    tempTeams: undefined,
    tempRatings: undefined,
    activeRatingId: undefined,
    depthChartModified: false,
};

const { reducer: teamsReducer, actions: teamsActions } = createSlice({
    name: 'teams',
    initialState: teamsDefaultState,
    reducers: {
        SetTempRatings: {
            prepare: (payload?: any) => ({ payload }),
            reducer: (state, action: PayloadAction<any>) => {
                state.tempRatings = action.payload;
            },
        },

        SetActiveRatingId: (state, action) => {
            state.activeRatingId = action.payload;
        },

        DepthChartModified: (state, action) => {
            state.depthChartModified = action.payload;
        },
    },
    extraReducers: (builder) => builder.addCase(authActions.PurgeProfile, () => teamsDefaultState),
});

export { teamsReducer, teamsActions, teamsDefaultState };
