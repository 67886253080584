import { Quill } from 'react-quill-new';
import { Root, TextBlot } from 'parchment';
import InlineBlot from 'quill/blots/inline';
import Tooltip from 'quill/ui/tooltip';

interface RosterPlayerData {
    number: string;
    className?: string;
    added?: boolean;
}

export class RosterPlayerBlot extends InlineBlot {
    static blotName = 'roster-player';
    static tagName = 'span';
    static className = 'roster-player';

    static create(value: RosterPlayerData): HTMLElement {
        const node = super.create(value) as HTMLElement;

        node.setAttribute('class', value.className ?? 'roster-player');

        if (value.added) {
            node.setAttribute('data-added', 'true');
        }

        return node;
    }

    static value(node: HTMLElement): RosterPlayerData {
        if (['roster-player-pill', 'roster-player'].includes(node.className)) {
            return {
                number: node.textContent,
                className: node.className,
                added: node.getAttribute('data-added') === 'true',
            };
        }

        return null;
    }

    static formats(node: HTMLElement, scroll: Root): { number: string } {
        if (node.textContent.trim() === '') return super.formats(node, scroll);

        return {
            number: node.textContent,
        };
    }
}

class RosterPlayerTooltip extends Tooltip {
    static TEMPLATE = [
        '<a class="roster-player-ignore">Ignore</a>',
        '<a class="roster-player-add">Add Personnel</a>',
    ].join('');

    activePlayer: string;

    showForPill(pill: RosterPlayerBlot, index: number) {
        if (pill != null) {
            const value = RosterPlayerBlot.value(pill.domNode);

            if (!value.added) {
                this.activePlayer = value.number;
                this.show();
                this.position(this.quill.getBounds(index));
                return;
            }
        }

        this.hide();
    }

    listen() {
        this.root.querySelector('a.roster-player-ignore').addEventListener('click', (event: any) => {
            event.preventDefault();
            this.hide();
        });

        this.root.querySelector('a.roster-player-add').addEventListener('click', (event: any) => {
            this.quill.emitter.emit('roster-player-add-click', this.activePlayer.replace('#', ''));
            event.preventDefault();
            this.hide();
        });

        this.quill.on('selection-change', (range: any, oldRange: any, source: any) => {
            if (range == null) return;
            if (range.length === 0 && source === 'user') {
                const [pill] = this.quill.scroll.descendants(RosterPlayerBlot, range.index - 1);
                this.showForPill(pill, range.index);
            }
        });

        this.quill.on('text-change', (delta: any, old: any, source: string) => {
            const range = this.quill.getSelection();
            if (range == null) return;
            if (range.length === 0) {
                const [beforePill] = this.quill.scroll.descendants(RosterPlayerBlot, range.index - 1);
                this.showForPill(beforePill, range.index);
            }
        });
    }
}

export default class RosterPlayer {
    quill: InstanceType<typeof Quill>;

    tooltip: RosterPlayerTooltip;

    options: any;

    constructor(quill: InstanceType<typeof Quill>, options: any) {
        this.quill = quill;
        this.options = options;
        this.registerTypeListener();
        this.tooltip = new RosterPlayerTooltip(quill, quill.container);
        this.tooltip.listen();
    }

    updateAddedPlayers(): void {
        const addedPlayers = this.options?.getAddedPlayers?.() ?? [];

        const blots = this.quill.scroll.descendants(RosterPlayerBlot);
        blots.forEach((blot: RosterPlayerBlot) => {
            const blotJersey = blot.domNode.textContent.substring(1);
            if (blotJersey !== '' && addedPlayers.find((plr: any) => plr.jersey === blotJersey)) {
                blot.domNode.setAttribute('data-added', 'true');
            } else {
                blot.domNode.setAttribute('data-added', 'false');
            }
        });
    }

    registerTypeListener(): void {
        this.quill.on('text-change', (delta, old, source) => {
            const { ops } = delta;

            const addedPlayers = this.options?.getAddedPlayers?.() ?? [];

            if (!ops || ops.length < 1) {
                return;
            }

            const lastOp = ops[ops.length - 1];

            if (source === 'user') {
                if (
                    lastOp.insert &&
                    lastOp.attributes?.['roster-player'] &&
                    (lastOp.attributes['roster-player'] as RosterPlayerData).number !== ''
                ) {
                    const number = (lastOp.attributes['roster-player'] as RosterPlayerData).number
                        .trim()
                        .replace('#', '');

                    if (addedPlayers.some((plr: any) => plr.jersey === number)) {
                        this.quill.emitter.emit('roster-player-add-existing', number);
                    }
                }

                this.checkForTag();
            }

            setTimeout(() => {
                this.updateAddedPlayers();
            }, 0);
        });
    }

    checkForTag(): void {
        const sel = this.quill.getSelection();
        if (!sel) {
            return;
        }

        const [leaf] = this.quill.getLeaf(sel.index) as [TextBlot, number];
        const leafIndex = this.quill.getIndex(leaf);
        const value = leaf.value();

        if (!value || typeof value !== 'string') {
            return;
        }

        const matches = value.match(/#[0-9]*/);

        if (!matches) {
            return;
        }

        const [match] = matches;
        const retain = match.length;

        const addedPlayers = this.options?.getAddedPlayers?.() ?? [];
        const number = match.slice(1);

        this.quill.formatText(leafIndex + matches.index, retain, 'roster-player', {
            number,
            added: addedPlayers.some((plr: any) => plr.jersey === number),
        });
    }
}
