import { configureStore, isPlain } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { objectMerge } from '@labradorsports/utils';

import { authReducer } from './auth/index.js';
import { emptySplitApi } from './baseApi.js';
import { billingReducer } from './billing/index.js';
import { communityReducer } from './community/index.js';
import { mainReducer } from './main/index.js';
import { messagingReducer } from './messaging/index.js';
import { playEditorReducer } from './play-editor/index.js';
import { playbookReducer } from './playbook/index.js';
import { routerReducer } from './router/index.js';
import { scheduleReducer } from './schedule/index.js';
import { scoutingReportReducer } from './scouting-report/index.js';
import { RootState, defaultState } from './state.js';
import { teamsReducer } from './teams/index.js';
import { ThunkContext } from './types.js';

export function initStore(initialState: RecursivePartial<RootState> = {}, thunkContext: ThunkContext) {
    // const persistConfig = {
    //     key: emptySplitApi.reducerPath,
    //     storage,
    //     timeout: null,
    // };

    // const apiReducer = SSR ? emptySplitApi.reducer : persistReducer(persistConfig, emptySplitApi.reducer);
    const apiReducer = emptySplitApi.reducer;

    const reducers = combineReducers({
        [emptySplitApi.reducerPath]: apiReducer,
        main: mainReducer,
        messaging: messagingReducer,
        auth: authReducer,
        playbook: playbookReducer,
        playEditor: playEditorReducer,
        teams: teamsReducer,
        schedule: scheduleReducer,
        billing: billingReducer,
        scoutingReport: scoutingReportReducer,
        community: communityReducer,
        router: routerReducer,
    });

    const preloadedState = objectMerge(
        {
            ...defaultState,
            playEditor: {
                PlayConfig: thunkContext.site.PlayConfig,
            },
        },
        initialState
    );

    const store = configureStore({
        middleware: (defaultMiddleware) =>
            defaultMiddleware({
                serializableCheck: {
                    isSerializable: (value: any) => value instanceof Date || isPlain(value),
                    ignoredPaths: [
                        'auth.user',
                        'auth.accountFormError',
                        'billing.creatingTeam.logoFile',
                        'main.genericError',
                        'playEditor.PlayConfig',
                    ],
                    ignoredActions: [
                        'auth/LoginChange',
                        'auth/AccountFormError',
                        'playEditor/RemoveMove',
                        'main/GenericError',
                    ],
                    ignoredActionPaths: ['payload.logoFile', 'meta'],
                },
                immutableCheck: {
                    ignoredPaths: ['auth.user', 'billing.creatingTeam.logoFile'],
                },
                thunk: {
                    extraArgument: thunkContext,
                },
            }).concat(emptySplitApi.middleware),
        reducer: reducers,
        preloadedState,
    });

    // if (!SSR) {
    //     persistStore(store);
    // }

    return store;
}

export const getGlobals = (initialState: RecursivePartial<RootState> = {}, thunkContext: ThunkContext) => {
    const store = initStore(initialState, thunkContext);

    thunkContext.logger.setStore(store);
    thunkContext.cff.setStore(store);

    return {
        ...thunkContext,
        store,
    };
};

export * from './baseApi.js';
export * from './api.js';

// Redux state
export * from './state.js';
export * from './auth/index.js';
export * from './main/index.js';
export * from './messaging/index.js';
export * from './playbook/index.js';
export * from './teams/index.js';
export * from './schedule/index.js';
export * from './router/index.js';
export * from './play-editor/index.js';
export * from './billing/index.js';
export * from './scouting-report/index.js';
export * from './community/index.js';

// RTK Query api slices
export * from './auth/api.js';
export * from './billing/api.js';
export * from './community/api.js';
export * from './playbook/api.js';
export * from './main/api.js';
export * from './messaging/api.js';
export * from './schedule/api.js';
export * from './scouting-report/api.js';
export * from './teams/api.js';

// Compound queries
export * from './auth/queries.js';
export * from './billing/queries.js';
export * from './community/queries.js';
export * from './playbook/queries.js';
export * from './play-editor/queries.js';
export * from './main/queries.js';
export * from './messaging/queries.js';
export * from './schedule/queries.js';
export * from './scouting-report/queries.js';
export * from './teams/queries.js';

export * as StoreTypes from './types.js';
