import { useSelector } from 'react-redux';

import { Selectors } from '../state.js';

import {
    useFetchTeamsQuery,
    useGetTeamMembersQuery,
    usePendingRosterQuery,
    useRetrieveRosterQuery,
    useSetCoachPlaceholdersMutation,
} from './api.js';

export const useAddCoachPlaceholderMutation = () => {
    const currentTeam = useSelector(Selectors.currentTeam);
    const [setCoachPlaceholders] = useSetCoachPlaceholdersMutation();

    const addCoachPlaceholder = async ({ coach }) => {
        await setCoachPlaceholders({
            activeTeam: currentTeam.id,
            coaches: (currentTeam.coachPlaceholders ?? []).concat([coach]),
        });
    };

    return [addCoachPlaceholder];
};

export const useRemoveCoachPlaceholderMutation = () => {
    const currentTeam = useSelector(Selectors.currentTeam);
    const [setCoachPlaceholders] = useSetCoachPlaceholdersMutation();

    const removeCoachPlaceholder = async ({ idx }: { idx: number }) => {
        await setCoachPlaceholders({
            activeTeam: currentTeam.id,
            coaches: currentTeam.coachPlaceholders.filter((_: any, i: number) => i !== idx),
        });
    };

    return [removeCoachPlaceholder];
};

export const useAllTeamsMembersQuery = () => {
    const { data: { ownedTeams = [], joinedTeams = [], programs = [] } = {}, isFetching: teamsFetching } =
        useFetchTeamsQuery();

    const teamIds = ownedTeams.concat(joinedTeams).map((tm: any) => tm.id);
    const programIds = programs.map((program) => program.id);

    const { data: teamMembers, isFetching: teamMembersFetching } = useGetTeamMembersQuery(
        { teamIds, programIds },
        { skip: teamIds.length === 0 && programIds.length === 0 }
    );

    return { data: teamMembers, isFetching: teamsFetching || teamMembersFetching };
};

export const useLoadRosterQuery = () => {
    const uid = useSelector(Selectors.uid);
    const activeTeam = useSelector(Selectors.activeTeam);
    const currentTeam = useSelector(Selectors.currentTeam);

    const { data: { roster = [], playerRoster = [], contactMap = {}, depthChart } = {}, isFetching } =
        useRetrieveRosterQuery(
            {
                uid,
                teamId: activeTeam,
            },
            {
                skip: !uid || !activeTeam || currentTeam?.pending || !currentTeam?.paid,
            }
        );

    return {
        data: {
            roster,
            playerRoster,
            contactMap,
            depthChart,
        },
        isFetching,
    };
};

export const useLoadPendingRosterQuery = () => {
    const activeTeam = useSelector(Selectors.activeTeam);
    const canViewRoster = useSelector(Selectors.canViewRoster);
    const teamViewable = useSelector(Selectors.teamViewable);

    const { data: pendingRoster = [] } = usePendingRosterQuery(
        { teamId: activeTeam },
        { skip: !activeTeam || !canViewRoster || !teamViewable }
    );

    return {
        data: pendingRoster,
    };
};
