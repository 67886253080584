import { createSlice } from '@reduxjs/toolkit';

import { authActions } from '../auth/index.js';

export interface ScheduleState {
    favoriteLocations: any[];
    rsvpUpdateStatus: string;
    promptRSVPAddReason?: string;
}

const scheduleDefaultState: ScheduleState = {
    favoriteLocations: undefined,
    rsvpUpdateStatus: undefined,
};

const { reducer: scheduleReducer, actions: scheduleActions } = createSlice({
    name: 'schedule',
    initialState: scheduleDefaultState,
    reducers: {
        FavoriteLocationsLoaded: (state, action) => {
            state.favoriteLocations = action.payload;
        },

        SetPromptRSVPReason: (state, action) => {
            state.promptRSVPAddReason = action.payload;
        },
    },
    extraReducers: (builder) => builder.addCase(authActions.PurgeProfile, () => scheduleDefaultState),
});

export { scheduleReducer, scheduleActions, scheduleDefaultState };
