export const Sites = {
    lacrosselab: 'lacrosselab',
    hoopslab: 'hoopslab',
    footballlab: 'footballlab',
};

export const SourceSiteHeader = 'x-labradorsports-site';

export const GlobalPublicSite = 'https://labradorsports.com';

export const SiteList = [Sites.lacrosselab, Sites.hoopslab, Sites.footballlab];

export const ShareLevels = {
    NOONE: 'NOONE',
    COACHES: 'COACHES',
    TEAM: 'TEAM',
    COMMUNITY: 'COMMUNITY',
};

export const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const EventTypes = {
    GAME: 'GAME',
    PRACTICE: 'PRACTICE',
    SCRIMMAGE: 'SCRIMMAGE',
    CUSTOM: 'CUSTOM',
};

export const RSVPStatuses = {
    GOING: 'GOING',
    MAYBE: 'MAYBE',
    NOTGOING: 'NOTGOING',
};

export const Plans = {
    BASIC: 'BASIC',
    COMPLETE: 'COMPLETE',
    COLLEGIATE: 'COLLEGIATE',
    CLUB_COMPLETE: 'CLUB_COMPLETE',
    PERSONAL_UNLIMITED: 'PERSONAL_UNLIMITED',

    // Legacy plan
    PERSONAL: 'PERSONAL',
};

// This 7 day period matches the Smart Retries duration set in Stripe
// This is also represented in firestore.rules
export const PaymentGracePeriod = 7;

export const MaxFreePlays = 10;

export const PlanConfig = {
    [Plans.BASIC]: {
        Name: 'Basic',
        MonthlyPrice: 18,
        AnnualPrice: 180,
    },
    [Plans.COMPLETE]: {
        Name: 'Complete',
        MonthlyPrice: 30,
        AnnualPrice: 300,
    },
    [Plans.COLLEGIATE]: {
        Name: 'Collegiate',
        MonthlyPrice: 60,
        AnnualPrice: 600,
    },
    [Plans.CLUB_COMPLETE]: {
        Name: 'Club Complete',
        MonthlyPrice: 20,
        AnnualPrice: 215,
    },
    [Plans.PERSONAL_UNLIMITED]: {
        Name: 'Personal Unlimited',
        MonthlyPrice: 5,
        AnnualPrice: 50,
    },

    // Legacy plan
    [Plans.PERSONAL]: {
        Name: 'Grandfathered',
        MonthlyPrice: 10,
        AnnualPrice: 120,
    },
};

export const Addons = {
    PLAYERS: 'PLAYERS',
    COACHES: 'COACHES',
    ADMINS: 'ADMINS',
};

export interface PlanTeamSizes {
    Admin: number | null;
    Coach: number | null;
    Player: number | null;
}

export const BillingFrequency = {
    MONTHLY: 'MONTHLY',
    ANNUAL: 'ANNUAL',
};

export const FreeTrialCoupons = {
    LLWELCOME: 15,
    LLWELCOME60: 30,
};

export const NotificationTypes = {
    ALL: 'ALL',
    EVENT_REMINDERS: 'EVENT_REMINDERS',
    NEW_TEAM_PLAY: 'NEW_TEAM_PLAY',
};

export const TeamFlags = {
    COACHES_CAN_EDIT_TEAM_PLAYS: 'COACHES_CAN_EDIT_TEAM_PLAYS',
    COACHES_CAN_COPY_TEAM_PLAYS: 'COACHES_CAN_COPY_TEAM_PLAYS',
    PLAYERS_CAN_REFERENCE_TEAM_PLAYS: 'PLAYERS_CAN_REFERENCE_TEAM_PLAYS',
    PLAYERS_CAN_SHARE_PLAY_LINKS: 'PLAYERS_CAN_SHARE_PLAY_LINKS',

    PLAYERS_CAN_VIEW_TEAM_ROSTER: 'PLAYERS_CAN_VIEW_TEAM_ROSTER',
    PLAYERS_CAN_EMAIL_ROSTER: 'PLAYERS_CAN_EMAIL_ROSTER',

    PLAYERS_USE_ROSTER_QUEUE: 'PLAYERS_USE_ROSTER_QUEUE',
};

export const ProfileFlags = {
    CREATED_FIRST_PLAY: 'CREATED_FIRST_PLAY',
    SHARED_SOCIAL: 'SHARED_SOCIAL',
    FOLLOWED_SOCIAL: 'FOLLOWED_SOCIAL',
    VIEWED_PLAYBOOK: 'VIEWED_PLAYBOOK',
    VIEWED_SCHEDULE: 'VIEWED_SCHEDULE',
    INVITED_TEAM: 'INVITED_TEAM',
    CREATED_FIRST_EVENT: 'CREATED_FIRST_EVENT',
    SHARED_PLAY_TEAM: 'SHARED_PLAY_TEAM',
    DOWNLOADED_APP: 'DOWNLOADED_APP',
    HIDE_PROFILE_SETUP: 'HIDE_PROFILE_SETUP',
    HAS_COMMUNITY_PROFILE: 'HAS_COMMUNITY_PROFILE',
};

export const JoinTypes = {
    COACH: 'COACH',
    PLAYER: 'PLAYER',
    ADMIN: 'ADMIN',
    CONTACT: 'CONTACT',
};

export const ToolTypes = {
    BLUEPLAYER: 'BLUEPLAYER',
    ORANGEPLAYER: 'ORANGEPLAYER',
    COACH: 'COACH',

    MOVE: 'MOVE',
    OMOVE: 'OMOVE',
    DMOVE: 'DMOVE',
    ZONE: 'ZONE',

    BALL: 'BALL',

    PASS: 'PASS',

    SHOOT: 'SHOOT',

    ASSIGN: 'ASSIGN',

    OPTION: 'OPTION',

    COMMENT: 'COMMENT',
    GOAL: 'GOAL',
    CONE: 'CONE',
    OLINE: 'OLINE',
    DLINE: 'DLINE',

    RECTANGLE: 'RECTANGLE',
    ELLIPSE: 'ELLIPSE',

    // Only exists to support legacy plays
    /** @deprecated */
    GOALIE: 'GOALIE',
    /** @deprecated */
    ATTACKER: 'ATTACKER',
    /** @deprecated */
    DEFENDER: 'DEFENDER',
};

export const ToolBaseTypes = {
    PLAYER: 'PLAYER',
    PIECE: 'PIECE',
    MOVE: 'MOVE',
    PASS: 'PASS',
    SHOOT: 'SHOOT',
    BALL: 'BALL',
    PRESET: 'PRESET',
};

// Targets are based on initial possession of the ball
export const MoveConfig: MoveConfigType = {
    [ToolTypes.BLUEPLAYER]: {
        baseType: ToolBaseTypes.PLAYER,
    },
    [ToolTypes.ORANGEPLAYER]: {
        baseType: ToolBaseTypes.PLAYER,
    },
    [ToolTypes.COACH]: {
        baseType: ToolBaseTypes.PLAYER,
    },

    [ToolTypes.MOVE]: {
        baseType: ToolBaseTypes.MOVE,
        targets: [ToolTypes.ORANGEPLAYER, ToolTypes.BLUEPLAYER, ToolTypes.COACH],
    },
    [ToolTypes.DMOVE]: {
        baseType: ToolBaseTypes.MOVE,
        targets: [ToolTypes.ORANGEPLAYER, ToolTypes.COACH],
    },
    [ToolTypes.OMOVE]: {
        baseType: ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.COACH],
    },
    [ToolTypes.ZONE]: {
        baseType: ToolBaseTypes.MOVE,
        targets: [ToolTypes.ORANGEPLAYER],
    },

    [ToolTypes.BALL]: {
        baseType: ToolBaseTypes.BALL,
    },

    [ToolTypes.PASS]: {
        baseType: ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER],
        strokeDashArray: '10, 5',
        shorten: 12,
    },

    [ToolTypes.SHOOT]: {
        baseType: ToolBaseTypes.SHOOT,
        targets: [ToolTypes.BLUEPLAYER],
        strokeDashArray: '10, 5',
        shorten: 12,
    },

    [ToolTypes.ASSIGN]: {
        targets: [ToolTypes.ORANGEPLAYER],
        head: 'circle',
        strokeDashArray: '5, 5',
    },

    [ToolTypes.OPTION]: {
        targets: [ToolTypes.BLUEPLAYER],
        head: 'circle',
        strokeDashArray: '5, 5',
    },

    [ToolTypes.RECTANGLE]: {
        baseType: ToolBaseTypes.PIECE,
    },
    [ToolTypes.ELLIPSE]: {
        baseType: ToolBaseTypes.PIECE,
    },

    [ToolTypes.COMMENT]: {
        baseType: ToolBaseTypes.PIECE,
    },
    [ToolTypes.GOAL]: {
        baseType: ToolBaseTypes.PIECE,
    },
    [ToolTypes.OLINE]: {
        baseType: ToolBaseTypes.PIECE,
    },
    [ToolTypes.DLINE]: {
        baseType: ToolBaseTypes.PIECE,
    },
    [ToolTypes.CONE]: {
        baseType: ToolBaseTypes.PIECE,
    },
};

export const SpecialAnchorTypes = {
    LINE_ROTATE: 'LINE_ROTATE',
    MOVE_ELBOW: 'MOVE_ELBOW',
    PLAYER_ROTATE: 'PLAYER_ROTATE',
    SHADING_RESIZE: 'SHADING_RESIZE',
    SHADING_ROTATE: 'SHADING_ROTATE',
    COMMENT_RESIZE: 'COMMENT_RESIZE',
    PEN_PLAYER: 'PEN_PLAYER',
    PASS_START: 'PASS_START',
    ZONE_SHADING: 'ZONE_SHADING',
};

export const CollegeLeagues = {
    NCAA: 'NCAA',
    NAIA: 'NAIA',
};

export const ScheduleTabs = {
    FEED: 'FEED',
    PRACTICE_TEMPLATES: 'PRACTICE_TEMPLATES',
};

export const PlaybookTabs = {
    MY: 'my',
    TEAM: 'team',
    PUBLIC: 'public',
};

export const DeletePeopleTypes = {
    PLAYER_AND_CONTACTS: 'PLAYER_AND_CONTACTS',
    PLAYER_ONLY: 'PLAYER_ONLY',
    CONTACTS_ONLY: 'CONTACTS_ONLY',
};

export const DrillDifficultyTags = ['Beginner', 'Intermediate', 'Advanced'];

export const DrillPlayersNeededTags = ['Individual (1)', 'Small Group (2-8)', 'Whole Unit (8+)'];

export const NCAASports = {
    MENS_LACROSSE: 'NCAA_MENS_LACROSSE',
    WOMENS_LACROSSE: 'NCAA_WOMENS_LACROSSE',
    MENS_BASKETBALL: 'NCAA_MENS_BASKETBALL',
    WOMENS_BASKETBALL: 'NCAA_WOMENS_BASKETBALL',
};

export const DocumentTypes = {
    FILE: 'FILE',
    LINK: 'LINK',
};

export const PlayerShapes = {
    CIRCLE: 'CIRCLE',
    RECT: 'RECT',
    STAR: 'STAR',
    TRIANGLE: 'TRIANGLE',
};

export const PlayOperations = {
    COPY: 'COPY',
    RENAME: 'RENAME',
    MOVE: 'MOVE',
    SHARE: 'SHARE',
    DELETE: 'DELETE',
} as const;

export type PlayPermissions = {
    [k in keyof typeof PlayOperations]: boolean;
};

export const CommunityPlaybookSections = {
    LATEST: 'LATEST',
    POPULAR: 'POPULAR',
    TRENDING: 'TRENDING',
} as const;

export const ReportStatuses = {
    REQUESTED: 'REQUESTED',
    RUNNING: 'RUNNING',
    ERROR: 'ERROR',
    COMPLETED: 'COMPLETED',
} as const;
