import { useEffect } from 'react';

import { mainActions } from '../../../store/index.js';
import { useDispatcher } from '../../hooks/index.js';

export const OfflineMonitor: FC = () => {
    const setOffline = useDispatcher(mainActions.SetOffline);

    useEffect(() => {
        window.addEventListener('offline', (e) => {
            setOffline(true);
        });

        window.addEventListener('online', (e) => {
            setOffline(false);
        });
    }, []);

    return null;
};

export default OfflineMonitor;
