import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';

import { PlaybookTabs, ProfileFlags, ShareLevels } from '@labradorsports/constants';
import {
    GeneralErrors,
    createException,
    findCurrentFolders,
    getCurrentFolderItems,
    getFolder,
    getFolderParent,
    useSite,
} from '@labradorsports/utils';

import { useDispatch } from '../../shared/hooks/index.js';
import { useLogger } from '../../shared/providers/index.js';
import { useUpdateProfileFlagMutation } from '../auth/queries.js';
import { mainActions } from '../main/index.js';
import { playEditorActions } from '../play-editor/index.js';
import { RootState, RouterSelectors, Selectors } from '../state.js';

import {
    useGetPublicSharedPlayQuery,
    useLoadMyPlayQuery,
    useLoadMyPlaybookQuery,
    useLoadSharedPlayQuery,
    useLoadTeamPlaybookQuery,
    useLoadViewerDetailsQuery,
    useMovePlaybookItemsMutation,
    useSaveExistingPlayMutation,
    useSaveFolderMutation,
    useSaveNewPlayMutation,
    useSavePlayMetadataMutation,
    useSaveSharedPlayMutation,
    useShareItemsMutation,
    useTriggerGIFExportMutation,
} from './api.js';

export const useMyPlaybookQuery = ({ skip = false } = {}) => {
    const { data = {}, isSuccess, refetch, isFetching, isLoading } = useLoadMyPlaybookQuery(undefined, { skip });

    return { data, isSuccess, refetch, isFetching, isLoading };
};

export const useTeamPlaybookQuery = ({ skip = false } = {}) => {
    const { Config } = useSite();
    const activeTeam = useSelector(Selectors.activeTeam);
    const currentTeam = useSelector(Selectors.currentTeam);
    const {
        data = {},
        isSuccess,
        refetch,
        isFetching,
        isLoading,
    } = useLoadTeamPlaybookQuery(
        { activeTeam },
        { skip: !Config.Features.TeamPlaybook || !activeTeam || currentTeam?.pending || skip }
    );

    return { data, isSuccess, refetch, isFetching, isLoading };
};

export const useCurrentFoldersQuery = () => {
    const shownPlaybook = useSelector(RouterSelectors.shownPlaybook);
    const { data: myPlaybook } = useMyPlaybookQuery({ skip: shownPlaybook !== PlaybookTabs.MY });
    const { data: teamPlaybook } = useTeamPlaybookQuery({ skip: shownPlaybook !== PlaybookTabs.TEAM });
    const { folders: myFolders } = myPlaybook;
    const { folders: teamFolders } = teamPlaybook;

    return {
        data: shownPlaybook === PlaybookTabs.MY ? myFolders : teamFolders,
    };
};

export const useCurrentFolderQuery = () => {
    const folderId = useSelector(RouterSelectors.folderId);
    const { data: folders } = useCurrentFoldersQuery();

    return {
        data: getFolder(folders, folderId),
    };
};

export const useAllFormationsQuery = () => {
    const { data: myPlaybook } = useMyPlaybookQuery();
    const { data: teamPlaybook } = useTeamPlaybookQuery();
    const { formations: myFormations = [] } = myPlaybook;
    const { formations: teamFormations = [] } = teamPlaybook;

    return {
        data: myFormations.concat(teamFormations),
    };
};

export const useLoadPlayQuery = ({ playId }) => {
    const store = useStore<RootState>();
    const dispatch = useDispatch();
    const activeTeam = useSelector(Selectors.activeTeam);
    const { data: myPlaybook, isSuccess: myPlaybookLoaded } = useMyPlaybookQuery();
    const { data: teamPlaybook, isSuccess: teamPlaybookLoaded } = useTeamPlaybookQuery();
    const { plays: myPlays = [], id: myPlaybookId } = myPlaybook;
    const { shared: teamPlays = [] } = teamPlaybook;
    const isMyPlay = Boolean(myPlays.find((play) => play.id === playId));
    const found = teamPlays.find((play) => play.id === playId);
    const isTeamPlay = Boolean(found);

    const { data: myPlay } = useLoadMyPlayQuery(
        { playbookId: myPlaybookId, playId },
        { skip: !myPlaybookLoaded || !isMyPlay }
    );

    const { data: teamPlay } = useLoadSharedPlayQuery(
        { teamId: activeTeam, srcPlaybookId: found?.playbookId, playId },
        { skip: !teamPlaybookLoaded || !activeTeam || isMyPlay || !isTeamPlay || found?.playbookId === myPlaybookId }
    );

    const play = isMyPlay ? myPlay : teamPlay;

    useEffect(() => {
        if (play) {
            const state = store.getState();

            if (state.playEditor.play?.id !== play.id) {
                dispatch(playEditorActions.PlayLoaded(play));
            }
        }
    }, [play]);

    return {
        data: play,
    };
};

export const usePublicSharedPlayQuery = () => {
    const store = useStore<RootState>();
    const dispatch = useDispatch();
    const publicLinkId = useSelector(RouterSelectors.publicLinkId);

    const { data: { play } = {} } = useGetPublicSharedPlayQuery({ publicLinkId }, { skip: !publicLinkId });

    useEffect(() => {
        if (play) {
            const state = store.getState();

            if (state.playEditor.play?.id !== play.id) {
                dispatch(playEditorActions.PlayLoaded(play));
            }
        }
    }, [play]);

    return {
        data: play,
    };
};

export const useShareItemMutation = () => {
    const logger = useLogger();
    const [shareItems] = useShareItemsMutation();
    const activeTeam = useSelector(Selectors.activeTeam);
    const playbookId = useSelector(Selectors.playbookId);
    const { data: myPlaybook } = useMyPlaybookQuery();
    const { data: teamPlaybook } = useTeamPlaybookQuery();
    const { plays: myPlays } = myPlaybook;
    const { shared: teamPlays, id: teamPlaybookId } = teamPlaybook;

    const shareItem = ({ itemId, modifications, proxyShare }) => {
        const isMyPlay = myPlays.find((play) => play.id === itemId);
        const isTeamPlay = teamPlays.find((play) => play.id === itemId);
        const srcPlaybookId = isMyPlay || !isTeamPlay ? playbookId : teamPlaybookId;

        logger.log('shareItem', { srcPlaybookId, activeTeam, itemId, modifications, proxyShare });

        shareItems({
            srcPlaybookId,
            itemIds: [itemId],
            modifications,
            proxyTeamId: proxyShare ? activeTeam : undefined,
        });
    };

    return [shareItem];
};

export const useUnshareItemMutation = () => {
    const logger = useLogger();
    const [shareItems] = useShareItemsMutation();
    const activeTeam = useSelector(Selectors.activeTeam);
    const playbookId = useSelector(Selectors.playbookId);

    const unshareItem = ({ itemId }) => {
        logger.log('unshareItem', { itemId });

        shareItems({
            srcPlaybookId: playbookId,
            itemIds: [itemId],
            modifications: {
                [activeTeam]: ShareLevels.NOONE,
            },
        });
    };

    return [unshareItem];
};

export const useViewerDetailsPlayQuery = () => {
    const activeTeam = useSelector(Selectors.activeTeam);
    const viewerDetailsPlayId = useSelector(RouterSelectors.viewerDetailsPlayId);
    const { data: teamPlaybook, isFetching: teamPlaybookFetching } = useTeamPlaybookQuery();
    const found = teamPlaybook?.shared?.find((pl: any) => pl.id === viewerDetailsPlayId);

    const { data: viewerDetails = {}, isFetching: viewerDetailsFetching } = useLoadViewerDetailsQuery(
        {
            activeTeam,
            srcPlaybookId: found?.playbookId,
            playId: viewerDetailsPlayId,
        },
        { skip: !found }
    ) as any;

    return {
        data: {
            ...found,
            ...viewerDetails,
        },
        isFetching: teamPlaybookFetching || viewerDetailsFetching,
    };
};

export const useCreateFolderMutation = () => {
    const logger = useLogger();
    const folders = useSelector(Selectors.currentFolders);
    const playbookId = useSelector(Selectors.currentPlaybookId);
    const [saveFolder] = useSaveFolderMutation();
    const folderId = useSelector(RouterSelectors.folderId);

    const createFolder = async () => {
        logger.log('createFolder', { folderId });

        const currentFolder = getFolder(folders, folderId);

        const newFolder: Folder = {
            name: 'New Folder',
            plays: [],
        };

        const { data: createdId } = (await saveFolder({
            playbookId,
            folder: newFolder,
        })) as any;

        if (currentFolder) {
            await saveFolder({
                playbookId,
                folder: {
                    ...currentFolder,
                    folders: currentFolder.folders ? [...currentFolder.folders, createdId] : [createdId],
                },
            });
        }

        logger.log('createFolder complete', { createdId });

        return createdId;
    };

    return [createFolder];
};

export const useRenameFolderMutation = () => {
    const logger = useLogger();
    const folders = useSelector(Selectors.currentFolders);
    const playbookId = useSelector(Selectors.currentPlaybookId);
    const [saveFolder] = useSaveFolderMutation();

    const renameFolder = async ({ folderId, folderName }) => {
        logger.log('renameFolder', { folderId, folderName });

        if (!folders) return;

        const folder = getFolder(folders, folderId);

        await saveFolder({
            playbookId,
            folder: {
                ...folder,
                name: folderName,
            },
        });
    };

    return [renameFolder];
};

export const useMoveToFolderMutation = () => {
    const logger = useLogger();
    const folders = useSelector(Selectors.currentFolders);
    const [saveFolder] = useSaveFolderMutation();
    const [movePlaybookItems] = useMovePlaybookItemsMutation();
    const playbookId = useSelector(Selectors.currentPlaybookId);

    const moveToFolder = async ({ itemIds, targetFolderId }) => {
        logger.log('moveToFolder', { itemIds, targetFolderId });

        const targetFolder = getFolder(folders, targetFolderId);
        const [playIds, folderIds] = itemIds.reduce(
            (acc, id) => {
                const folder = getFolder(folders, id);

                if (folder) {
                    return [acc[0], [...acc[1], id]];
                }

                return [[...acc[0], id], acc[1]];
            },
            [[], []]
        );

        if (playIds.length > 0) {
            logger.log('moving play');

            // Moving a play to the root folder will have a path of []
            // We only need to remove it from it's current folder
            if (targetFolder) {
                await saveFolder({
                    playbookId,
                    folder: {
                        ...targetFolder,
                        plays: Array.from(new Set([...targetFolder.plays, ...playIds])),
                    },
                });
            }

            const currentFolders: Folder[] = Array.from(
                new Set(playIds.map((id) => findCurrentFolders(folders, id)[0]).filter(Boolean))
            );

            if (currentFolders.length > 0) {
                await Promise.all(
                    currentFolders.map(async (currentFolder) => {
                        await saveFolder({
                            playbookId,
                            folder: {
                                ...currentFolder,
                                plays: currentFolder.plays.filter((playId) => !playIds.includes(playId)),
                            },
                        });
                    })
                );
            }
        }

        if (folderIds.length > 0) {
            logger.log('moving folder');

            // There will be no target folder if the user is moving to the root folder
            if (targetFolder) {
                await saveFolder({
                    playbookId,
                    folder: {
                        ...targetFolder,
                        folders: targetFolder.folders
                            ? Array.from(new Set([...targetFolder.folders, ...folderIds]))
                            : folderIds,
                    },
                });
            }

            const parentFolders: Folder[] = Array.from(
                new Set(playIds.map((id) => getFolderParent(folders, id)).filter(Boolean))
            );

            if (parentFolders.length > 0) {
                await Promise.all(
                    parentFolders.map(async (parentFolder) => {
                        await saveFolder({
                            playbookId,
                            folder: {
                                ...parentFolder,
                                folders: parentFolder.folders.filter((folderId) => !folderIds.includes(folderId)),
                            },
                        });
                    })
                );
            }
        }

        const changes = Object.fromEntries(itemIds.map((k) => [k, null]));

        // Remove user-defined position when moving to a new folder
        await movePlaybookItems({
            playbookId,
            changes,
            folderId: targetFolderId,
        });
    };

    return [moveToFolder];
};

export const useShiftPlaybookItemsMutation = () => {
    const logger = useLogger();
    const myPlaybook = useSelector(Selectors.myPlaybook);
    const teamPlaybook = useSelector(Selectors.teamPlaybook);
    const [movePlaybookItems] = useMovePlaybookItemsMutation();
    const shownPlaybook = useSelector(RouterSelectors.shownPlaybook);
    const folderId = useSelector(RouterSelectors.folderId);

    const shiftPlaybookItems = async ({ itemIds, up }) => {
        const playbook = shownPlaybook === PlaybookTabs.MY ? myPlaybook : teamPlaybook;

        logger.log('shiftPlaybookItems', { itemIds, up, folderId });

        const plays = playbook.shared ?? playbook.plays;
        const items = getCurrentFolderItems(plays, playbook.folders, folderId);

        logger.log('retrieved folder items', {
            items: items.length,
        });

        const changes: any = {};
        const newIndexes: any = {};

        const updateItemPosition = (itemId: string, newPosition: number) => {
            changes[itemId] = newPosition;
            newIndexes[newPosition] = itemId;
        };

        // Update the selected items indices
        items.forEach((item, idx) => {
            const isMoving = itemIds.includes(item.id);

            if (isMoving) {
                if ((up && idx === 0) || (!up && idx === items.length - 1)) {
                    logger.log('invalid movement');
                    return;
                }

                updateItemPosition(item.id, up ? idx - 1 : idx + 1);
            }
        });

        const maxPosition = Math.max(...Object.keys(newIndexes).map(Number));

        // Update any items that are "overlapping" with the new position of selected items
        items.forEach((item, idx) => {
            if (newIndexes[idx] && typeof changes[item.id] === 'undefined') {
                let newIdx = idx;

                while (newIndexes[newIdx]) {
                    newIdx += up ? 1 : -1;
                }

                updateItemPosition(item.id, newIdx);
            } else if (idx <= maxPosition) {
                updateItemPosition(item.id, idx);
            }
        });

        await movePlaybookItems({
            changes,
            folderId,
            playbookId: playbook.id,
        });
    };

    return [shiftPlaybookItems];
};

export const useDropPlaybookItemMutation = () => {
    const logger = useLogger();
    const myPlaybook = useSelector(Selectors.myPlaybook);
    const teamPlaybook = useSelector(Selectors.teamPlaybook);
    const [movePlaybookItems] = useMovePlaybookItemsMutation();
    const shownPlaybook = useSelector(RouterSelectors.shownPlaybook);
    const folderId = useSelector(RouterSelectors.folderId);

    const dropPlaybookItem = async ({ itemIds, newPosition }) => {
        const playbook = shownPlaybook === PlaybookTabs.MY ? myPlaybook : teamPlaybook;

        logger.log('dropPlaybookItem', { itemIds, newPosition, folderId });

        const plays = playbook.shared ?? playbook.plays;
        const items = getCurrentFolderItems(plays, playbook.folders, folderId);

        logger.log('retrieved folder items', {
            items: items.length,
        });

        const changes: any = {};

        const selectedItems = itemIds
            .map((itemId) => items.find((i) => i.id === itemId))
            .sort((itemA, itemB) => {
                const itemAPosition = itemA.position ?? items.length;
                const itemBPosition = itemB.position ?? items.length;

                return itemAPosition - itemBPosition;
            });

        const originalPositions = selectedItems.map((item) => item.position);

        // Remove the selected items so they can be replaced in the new position
        selectedItems.forEach((item) => {
            items.splice(
                items.findIndex((i) => i.id === item.id),
                1
            );
        });

        // update the items array to reflect the new ordering
        items.splice(newPosition, 0, ...selectedItems);

        // create a change object to update all positions
        items.forEach((item, idx) => {
            if (
                typeof item.position === 'number' ||
                idx < Math.max(newPosition + itemIds.length, ...originalPositions)
            ) {
                changes[item.id] = idx;
            }
        });

        await movePlaybookItems({
            changes,
            folderId,
            playbookId: playbook.id,
        });
    };

    return [dropPlaybookItem];
};

export const useSavePlayMutation = () => {
    const store = useStore<RootState>();
    const dispatch = useDispatch();
    const { data: myPlaybook } = useMyPlaybookQuery();
    const { data: teamPlaybook } = useTeamPlaybookQuery();
    const activeTeam = useSelector(Selectors.activeTeam);
    const [saveExistingPlay] = useSaveExistingPlayMutation();
    const [saveSharedPlay] = useSaveSharedPlayMutation();
    const [saveNewPlay] = useSaveNewPlayMutation();
    const [setProfileFlag] = useUpdateProfileFlagMutation();

    const savePlay = async ({ autosave = false } = {}) => {
        const { playEditor } = store.getState();
        const { play, savingPlay } = playEditor;

        // Do not attempt to save again if the play is already saving
        // NOTE: this may cause autosaves to be missed on subsequent attempts if one is still happening
        if (savingPlay || !play) return;

        dispatch(playEditorActions.Saving(true));

        // Saved play
        if (!play.id.includes('__')) {
            if (myPlaybook.plays?.findIndex((pl: any) => pl.id === play.id) !== -1) {
                await saveExistingPlay({ playbookId: myPlaybook.id, play, autosave });
            } else {
                const sharedPlay = teamPlaybook?.shared?.find((pl: any) => pl.id === play.id);
                await saveSharedPlay({ playbookId: sharedPlay.playbookId, activeTeam, play, autosave });
            }
        } else {
            await saveNewPlay({ playbookId: myPlaybook.id, play, autosave });
            await setProfileFlag({
                flag: ProfileFlags.CREATED_FIRST_PLAY,
                value: true,
            });
        }

        dispatch(playEditorActions.Saving(false));
        dispatch(playEditorActions.PlayModified(false));

        if (!autosave) {
            dispatch(mainActions.Loading(false));
        }
    };

    return [savePlay];
};

export const useRenamePlayMutation = () => {
    const [savePlayMetadata] = useSavePlayMetadataMutation();
    const myPlaybook = useSelector(Selectors.myPlaybook);

    const renamePlay = async ({ playId, name }) => {
        const play = myPlaybook.plays.find((pl) => pl.id === playId);

        await savePlayMetadata({
            playbookId: myPlaybook.id,
            playId,
            name,
            tags: play.tags ?? [],
            description: play.description ?? '',
        });
    };

    return [renamePlay];
};

export const useExportGIFMutation = () => {
    const store = useStore<RootState>();
    const logger = useLogger();
    const [savePlay] = useSavePlayMutation();
    const [triggerGIFExport] = useTriggerGIFExportMutation();
    const communityPlay = useSelector(Selectors.communityPlay);
    const play = useSelector(Selectors.play);
    const playReadOnly = useSelector(Selectors.playReadOnly);
    const publicLinkId = useSelector(RouterSelectors.publicLinkId);
    const playModified = useSelector((state: RootState) => state.playEditor.playModified);

    const exportGIF = async ({ playId }: { playId: string }) => {
        logger.log('exportGIF', { playId });

        if (playId === communityPlay?.playId) {
            logger.log('community play');

            const { playbookId, programId, name } = communityPlay;
            await triggerGIFExport({ playbookId, playId, programId, name });
            return;
        }

        if (publicLinkId) {
            const { playbookId, programId, name } = play;
            await triggerGIFExport({ playbookId, playId, programId, name, linkId: publicLinkId });
            return;
        }

        if (playModified && !playReadOnly) {
            logger.log('saving play before export');

            await savePlay();
        }

        // Get updated state in case play id has changed
        const updatedState = store.getState();
        const foundPlay = Selectors.findPlay(updatedState, playId);
        const playbookId = foundPlay?.playbookId ?? Selectors.playbookId(updatedState);

        if (!playbookId || !foundPlay) {
            logger.exception(
                createException(GeneralErrors.INVALID_REQUEST, {
                    details: `exportGIF play not found (${playId} ${playbookId})`,
                })
            );
            return;
        }

        logger.log('individual play');

        const programId = Selectors.teamPlaybook(updatedState)?.shared?.some((pl: any) => pl.id === playId)
            ? Selectors.currentTeam(updatedState).programId
            : '';

        await triggerGIFExport({ playbookId, playId, programId, name: foundPlay.name });
    };

    return [exportGIF];
};
