import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

interface Props {
    in: boolean;
    position?: 'left' | 'right' | 'bottom';
    aboveModals?: boolean;
}

const Flyover: FC<Props> = ({ in: animationIn, position = 'left', children, aboveModals }) => {
    const nodeRef = useRef(null);

    return SSR
        ? null
        : createPortal(
              <CSSTransition
                  nodeRef={nodeRef}
                  classNames="flyover"
                  in={animationIn}
                  timeout={300}
                  mountOnEnter
                  unmountOnExit
              >
                  <div ref={nodeRef} className={`flyover ${position} ${aboveModals ? 'above-modals' : ''}`}>
                      {children}
                  </div>
              </CSSTransition>,
              document.getElementById('react')
          );
};

export default Flyover;
