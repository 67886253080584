import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SimpleModal } from '@labradorsports/components';
import { PlaybookTabs } from '@labradorsports/constants';
import { GeneralErrors, TeamErrors } from '@labradorsports/utils';

import { useNavigateWithQuery } from '../../shared/providers/index.js';
import { useLogger } from '../../shared/providers/index.js';
import { RouterSelectors, Selectors, useJoinTeamLinkMutation } from '../../store/index.js';
import { JoinTeamSuccess } from '../index.js';

const JoinTeamPage: FC = () => {
    const [joinTeamLink] = useJoinTeamLinkMutation();

    const joinTeamCode = useSelector(RouterSelectors.joinTeamCode);
    const joinTeamId = useSelector(RouterSelectors.joinTeamId);
    const teams = useSelector(Selectors.allTeams);
    const logger = useLogger();
    const navigate = useNavigateWithQuery();
    const [error, setError] = useState('');
    const [joinResult, setJoinResult] = useState(null);

    useEffect(() => {
        // If the user is already joined to the team, just direct them to the team playbook
        if (joinTeamId && teams.find((tm) => tm.id === joinTeamId)) {
            logger.log('joinTeamLink user already member', { joinTeamId });

            navigate('/feed', {
                activeTeam: joinTeamId,
                shownPlaybook: PlaybookTabs.TEAM,
            });
        } else {
            joinTeamLink({ entityId: joinTeamId, linkCode: joinTeamCode }).then((result) => {
                if ('data' in result) {
                    setJoinResult(result.data);
                } else if ('error' in result) {
                    const newError = (() => {
                        if (result.error.code === TeamErrors.TEAM_FULL.code) {
                            return 'This team is already full';
                        }

                        if (result.error.code === TeamErrors.ALREADY_JOINED.code) {
                            return 'You are already joined to this team';
                        }

                        if (
                            result.error.code === GeneralErrors.NOT_FOUND.code ||
                            result.error.code === TeamErrors.INVALID_LINK.code
                        ) {
                            return 'This link is invalid or expired.';
                        }

                        return 'An error occurred';
                    })();

                    setError(newError);
                }
            });
        }
    }, []);

    const dismissMessage = () => {
        navigate('/feed');
    };

    if (error) {
        return (
            <SimpleModal onClose={dismissMessage} open>
                <p>{error}</p>
            </SimpleModal>
        );
    }

    if (joinResult) {
        if (joinResult.pending) {
            return (
                <SimpleModal onClose={dismissMessage} open>
                    <p>Your coach needs to review your membership. Check back later.</p>
                </SimpleModal>
            );
        }

        return <JoinTeamSuccess onClose={dismissMessage} joinResult={joinResult} />;
    }

    return null;
};

export default JoinTeamPage;
