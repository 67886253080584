import {
    getAuth,
    User,
    signInWithEmailAndPassword,
    applyActionCode,
    verifyPasswordResetCode,
    confirmPasswordReset,
    EmailAuthProvider,
    sendEmailVerification as sendFirebaseEmailVerification,
    updateEmail,
    reauthenticateWithCredential,
    updatePassword as updateFirebasePassword,
    signOut,
    deleteUser,
} from 'firebase/auth';
import { serverTimestamp, doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL, getStorage, uploadBytes } from 'firebase/storage';

import { NotificationTypes } from '@labradorsports/constants';
import {
    GeneralErrors,
    createException,
    createNestedUpdates,
    AuthenticationErrors,
    getAuthError,
} from '@labradorsports/utils';

import { getNotificationPermission, onTokenRefresh } from '../../app/plugins/index.js';
import firestore from '../../firebase-config.js';
import { DataTags } from '../../shared/constants.js';
import { trackUserProperties } from '../../shared/utils.js';
import { createApiSlice } from '../api.js';
import { emptySplitApi } from '../baseApi.js';
import { mainActions } from '../main/index.js';
import { teamsApi } from '../teams/api.js';
import { CustomMutationDefinition, CustomQueryDefinition } from '../types.js';

import { authActions } from './index.js';

export const LoginPopoverForms = {
    SIGNUP: 'SIGNUP',
    LOGIN: 'LOGIN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};

export const authApi = createApiSlice({
    login: {
        type: 'mutation',
        extraOptions: {
            suppressError: true,
            anonymousHooks: true,
            showLoading: true,
            unloggableArg: ['email', 'password'],
        },
        queryFn: async ({ email, password }, { dispatch }) => {
            dispatch(authActions.AccountFormError());

            await signInWithEmailAndPassword(getAuth(), email.trim(), password);
        },
        onQueryError: ({ error }, { dispatch }) => {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return;
            }

            throw exc;
        },
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<{ email: string; password: string }, any>,

    logout: {
        type: 'mutation',
        queryFn: async (arg, { dispatch }) => {
            dispatch(authActions.PurgeProfile());

            dispatch(emptySplitApi.util.resetApiState());

            await signOut(getAuth());
        },
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<void, any>,

    sendEmailVerification: {
        type: 'mutation',
        queryFn: async ({ user }) => {
            await sendFirebaseEmailVerification(user);
        },
    } as CustomMutationDefinition<{ user: User }, any>,

    createAccount: {
        type: 'mutation',
        extraOptions: {
            anonymousHooks: true,
            suppressError: true,
            showLoading: true,
            unloggableArg: ['email', 'password', 'firstName', 'lastName', 'token'],
        },
        query: ({ email, password, firstName, lastName, token }) => ({
            path: 'account/createAccount',
            body: {
                email: email.trim(),
                password,
                firstName,
                lastName,
                token,
            },
        }),
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<
        { email: string; password: string; firstName: string; lastName: string; token: string },
        any
    >,

    sendPasswordReset: {
        type: 'mutation',
        extraOptions: {
            suppressError: true,
            anonymousHooks: true,
        },
        query: ({ email }) => ({
            path: 'account/sendPasswordReset',
            body: {
                email: email.trim(),
            },
        }),
        onQueryError: ({ error }, { dispatch }) => {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return false;
            }

            throw exc;
        },
        onQueryEnded: (result, { dispatch }) => {
            dispatch(mainActions.GenericAlert('A password reset link has been sent to your email address.'));
        },
    } as CustomMutationDefinition<{ email: string }, any>,

    verifyEmail: {
        type: 'mutation',
        extraOptions: {
            anonymousHooks: true,
        },
        queryFn: async ({ code }) => {
            await applyActionCode(getAuth(), code);

            return {
                data: true,
            };
        },
        onQueryError: ({ error }) => {
            throw getAuthError(error);
        },
    } as CustomMutationDefinition<{ code: string }, any>,

    validateResetPassword: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        queryFn: async ({ code }) => {
            const email = await verifyPasswordResetCode(getAuth(), code);

            return {
                data: email,
            };
        },
    } as CustomQueryDefinition<{ code: string }, any>,

    resetPassword: {
        type: 'mutation',
        extraOptions: {
            anonymousHooks: true,
        },
        queryFn: async ({ code, email, newPassword }, { dispatch }) => {
            await confirmPasswordReset(getAuth(), code, newPassword);

            await dispatch(authApi.endpoints.login.initiate({ email: email.trim(), password: newPassword }));

            return {
                data: true,
            };
        },
        onQueryEnded: (result, { dispatch }) => {
            dispatch(mainActions.GenericAlert('Your password has been updated'));
        },
    } as CustomMutationDefinition<{ code: string; email: string; newPassword: string }, any>,

    updateEmailAddress: {
        type: 'mutation',
        queryFn: async ({ newEmail }, { dispatch, getState }) => {
            const state = getState();
            const { user } = state.auth;

            await updateEmail(user, newEmail.trim());
            await dispatch(
                authApi.endpoints.updatePersonal.initiate({ uid: user.uid, updates: { email: newEmail.trim() } })
            );
        },
        onQueryError: ({ error }, { dispatch }) => {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return;
            }

            throw exc;
        },
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<{ newEmail: string }, any>,

    reauthenticate: {
        type: 'mutation',
        queryFn: async ({ password }, { dispatch, getState }) => {
            const state = getState();
            const { user } = state.auth;

            const credential = await EmailAuthProvider.credential(user.email, password);
            await reauthenticateWithCredential(user, credential);

            dispatch(authActions.Reauthenticating(false));
        },
        onQueryError: ({ error }, { dispatch }) => {
            const exc = getAuthError(error);
            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return;
            }

            throw exc;
        },
    } as CustomMutationDefinition<{ password: string }, any>,

    updatePassword: {
        type: 'mutation',
        queryFn: async ({ currentPassword, newPassword }, { dispatch, getState }) => {
            const state = getState();
            const { user } = state.auth;

            try {
                await dispatch(authApi.endpoints.reauthenticate.initiate({ password: currentPassword }));
                await updateFirebasePassword(user, newPassword);

                dispatch(mainActions.GenericAlert('Your password has been updated.'));
            } catch (error) {
                const exc = getAuthError(error);
                if (exc.isOfType(AuthenticationErrors)) {
                    dispatch(authActions.AccountFormError(exc));
                    return;
                }

                throw exc;
            }
        },
    } as CustomMutationDefinition<{ currentPassword: string; newPassword: string }, any>,

    deleteAccount: {
        type: 'mutation',
        queryFn: async (arg, { getState }) => {
            const state = getState();
            const { user } = state.auth;

            await deleteUser(user);
        },
        onQueryError: ({ error }, { dispatch }) => {
            const exc = getAuthError(error);
            if (exc.is(AuthenticationErrors.REQUIRES_RECENT_LOGIN)) {
                dispatch(authActions.Reauthenticating(true));
            } else {
                throw exc;
            }
        },
        onQueryEnded: (result, { dispatch }) => {
            dispatch(authActions.PurgeProfile());
        },
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<void, any>,

    updatePersonal: {
        type: 'mutation',
        queryFn: async ({ updates, uid }) => {
            await updateDoc(doc(firestore, 'users', uid), createNestedUpdates({ basicDetails: updates }));
        },
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<{ updates: any; uid: string }, any>,

    updateSettings: {
        type: 'mutation',
        queryFn: async ({ updates, uid }) => {
            await updateDoc(doc(firestore, 'users', uid), createNestedUpdates({ settings: updates }));
        },
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<{ updates: any; uid: string }, any>,

    loadProfileImage: {
        type: 'query',
        extraOptions: {
            suppressError: true,
        },
        isValid: ({ uid }) => Boolean(uid),
        queryFn: async ({ uid }) => {
            const url = await getDownloadURL(ref(getStorage(), `users/${uid}/profile`));

            return {
                data: url,
            };
        },
        onQueryError: ({ error }: any, { extra }) => {
            const { logger } = extra;
            if (error.code === 'storage/object-not-found' || error.code === 'storage/unauthorized') {
                logger.log('error loading profile image', { code: error.code });
            } else {
                throw createException(GeneralErrors.LOADING_ERROR, { nestedError: error });
            }
        },
        providesTags: (result, error, { uid }) => [{ type: DataTags.PROFILE_IMAGE, id: uid }],
    } as CustomQueryDefinition<{ uid: string }, any>,

    enableNotifications: {
        type: 'mutation',
        queryFn: async ({ uid, profileSettings }, { dispatch, extra }) => {
            const { logger } = extra;
            const permission = await getNotificationPermission(logger);

            if (permission) {
                onTokenRefresh(async (deviceToken) => {
                    if (
                        !profileSettings?.notifications?.[NotificationTypes.ALL] ||
                        !profileSettings?.notificationDevices?.[deviceToken]
                    ) {
                        await dispatch(
                            authApi.endpoints.updateSettings.initiate({
                                uid,
                                updates: {
                                    notifications: {
                                        [NotificationTypes.ALL]: true,
                                    },
                                    notificationDevices: {
                                        [deviceToken]: true,
                                    },
                                },
                            })
                        );
                    }
                });
            }
        },
    } as CustomMutationDefinition<{ uid: string; profileSettings: any }, any>,

    loadProfile: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
            showLoading: true,
        },
        queryFn: async (arg, { getState, extra }) => {
            const { logger } = extra;
            const state = getState();
            const { user } = state.auth;

            if (!user) {
                throw new Error('User not found in state');
            }

            logger.log('initProfile', { uid: user.uid });

            const userDoc = await getDoc(doc(firestore, 'users', user.uid));
            const profile = userDoc.data();

            return {
                data: {
                    ...profile,
                    basicDetails: {
                        ...profile.basicDetails,
                        createdDate: profile.basicDetails.createdDate?.toDate(),
                        lastAccess: profile.basicDetails.lastAccess?.toDate(),
                    },
                },
            };
        },
        onQueryStarted: (arg, { dispatch }) => {
            dispatch(authActions.ProfileLoaded(false));
        },
        onQueryError: async ({ error }: any, { dispatch, extra }) => {
            const { logger } = extra;
            logger.exception(createException(GeneralErrors.NOT_FOUND, { nestedError: error }));
            dispatch(authApi.endpoints.logout.initiate());
        },
        onQueryEnded: async ({ data: profile }, { dispatch, extra, getState }) => {
            const { logger, site } = extra;
            const state = getState();
            const { user } = state.auth;

            if (APP) {
                dispatch(
                    authApi.endpoints.enableNotifications.initiate({
                        uid: user.uid,
                        profileSettings: profile.settings,
                    })
                );
            }

            const promises: any[] = [];

            const personalUpdates = [];
            if (!profile.basicDetails.emailVerified && user.emailVerified) {
                personalUpdates.push(['emailVerified', true]);
            }

            if (personalUpdates.length > 0) {
                promises.push(
                    dispatch(
                        authApi.endpoints.updatePersonal.initiate({
                            uid: user.uid,
                            updates: Object.fromEntries(personalUpdates),
                        })
                    )
                );
            }

            promises.push(dispatch(teamsApi.endpoints.fetchTeams.initiate()));

            // Using proper key name directly to maintain value of timestamp
            promises.push(
                updateDoc(doc(firestore, 'users', user.uid), {
                    'basicDetails.lastAccess': serverTimestamp(),
                })
            );

            trackUserProperties(user.uid, {
                site: site.Config.SiteConfig.Site,
                email: profile.basicDetails.email,
                firstName: profile.basicDetails.firstName,
                lastName: profile.basicDetails.lastName,
            });

            await Promise.all(promises);

            dispatch(authActions.ProfileLoaded(true));

            logger.log('initProfile complete');
        },
        providesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomQueryDefinition<void, any>,

    uploadProfileImage: {
        type: 'mutation',
        queryFn: async ({ uid, file }) => {
            await uploadBytes(ref(getStorage(), `users/${uid}/profile`), file);
        },
        invalidatesTags: ({ uid }) => [{ type: DataTags.PROFILE_IMAGE, id: uid }],
    } as CustomMutationDefinition<{ uid: string; file: File }, any>,

    setProfileFlag: {
        type: 'mutation',
        queryFn: async ({ flag, value, uid }) => {
            await updateDoc(
                doc(firestore, 'users', uid),
                createNestedUpdates({
                    profileFlags: {
                        [flag]: value,
                    },
                })
            );
        },
        invalidatesTags: () => [{ type: DataTags.PROFILE }],
    } as CustomMutationDefinition<{ flag: string; value: boolean; uid: string }, any>,
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useSendEmailVerificationMutation,
    useCreateAccountMutation,
    useSendPasswordResetMutation,
    useVerifyEmailMutation,
    useValidateResetPasswordQuery,
    useResetPasswordMutation,
    useUpdateEmailAddressMutation,
    useReauthenticateMutation,
    useUpdatePasswordMutation,
    useDeleteAccountMutation,
    useUpdatePersonalMutation,
    useUpdateSettingsMutation,
    useLoadProfileImageQuery,
    useEnableNotificationsMutation,
    useLoadProfileQuery,
    useUploadProfileImageMutation,
    useSetProfileFlagMutation,
} = authApi;
