import { Action } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'redux-persist';

import { DataTags } from '../shared/constants.js';

import { RootState } from './state.js';
import { BaseQueryArgs, QueryApi, QueryOptions } from './types.js';

function isHydrateAction(action: Action): action is Action<typeof REHYDRATE> & {
    key: string;
    payload: RootState;
    err: unknown;
} {
    return action.type === REHYDRATE;
}

export const emptySplitApi = createApi({
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: 3600,
    baseQuery: (arg: BaseQueryArgs, api: QueryApi, extraOptions: QueryOptions<any>) => ({ data: undefined }),
    tagTypes: Object.values(DataTags),
    extractRehydrationInfo(action): any {
        if (isHydrateAction(action)) {
            return action.payload;
        }
    },
    endpoints: () => ({}),
});
