import * as Constants from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';

import CollegeFullFieldBackdrop from './field-backdrop/college-full-field-backdrop.js';
import HighSchoolFullFieldBackdrop from './field-backdrop/highschool-full-field-backdrop.js';
import Hoop from './hoop.js';

const { Sites } = Constants;
const { Sports, PlayerPositions } = SportConfigs[Sites.hoopslab];

const PlayTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    DRILL: 'DRILL',
};

const FieldTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    CUSTOM: 'CUSTOM',
};

const FieldDescriptor = 'Court';

const FieldBackdropMap = {
    [Sports.HighSchoolBBall]: HighSchoolFullFieldBackdrop,
    [Sports.MensCollegeBBall]: CollegeFullFieldBackdrop,
    [Sports.WomensCollegeBBall]: CollegeFullFieldBackdrop,
};

const GoalDesign = {
    [Sports.HighSchoolBBall]: Hoop,
    [Sports.MensCollegeBBall]: Hoop,
    [Sports.WomensCollegeBBall]: Hoop,
};

const Formations = {
    _3out2in: '_3out2in',
    _4out1in: '_4out1in',
    _5out: '_5out',
};

const ToolTypes = {
    ...Constants.ToolTypes,
    PICK: 'PICK',
    DRIBBLE: 'DRIBBLE',
    REBOUND: 'REBOUND',

    ...Formations,

    // Included for legacy plays
    /** @deprecated */
    ROLLBACK: 'ROLLBACK',
    /** @deprecated */
    DODGE: 'DODGE',
};

const FormationDefinitions = {
    [Formations._3out2in]: {
        name: '3 out 2 in',
        anchorType: ToolTypes.GOAL,
        attributes: ['half-court', 'post-oriented', 'inside-out', 'intermediate', 'balanced-spacing'],
        description:
            'A traditional formation with three perimeter players and two post players, ideal for teams with skilled big men. This setup creates opportunities for inside-out play and is effective against man-to-man defense when you have strong post players.',
        initial: [
            { origin: { x: 0, y: -30 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -15, y: -17.5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -17.5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 15, y: -17.5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -5 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._4out1in]: {
        name: '4 out 1 in',
        anchorType: ToolTypes.GOAL,
        attributes: ['perimeter-oriented', 'spacing', 'modern', 'intermediate', 'wide-spacing'],
        description:
            'A modern offensive formation featuring four perimeter players and one post player, maximizing floor spacing for drives and three-point shots. This formation is particularly effective for teams with good shooters and a skilled post player who can either score or pass from the block.',
        initial: [
            { origin: { x: -12.5, y: -30 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 12.5, y: -30 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 0, y: -15 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -20, y: -5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -5 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
    [Formations._5out]: {
        name: '5 out',
        anchorType: ToolTypes.GOAL,
        attributes: ['perimeter-oriented', 'motion', 'modern', 'advanced', 'maximum-spacing'],
        description:
            'A contemporary offensive formation with all five players positioned around the perimeter, creating maximum spacing for drives and three-point opportunities. This formation is best utilized by teams with versatile players who can all handle the ball and shoot from outside, making it difficult for defenses to help or protect the paint.',
        initial: [
            { origin: { x: 0, y: -25 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -12.5, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 12.5, y: -20 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: -20, y: -2.5 }, type: ToolTypes.BLUEPLAYER },
            { origin: { x: 20, y: -2.5 }, type: ToolTypes.BLUEPLAYER },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
};

// Dimensions in feet
const FieldSettings: DefaultFieldConfig = {
    [Sports.HighSchoolBBall]: {
        width: 50,
        height: 84,
        sideline: {
            top: 4,
            right: 4,
            bottom: 4,
            left: 4,
        },
        center: {
            x: 25,
            y: 42,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [
                {
                    origin: { x: 25, y: 37.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            [FieldTypes.FULL_FIELD]: [
                {
                    origin: { x: 25, y: 79.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 25, y: 4.75 },
                    rotation: 180,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
        },
        zoomLevels: [1, 2, 3],
    },
    [Sports.MensCollegeBBall]: {
        width: 50,
        height: 94,
        sideline: {
            top: 4,
            right: 4,
            bottom: 4,
            left: 4,
        },
        center: {
            x: 25,
            y: 47,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [
                {
                    origin: { x: 25, y: 42.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            [FieldTypes.FULL_FIELD]: [
                {
                    origin: { x: 25, y: 89.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 25, y: 4.75 },
                    rotation: 180,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
        },
        zoomLevels: [1, 2, 3],
    },
    [Sports.WomensCollegeBBall]: {
        width: 50,
        height: 94,
        sideline: {
            top: 4,
            right: 4,
            bottom: 4,
            left: 4,
        },
        center: {
            x: 25,
            y: 47,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [
                {
                    origin: { x: 25, y: 42.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            [FieldTypes.FULL_FIELD]: [
                {
                    origin: { x: 25, y: 89.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 25, y: 4.75 },
                    rotation: 180,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
        },
        zoomLevels: [1, 2, 3],
    },
};

const FeatureFlags = {
    EnableGoalie: false,
    EnableCustomField: false,
    OnionSkin: false,
};

const NewPlaySettings = {
    FrameCount: 1,
};

const FrameRoles = {};

const FrameRoleNames = {};

const PlayTheme: PlayThemeSpec = {
    Balls: {
        fill: '#ff6700',
    },
};

const MoveConfig: MoveConfigType = {
    ...Constants.MoveConfig,
    [ToolTypes.PICK]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER],
        head: 'line',
    },
    [ToolTypes.DRIBBLE]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        pattern: 'WAVE',
    },
    [ToolTypes.REBOUND]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        strokeDashArray: '2, 4',
        shorten: 12,
    },
};

const RoleDisplay = {
    [PlayerPositions.POINT_GUARD]: 'PG',
    [PlayerPositions.SHOOTING_GUARD]: 'SG',
    [PlayerPositions.SMALL_FORWARD]: 'SF',
    [PlayerPositions.POWER_FORWARD]: 'PF',
    [PlayerPositions.CENTER]: 'C',
};

const PlayConfig: PlayConfigSpec = {
    Site: Sites.hoopslab,
    PlayTypes,
    FieldTypes,
    FieldDescriptor,
    GoalDesign,
    FieldSettings,
    FeatureFlags,
    NewPlaySettings,
    ToolTypes,
    MoveConfig,
    FrameRoles,
    FrameRoleNames,
    PlayTheme,
    Formations,
    RoleDisplay,
    FieldBackdropMap,
    MovementSpeed: 10,
    FormationDefinitions,
};

export default PlayConfig;
