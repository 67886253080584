import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { routerActions } from '../router/index.js';

export interface ShareConfig {
    itemIds?: string[];
    myPlaybook?: boolean;
}

interface GIFDownload {
    playbookId: string;
    playId: string;
    programId: string;
    nonce: string;
    estimatedWait: number;
    name: string;
    previewUrl?: string;
    gifUrl?: string;
    error?: boolean;
    linkId?: string;
}

export interface PlaybookState {
    teamPlaybookId: string;

    sidePanelShown: boolean;

    viewedPlayIds: string[];
    shareConfig: ShareConfig;
    viewershipProfile: any[];

    renameItemId: string;

    publicLoadPlayError: boolean;

    printPlay: boolean;

    overrideMenuWithBack: boolean;

    gifDownloads: GIFDownload[];
}

const playbookDefaultState: PlaybookState = {
    teamPlaybookId: undefined,
    // TODO: replace
    // shownPlaybook: PlaybookTabs.MY,
    sidePanelShown: true,
    shareConfig: undefined,
    viewershipProfile: undefined,
    viewedPlayIds: undefined,
    renameItemId: undefined,
    publicLoadPlayError: false,
    printPlay: false,
    overrideMenuWithBack: false,
    gifDownloads: [],
};

const { reducer: playbookReducer, actions: playbookActions } = createSlice({
    name: 'playbook',
    initialState: playbookDefaultState,
    reducers: {
        ViewedPlaysLoaded: (state, action: PayloadAction<string[] | null>) => {
            state.viewedPlayIds = action.payload;
        },

        SetRenameItemId: (state, action: PayloadAction<string | null>) => {
            state.renameItemId = action.payload;
        },

        SetSharing: {
            prepare: (payload?: ShareConfig) => ({
                payload,
            }),
            reducer: (state, action: PayloadAction<ShareConfig>) => {
                state.shareConfig = action.payload;
            },
        },

        ShowSidePanel: (state, action) => {
            state.sidePanelShown = action.payload;
        },

        Printing: (state, action) => {
            state.printPlay = !!action.payload;
        },

        PublicLoadPlayError: (state, action) => {
            state.publicLoadPlayError = action.payload;
        },

        OverrideMenuWithBack: (state, action: PayloadAction<boolean | null>) => {
            state.overrideMenuWithBack = action.payload;
        },

        AddGIFDownload: (state, action: PayloadAction<GIFDownload>) => {
            // If a download already exists for this play, don't duplicate it
            const existingDownloads = state.gifDownloads.filter(
                (gd) =>
                    gd.playId !== action.payload.playId ||
                    gd.playbookId !== action.payload.playbookId ||
                    gd.programId !== action.payload.programId
            );
            state.gifDownloads = [...existingDownloads, action.payload];
        },

        RemoveGIFDownload: (state, action: PayloadAction<GIFDownload>) => {
            if (action.payload) {
                state.gifDownloads = state.gifDownloads.filter(
                    (gd) =>
                        gd.playId !== action.payload.playId ||
                        gd.playbookId !== action.payload.playbookId ||
                        gd.programId !== action.payload.programId
                );
            }
        },

        ViewershipProfileLoaded: (state, action: PayloadAction<any[] | null>) => {
            state.viewershipProfile = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder.addCase(routerActions.SetActiveTeam, (state) => {
            state.shareConfig = null;
        }),
});

export { playbookReducer, playbookActions, playbookDefaultState };
