import LoadingSpinner from '../loading-spinner/loading-spinner.js';

const GlobalLoadingSpinner: FC = () => {
    return (
        <div className="global-loading-spinner">
            <LoadingSpinner />
        </div>
    );
};

export default GlobalLoadingSpinner;
