import { useSelector, useStore } from 'react-redux';

import { AuthenticationErrors, GeneralErrors, createException, getAuthError } from '@labradorsports/utils';

import { useDispatch } from '../../shared/hooks/index.js';
import { playEditorActions } from '../play-editor/index.js';
import { RootState, Selectors } from '../state.js';

import { authApi, useCreateAccountMutation, useSetProfileFlagMutation } from './api.js';

import { authActions } from './index.js';

export const useCaptchaCreateAccountMutation = () => {
    const store = useStore<RootState>();
    const dispatch = useDispatch();
    const [createAccount] = useCreateAccountMutation();

    const createAccountWithCaptcha = async ({ email, password, firstName, lastName }) => {
        const state = store.getState();

        if (state.playEditor.playModified) {
            dispatch(playEditorActions.PlayModified(false));
        }

        try {
            await new Promise<void>((resolve, reject) => {
                if (!window.grecaptcha) {
                    reject(createException(GeneralErrors.LOADING_ERROR, { details: 'No grecaptcha' }));

                    return;
                }

                window.grecaptcha.ready(function () {
                    window.grecaptcha
                        .execute(
                            PROD
                                ? '6LcSiOokAAAAAPGeVYT6n27e0-1TgzmCDKPT-1Hk'
                                : '6LdCi-okAAAAACgHuBNp7Wjn5EXgGf_v99n7aQ40',
                            { action: 'submit' }
                        )
                        .then(async function (token) {
                            const result = await createAccount({
                                email: email.trim(),
                                password,
                                firstName,
                                lastName,
                                token,
                            });

                            if ('error' in result) {
                                reject(result.error);
                            } else {
                                resolve();
                            }
                        });
                });
            });
        } catch (error) {
            const exc = getAuthError(error);

            if (exc.isOfType(AuthenticationErrors)) {
                dispatch(authActions.AccountFormError(exc));
                return { data: false };
            }

            throw exc;
        }

        await dispatch(authApi.endpoints.login.initiate({ email: email.trim(), password }));

        return { data: true };
    };

    return [createAccountWithCaptcha];
};

export const useUpdateProfileFlagMutation = () => {
    const store = useStore<RootState>();
    const [setProfileFlag] = useSetProfileFlagMutation();
    const uid = useSelector(Selectors.uid);

    const updateProfileFlag = ({ flag, value }) => {
        const state = store.getState();
        const profileFlags = Selectors.profileFlags(state);

        if (profileFlags?.[flag] !== value) {
            setProfileFlag({ flag, value, uid });
        }
    };

    return [updateProfileFlag];
};
