import { HighlightedSearchText } from '@labradorsports/components';
import { findMatchedField } from '@labradorsports/utils';

import { ProfilePicture } from '../../../shared/index.js';
import { useNavigateWithQuery } from '../../../shared/providers/index.js';

interface Props {
    result: any;
    onClick: (result: any) => void;
}

const getMatchedFieldName = (key: string) => {
    if (key.includes('contact')) {
        return 'Contact Name';
    }

    const mappedKey = {
        jersey: 'Jersey #',
        position: 'Position',
    }[key];

    return mappedKey;
};

const getMatchedContact = (key: string, contacts: any[]) => {
    if (!key.includes('contact')) {
        return null;
    }

    const [, idx] = key.split('.');

    return contacts[Number(idx)];
};

const getMatchedField = (highlightResult: any) => {
    // TODO: can this be cleaned up a bit?
    const matchedField = findMatchedField(highlightResult);

    if (!matchedField) {
        return null;
    }

    const showMatchedField = !['firstName', 'lastName', 'email'].includes(matchedField.key);

    if (!showMatchedField) {
        return null;
    }

    const matchedContact = getMatchedContact(matchedField.key, highlightResult.contacts);
    const matchedFieldValue =
        matchedContact && matchedField.key.includes('Name')
            ? `${matchedContact.firstName.value} ${matchedContact.lastName.value}`
            : matchedField.entry.value;

    return {
        name: getMatchedFieldName(matchedField.key),
        value: matchedFieldValue,
    };
};

const RosterSearchResult: FC<Props> = ({ result, onClick }) => {
    const navigate = useNavigateWithQuery();

    const matchedField = getMatchedField(result._highlightResult);

    const showPlayer = () => {
        onClick(result);
        navigate('/team/player/overview', {
            activeTeam: result.teamId,
            rosterPlayerId: result.playerId,
        });
    };

    return (
        <div className="roster-search-result search-result" onClick={showPlayer}>
            <ProfilePicture uid={result.uid} />
            <div>
                <div>
                    <HighlightedSearchText text={result._highlightResult.firstName.value} />{' '}
                    <HighlightedSearchText text={result._highlightResult.lastName.value} />
                </div>
                <div>
                    <HighlightedSearchText text={result._highlightResult.email.value} />
                </div>
                {matchedField ? (
                    <div>
                        {matchedField.name}: <HighlightedSearchText text={matchedField.value} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default RosterSearchResult;
