import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { StyledTextField } from '@labradorsports/components';
import { AuthenticationErrors, getAuthError } from '@labradorsports/utils';

import { useNavigateWithQuery } from '../../shared/providers/index.js';
import { RouterSelectors, useResetPasswordMutation, useValidateResetPasswordQuery } from '../../store/index.js';

const ResetPassword: FC = () => {
    const emailAction = useSelector(RouterSelectors.emailAction);
    const { oobCode } = emailAction;
    const { data: email } = useValidateResetPasswordQuery({ code: oobCode });
    const [resetPassword] = useResetPasswordMutation();
    const navigate = useNavigateWithQuery();
    const [error, setError] = useState<any>({});
    const [codeError, setCodeError] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const validate = () => {
        const newError: any = {};

        if (newPassword !== confirmNewPassword) {
            newError.password = true;
        }

        const valid = !Object.keys(newError)
            .map((k) => newError[k])
            .includes(true);

        setError(newError);

        return valid;
    };

    const newPasswordChange = (evt: any) => {
        setNewPassword(evt.target.value);
    };

    const confirmNewPasswordChange = (evt: any) => {
        setConfirmNewPassword(evt.target.value);
    };

    const changePassword = async () => {
        if (validate()) {
            const result = await resetPassword({ code: oobCode, email, newPassword });

            if ('data' in result) {
                navigate('/playbook');
            } else if (result.error) {
                const exc = getAuthError(result.error);

                if (exc.isOfType(AuthenticationErrors)) {
                    setErrorMessage(exc.message);
                } else {
                    setCodeError(true);
                }
            }
        }
    };

    if (codeError) {
        return (
            <div className="p-3">
                <div className="alert alert-danger">This link is invalid or expired.</div>
            </div>
        );
    }

    if (email) {
        return (
            <div className="p-3 reset-password">
                <div className="reset-password-form">
                    <h2>Reset Password</h2>
                    <p>Enter and confirm your new password.</p>
                    <StyledTextField label="Email" value={email} disabled fullWidth />
                    <StyledTextField
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={newPasswordChange}
                        fullWidth
                    />
                    <StyledTextField
                        label="Confirm New Password"
                        type="password"
                        value={confirmNewPassword}
                        onChange={confirmNewPasswordChange}
                        helperText={error.password && 'Passwords do not match'}
                        error={error.password}
                        fullWidth
                    />
                    {errorMessage ? <div className="error-text">{errorMessage}</div> : undefined}
                    <Button variant="contained" color="secondary" onClick={changePassword} className="mt-2">
                        Change Password
                    </Button>
                </div>
            </div>
        );
    }

    return null;
};

export default ResetPassword;
