import * as Constants from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';

import CollegeFullFieldBackdrop from './field-backdrop/college-full-field-backdrop.js';
import HighSchoolFullFieldBackdrop from './field-backdrop/highschool-full-field-backdrop.js';

const { Sites } = Constants;
const { Sports, PlayerPositions } = SportConfigs[Sites.footballlab];

const PlayTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    DRILL: 'DRILL',
};

const FieldTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    CUSTOM: 'CUSTOM',
};

const FieldBackdropMap = {
    [Sports.CollegeFootball]: CollegeFullFieldBackdrop,
    [Sports.HighSchoolFootball]: HighSchoolFullFieldBackdrop,
};

const FieldDescriptor = 'Field';

const GoalDesign = {};

const Formations = {
    Starting: 'Starting',
};

const ToolTypes = {
    ...Constants.ToolTypes,
    BLOCK: 'BLOCK',
    GROUNDBALL: 'GROUNDBALL',
    PRESNAP: 'PRESNAP',
    SNAP: 'SNAP',
    HANDOFF: 'HANDOFF',

    ...Formations,
};

const FormationDefinitions = {
    [Formations.Starting]: {
        name: 'Starting',
        sport: Sports.CollegeFootball,
        anchorType: 'CENTER',
        attributes: ['pro-style', 'balanced', 'traditional', 'intermediate', 'standard-personnel', 'single-back'],
        description:
            'A classic pro-style formation with a balanced offensive line, single running back, and multiple receiver options. This versatile formation allows for both run and pass plays while maintaining good protection for the quarterback and creating multiple offensive threats.',
        initial: [
            { origin: { x: 44, y: 50 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RIGHT_TACKLE } },
            { origin: { x: 47, y: 50 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RIGHT_GUARD } },
            { origin: { x: 50, y: 50 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.CENTER } },
            { origin: { x: 53, y: 50 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.LEFT_GUARD } },
            { origin: { x: 56, y: 50 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.LEFT_TACKLE } },

            { origin: { x: 50, y: 55 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.QUARTERBACK } },

            { origin: { x: 40, y: 70 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RUNNING_BACK } },
            { origin: { x: 45, y: 70 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RUNNING_BACK } },
            { origin: { x: 50, y: 70 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.TIGHT_END } },
            { origin: { x: 55, y: 70 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.WIDE_RECEIVER } },
            { origin: { x: 60, y: 70 }, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.WIDE_RECEIVER } },
        ],
        fieldPieces: [],
        balls: [],
        version: 1,
    },
};

// Dimensions in yards
const FieldSettings: DefaultFieldConfig = {
    [Sports.CollegeFootball]: {
        width: 53,
        height: 120,
        sideline: {
            right: 2,
        },
        center: {
            x: 25,
            y: 42,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [],
            [FieldTypes.FULL_FIELD]: [],
        },
        zoomLevels: [1.5, 3, 4.5],
    },
    [Sports.HighSchoolFootball]: {
        width: 53,
        height: 120,
        sideline: {
            right: 2,
        },
        center: {
            x: 25,
            y: 42,
        },
        goals: {
            [FieldTypes.HALF_FIELD]: [],
            [FieldTypes.FULL_FIELD]: [],
        },
        zoomLevels: [1.5, 3, 4.5],
    },
};

const FeatureFlags = {
    EnableGoalie: false,
    EnableCustomField: true,
    OnionSkin: true,
};

const NewPlaySettings = {
    FrameCount: 3,
    StartingFormation: Formations.Starting,
    InitialBalls: [
        {
            role: PlayerPositions.CENTER,
        },
    ],
    ViewOptions: {
        onionSkin: {
            framesBack: 2,
            framesForward: 2,
            opacityDelta: 0,
        },
    },
};

const FrameRoles = {
    HOT: 'HOT',
    ONBALL: 'ONBALL',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TWO: 'TWO',
    THREE: 'THREE',
};

const MoveConfig: MoveConfigType = {
    ...Constants.MoveConfig,
    [ToolTypes.BLOCK]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER],
        head: 'line',
    },
    [ToolTypes.GROUNDBALL]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        strokeDashArray: '2, 4',
        shorten: 12,
    },
    [ToolTypes.PRESNAP]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        pattern: 'WAVE',
    },
    [ToolTypes.SNAP]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER],
        strokeDashArray: '2, 2',
    },
    [ToolTypes.HANDOFF]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER],
        strokeDashArray: '2, 2',
    },
};

const RoleDisplay = {
    [PlayerPositions.LEFT_TACKLE]: 'LT',
    [PlayerPositions.LEFT_GUARD]: 'LG',
    [PlayerPositions.CENTER]: 'C',
    [PlayerPositions.RIGHT_GUARD]: 'RG',
    [PlayerPositions.RIGHT_TACKLE]: 'RT',
    [PlayerPositions.QUARTERBACK]: 'QB',
    [PlayerPositions.RUNNING_BACK]: 'RB',
    [PlayerPositions.TIGHT_END]: 'TE',
    [PlayerPositions.WIDE_RECEIVER]: 'WR',
};

const FrameRoleNames = {
    [ToolTypes.ORANGEPLAYER]: {
        [FrameRoles.ONBALL]: 'On-Ball',
        [FrameRoles.HOT]: 'Hot Slide',
        [FrameRoles.LEFT]: 'Left',
        [FrameRoles.RIGHT]: 'Right',
        [FrameRoles.TWO]: '2-Slide',
        [FrameRoles.THREE]: '3-Slide',
    },
};

const FieldLines = {
    GOAL: 'Goal',
    FOOTBALL: 'Football',
};

const PlayTheme: PlayThemeSpec = {
    Players: {
        [PlayerPositions.CENTER]: {
            fill: '#5592C2',
            shape: Constants.PlayerShapes.RECT,
        },
        [PlayerPositions.LEFT_TACKLE]: {
            fill: '#5592C2',
        },
        [PlayerPositions.LEFT_GUARD]: {
            fill: '#5592C2',
        },
        [PlayerPositions.RIGHT_TACKLE]: {
            fill: '#5592C2',
        },
        [PlayerPositions.RIGHT_GUARD]: {
            fill: '#5592C2',
        },
    },
};

const PlayConfig: PlayConfigSpec = {
    Site: Sites.footballlab,
    PlayTypes,
    FieldTypes,
    FieldDescriptor,
    GoalDesign,
    FieldSettings,
    FeatureFlags,
    NewPlaySettings,
    ToolTypes,
    MoveConfig,
    FrameRoles,
    FrameRoleNames,
    RoleDisplay,
    FieldLines,
    Formations,
    FieldBackdropMap,
    MovementSpeed: 3,
    FormationDefinitions,
};

export default PlayConfig;
