import { AddressDisplay, HighlightedSearchText } from '@labradorsports/components';
import { getCSSVariables } from '@labradorsports/utils';

import { EventColorClasses } from '../../../shared/index.js';
import { useNavigateWithQuery } from '../../../shared/providers/index.js';

interface Props {
    result: any;
    onClick: (result: any) => void;
}

const EventSearchResult: FC<Props> = ({ result, onClick }) => {
    const navigate = useNavigateWithQuery();

    const { streetAddress, city, state, zipCode, locationName } = result._highlightResult;

    const showEvent = () => {
        onClick(result);
        navigate('/schedule/event/details', {
            activeTeam: result.teamId,
            viewEventId: result.eventId,
        });
    };

    return (
        <div className="event-search-result search-result" onClick={showEvent}>
            <div
                className={`event-result-details ${EventColorClasses[result.eventType]}`}
                style={getCSSVariables({
                    '--event-color': result.customEventTypeColor,
                })}
            >
                <div className="d-flex">
                    <div className="flex-grow-1">{result.eventType}</div>
                    <div>
                        <HighlightedSearchText text={result._highlightResult.date.value} />
                    </div>
                </div>
                <AddressDisplay
                    addressComponents={{
                        streetAddress: streetAddress?.value,
                        city: city?.value,
                        state: state?.value,
                        zipCode: zipCode?.value,
                        name: locationName?.value,
                    }}
                />
            </div>
        </div>
    );
};

export default EventSearchResult;
