import { Sites, SiteConstants } from '@labradorsports/constants';

export function testifyLink(link: string, isProd = true): string {
    if (isProd) {
        return link;
    }

    const domainRegex = /https:\/\/([a-z]*)\.labradorsports\.com/;
    const match = link.match(domainRegex);
    const [, sport] = match;

    const site = {
        lacrosse: Sites.lacrosselab,
        basketball: Sites.hoopslab,
        football: Sites.footballlab,
    }[sport];

    return link.replace(domainRegex, SiteConstants[site].TestUrl);
}

export function buildInviteLink(site: string, entityId: string, linkId: string): string {
    const siteConfig = SiteConstants[site].SiteConfig;

    return `${siteConfig.Url}/join-team?k=${linkId}&j=${entityId}`;
}

export function linkWithQuery(path: string, searchParams: URLSearchParams): string {
    if (!path.includes('?') && searchParams.toString()) {
        path = `${path}?${searchParams.toString()}`;
    }

    return path;
}
