import { useSelector } from 'react-redux';

import { LoadingSpinner } from '@labradorsports/components';

import { RootState } from '../../../store/index.js';

const InlineLoadingSpinner: FC = () => {
    const loading = useSelector((state: RootState) => state.main.loadingCount > 0);

    if (loading) {
        return null;
    }

    return <LoadingSpinner />;
};

export default InlineLoadingSpinner;
