import { MouseEvent, ReactNode } from 'react';

export interface PanelHeaderProps {
    title: string;
    subtitle?: string;
    actionMenu?: any;
    logoUrl?: string;
    fullPage?: boolean;
    onBack?: (evt: MouseEvent<any, any>) => any;
    children?: ReactNode;
}

const PanelHeader: FC<PanelHeaderProps> = ({ title, subtitle, actionMenu, onBack, logoUrl, children, fullPage }) => {
    const classes = ['panel-header'];
    if (actionMenu) classes.push('has-action-menu');
    if (onBack) classes.push('has-back-button');
    if (logoUrl) classes.push('has-logo');
    if (fullPage) classes.push('full-page');

    return (
        <div className={classes.join(' ')}>
            <div className="panel-header-content">
                {onBack ? <span className="back-button" onClick={onBack} /> : undefined}
                <div className="header-text">
                    <h4>{title}</h4>
                    <small>{subtitle}</small>
                </div>
                {children ? <div className="panel-content">{children}</div> : null}
                <div className="logo-container">
                    {logoUrl ? <img className="team-logo" src={logoUrl} alt="Team logo" /> : undefined}
                </div>
                <div className="action-menu">{actionMenu}</div>
            </div>
        </div>
    );
};

export default PanelHeader;
