import { serializeObjectPaths, stateDeserializers, useSite } from '@labradorsports/utils';

import { getGlobals } from '../../store/index.js';
import { CFFetcher } from '../cloud-functions.js';
import { Logger } from '../logger.js';

import { GlobalsProvider } from './globals.js';

export const SiteGlobalsProvider = ({ children }) => {
    const site = useSite();
    const logger = new Logger(site.env);
    const cff = new CFFetcher(site.env, logger);
    const initialState = serializeObjectPaths(window.__SSR_STATE__, stateDeserializers);
    const globals = getGlobals(initialState, { logger, cff, site });

    return (
        <GlobalsProvider globals={globals} serverState={initialState}>
            {children}
        </GlobalsProvider>
    );
};
