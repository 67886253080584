import './404.html';
import '@sport/favicon';
import '@sport/res';
import './main.scss';

import './firebase-config.js';

import 'core-js/stable/array/flat-map';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, HashRouter } from 'react-router';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { hydrateRoot, createRoot } from 'react-dom/client';

import {
    AppWrapper,
    SiteContent,
    AppContent,
    getSettings,
    initEnv,
    SiteGlobalsProvider,
    RouterStateProvider,
} from '@labradorsports/ui';
import { SiteContext } from '@labradorsports/utils';
import { Config, TestUrl, RenderingConfig } from '@sport';

import './app/assets.js';

const Router = APP ? HashRouter : BrowserRouter;
const Content = APP ? AppContent : SiteContent;

const rootElement = document.getElementById('react');

const settings = getSettings();

const cache = createCache({ key: 'css' });

if (APP) {
    window.__SSR_STATE__ = {};
}

const rootContent = (
    <CacheProvider value={cache}>
        <HelmetProvider>
            <SiteContext.Provider
                value={{
                    Config,
                    ...RenderingConfig,
                    Images: {
                        ...RenderingConfig.Images,
                        Logo: Config.SiteConfig.Logo,
                    },
                    env: {
                        ...initEnv(Config, TestUrl),
                        ...settings,
                    },
                }}
            >
                <SiteGlobalsProvider>
                    <Router>
                        <RouterStateProvider>
                            <AppWrapper>
                                <CssBaseline />
                                <Content />
                            </AppWrapper>
                        </RouterStateProvider>
                    </Router>
                </SiteGlobalsProvider>
            </SiteContext.Provider>
        </HelmetProvider>
    </CacheProvider>
);

if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, rootContent);
} else {
    const root = createRoot(rootElement);
    root.render(rootContent);
}
