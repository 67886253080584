/// <reference types="@types/google.maps" />

import { createException, GeneralErrors } from './errors.js';

declare global {
    interface Window {
        google: {
            maps: typeof google.maps;
        };
    }
}

export async function getPlaceAPI() {
    try {
        const result = (await window.google.maps.importLibrary('places')) as google.maps.PlacesLibrary;

        return result.Place;
    } catch (err) {
        throw createException(GeneralErrors.GOOGLE_MAPS_ERROR, {
            nestedError: err,
            details: 'Failed to load places library.',
        });
    }
}

export async function searchForPlaces(search: string): Promise<google.maps.places.Place[]> {
    const request = {
        textQuery: search,
        fields: ['formattedAddress', 'addressComponents', 'location'],
        language: 'en-US',
        maxResultCount: 5,
        region: 'us',
    };

    const placeAPI = await getPlaceAPI();
    try {
        const { places } = await placeAPI.searchByText(request);
        return places;
    } catch (err) {
        throw createException(GeneralErrors.GOOGLE_MAPS_ERROR, {
            nestedError: err,
            details: 'Error searching for places.',
        });
    }
}

export const findAddressComponents = (place: any) => {
    const findComponent = (type: string, short = false) => {
        const comp = place.addressComponents.filter((c: any) => {
            return c.types.includes(type);
        });

        if (comp.length > 0) {
            return short ? comp[0].shortText : comp[0].longText;
        }

        return '';
    };

    const addr: any = {};

    const streetNum = findComponent('street_number');
    const streetAddr = findComponent('route');

    if (streetNum && streetAddr) {
        addr.streetAddress = `${streetNum} ${streetAddr}`;
    }

    addr.city = findComponent('locality');
    addr.state = findComponent('administrative_area_level_1', true);
    addr.zipCode = findComponent('postal_code');

    return addr;
};
