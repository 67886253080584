import { useSite } from '@labradorsports/utils';

import { NavLinkWithQuery } from '../../shared/structural/index.js';

interface Props {
    navLinks: [string, string][];
}

const HeaderNav: FC<Props> = ({ navLinks }) => {
    const { Config } = useSite();

    return (
        <div className="header-nav">
            {navLinks.map(([text, to]) => (
                <NavLinkWithQuery to={to} key={to}>
                    {text}
                </NavLinkWithQuery>
            ))}
            {Config.Features.Community ? (
                <NavLinkWithQuery to="community" className="community-link">
                    Community
                </NavLinkWithQuery>
            ) : null}
        </div>
    );
};

export default HeaderNav;
