import { SimpleModal, MailTo, ErrorDetails } from '@labradorsports/components';
import { Exception } from '@labradorsports/utils';

interface Props {
    error: Exception;
}

const CrashPage: FC<Props> = ({ error }) => {
    const refresh = () => {
        // Pause to allow more time for error logs to reach the server
        setTimeout(() => {
            if (APP) {
                window.location.href = 'index.html';
            } else {
                window.location.reload();
            }
        }, 2000);
    };

    return <ErrorDetails onClose={refresh} error={error} extraText="Press OK to refresh the page." />;
};

export default CrashPage;
