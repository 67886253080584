import EmbedBlot  from 'quill/blots/embed';

// eslint-disable-next-line no-script-url
const javascriptVoid = 'javascript:void(0)';

const getHref = (node: any) => {
    const href = node.getAttribute('href');
    return href === javascriptVoid ? node.getAttribute('data-href') : href;
};

interface PlayLinkData {
    playId: string;
    teamId: string;
    name: string;
    className: string;
}

export default (Config: ConfigSpec) => {
    class PlayLinkBlot extends EmbedBlot {
        static create(value: PlayLinkData): HTMLElement {
            const node = super.create(value) as HTMLElement;

            node.setAttribute('class', value.className ?? 'play-link-pill');
            node.textContent = value.name;

            const path = `/playbook?p=${value.playId}&t=${value.teamId}&sp=team`;

            // APP read-only link should use a hash URL to avoid opening a browser
            const url = APP ? `${window.location.pathname}#${path}` : `${Config.SiteConfig.Url}${path}`;

            if (value.className?.includes('read-only')) {
                // allow the parent component to capture a click
                node.setAttribute('href', javascriptVoid);
                node.setAttribute('data-href', url);
            } else {
                node.setAttribute('href', url);
            }

            return node;
        }

        static formats(node: HTMLElement): { url: string } {
            return {
                url: getHref(node),
            };
        }

        static value(node: HTMLElement): PlayLinkData {
            if (node.className.includes('play-link-pill')) {
                // Ensure there is an & to match last param on
                const href = `${getHref(node)}&`;
                const playIdMatch = href.match(/[&?]p=(.*?)&/);
                const teamIdMatch = href.match(/[&?]t=(.*?)&/);

                return {
                    playId: playIdMatch?.[1],
                    teamId: teamIdMatch?.[1],
                    name: node.textContent,
                    className: node.className,
                };
            }

            return null;
        }

        /*
         * Redefine the `update` method to handle the `childList` case.
         * This is necessary to correctly handle "backspace" on Android using Gboard.
         * It behaves differently than other cases and we need to handle the node
         * removal instead of the `characterData`.
         */
        update(mutations: any[], context: any): void {
            // `childList` mutations are not handled on Quill
            // see `update` implementation on:
            // https://github.com/quilljs/quill/blob/master/blots/embed.js
            mutations.forEach((mutation: any) => {
                if (mutation.type !== 'childList') return;
                if (mutation.removedNodes.length === 0) return;

                setTimeout(() => this.remove(), 0);
            });

            const unhandledMutations = mutations.filter((m: any) => m.type !== 'childList');
            super.update(unhandledMutations, context);
        }

        appendChild(): void {
            // Quill attempts to append the cursor to this Blot - just do nothing
        }
    }

    PlayLinkBlot.blotName = 'play-link';
    PlayLinkBlot.tagName = 'a';
    PlayLinkBlot.className = 'play-link-pill';

    return PlayLinkBlot;
};
