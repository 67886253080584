import { DataTags } from '../../shared/constants.js';
import { mainApi } from '../main/api.js';
import { playbookApi } from '../playbook/api.js';
import { RootState, Selectors } from '../state.js';
import { TD } from '../types.js';

import { playEditorActions } from './index.js';

let newPlayId = 0;
export const createPlay = (type: string, sport: string, name?: string, description = '', tags: string[] = []) => {
    return async (dispatch: TD, getState: () => RootState) => {
        const playbookId = Selectors.playbookId(getState());

        newPlayId += 1;
        const id = `__${newPlayId}`;
        dispatch(playEditorActions.CreatePlay({ id, type, sport, name, description, tags }));

        playbookApi.util.invalidateTags([{ type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_LIST` }]);
    };
};

export const setVideoLink = (videoLink: string) => {
    return async (dispatch: TD) => {
        dispatch(
            playEditorActions.UpdatePlay({
                videoLink,
            })
        );

        if (videoLink !== '') {
            const {
                data: { thumbnailUrl },
            } = await dispatch(mainApi.endpoints.getVideoDetails.initiate({ videoLink }));

            dispatch(
                playEditorActions.UpdatePlay({
                    thumbnailUrl,
                })
            );
        } else {
            dispatch(
                playEditorActions.UpdatePlay({
                    thumbnailUrl: null,
                })
            );
        }
    };
};
