import { Sites, Addons, Plans, GlobalPublicSite, PlanTeamSizes } from './common.js';

export const Sports = {
    HighSchoolFootball: 'HIGH_SCHOOL_FOOTBALL',
    CollegeFootball: 'COLLEGE_FOOTBALL',
};

export const SiteConfig = {
    Site: Sites.footballlab,
    SiteName: 'Football Lab',
    Sport: 'football',
    FriendlyUrl: 'FootballLab.com',
    Url: 'https://football.labradorsports.com',
    PublicUrl: `${GlobalPublicSite}/football`,
    InstagramUrl: 'https://www.instagram.com/lacrosselab_sports/',
    FacebookUrl: 'https://www.facebook.com/LacrosseLab/',
    TwitterUrl: 'https://twitter.com/lacrosse_lab',
    YouTubeUrl: 'https://www.youtube.com/channel/UCnDsmzWjusDL_Rsth8Hs5Qw',
    DefaultDescription:
        'The most comprehensive software available to football coaches. Create and share your football playbook with your players.',
    GooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.labradorsports.hoopslab',
    AppStoreUrl: 'https://apps.apple.com/la/app/hoops-lab/id1576985969',
};

export const TestUrl = 'https://football-lab-test1.firebaseapp.com';

export const AddonConfig = {
    [Addons.PLAYERS]: {
        Name: 'Roster Expansion',
        MonthlyPrice: 3,
        AnnualPrice: 30,
        ExtraSlots: 2,
    },
    [Addons.COACHES]: {
        Name: 'Additional Coach',
        MonthlyPrice: 3,
        AnnualPrice: 30,
        ExtraSlots: 1,
    },
    [Addons.ADMINS]: {
        Name: 'Additional Admin',
        MonthlyPrice: 4,
        AnnualPrice: 30,
        ExtraSlots: 1,
    },
};

export const TeamSizes: {
    [plan: string]: PlanTeamSizes;
} = {
    [Plans.BASIC]: {
        Admin: 1,
        Coach: 4,
        Player: 30,
    },
    [Plans.COMPLETE]: {
        Admin: 1,
        Coach: 6,
        Player: 60,
    },
    [Plans.COLLEGIATE]: {
        Admin: 1,
        Coach: 12,
        Player: 120,
    },
};

export const Features = {
    Roster: true,
    DepthChart: true,
    Evaluation: false,
    Schedule: true,
    ScoutingReport: true,
    Community: true,
    PlayDesigner: true,
    TeamPlaybook: true,
    TeamAdmin: true,
    TeamDocuments: true,
};
