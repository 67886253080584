import { Move } from '@labradorsports/play-rendering';
import { getCDNUrl } from '@labradorsports/utils';

import { downloadFileNative } from '../app/plugins/file.js';

import Logger from './logger.js';

export function linePlayerId(line: Move, i: number): number {
    return (line.id + 1) * 1000 + i;
}

export function getAssetUrl(getter: AssetUrlGetter | string): string {
    const domain = getCDNUrl(APP, PROD);

    return typeof getter === 'string' ? getter : getter(domain);
}

export function trackUserProperties(uid: string, properties: any) {
    window.StonlyWidget('identify', uid, properties);
}

export async function downloadFile(logger: Logger, filename: string, url: string) {
    const response = await fetch(url);
    const blob = await response.blob();

    logger.log('loaded blob');

    if (APP) {
        logger.log('native', { filename });

        await downloadFileNative(logger, blob, filename);
    } else {
        logger.log('web', { filename });
        const objUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}
