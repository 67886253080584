import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authActions } from '../auth/index.js';

export interface CommunityState {
    showSignupPrompt: boolean;
}

const communityDefaultState: CommunityState = {
    showSignupPrompt: false,
};

const { reducer: communityReducer, actions: communityActions } = createSlice({
    name: 'community',
    initialState: communityDefaultState,
    reducers: {
        ShowSignupPrompt: (state, action) => {
            state.showSignupPrompt = action.payload;
        },
    },
});

export { communityReducer, communityActions, communityDefaultState };
