import { CommunityPlaybookSections, ShareLevels } from '@labradorsports/constants';

import { DataTags } from '../../shared/constants.js';
import { createApiSlice } from '../api.js';
import { mainActions } from '../main/index.js';
import { playEditorActions } from '../play-editor/index.js';
import { routerActions } from '../router/index.js';
import { teamsApi } from '../teams/api.js';
import { CustomMutationDefinition, CustomQueryDefinition } from '../types.js';

const processCommunityPlay = (play: any) => ({
    ...play,
    createdDate: new Date(play.createdDate),
    sharedOn: new Date(play.sharedOn),
});

export const communityApi = createApiSlice({
    getCommunityPlaybook: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        query: ({ section = CommunityPlaybookSections.LATEST, page = 1 }) => ({
            path: 'community/getCommunityPlaybook',
            query: { section, page },
        }),
        transformResponse: (response) => {
            return {
                ...response,
                plays: response.plays.map(processCommunityPlay),
            };
        },
        onQueryEnded: ({ data: result }, { extra }) => {
            const { logger } = extra;
            const { plays, estimatedPages } = result;

            logger.log('getCommunityPlaybook complete', { plays: plays.length, estimatedPages });
        },
        providesTags: ({ plays }) => [
            ...plays.map((play) => ({ type: DataTags.COMMUNITY_PLAY, id: play.id })),
            { type: DataTags.COMMUNITY_PLAY, id: 'LIST' },
        ],
    } as CustomQueryDefinition<{ section?: string; page?: number }, any>,

    getCommunityHomepage: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        query: () => ({
            path: 'community/getCommunityHomepage',
        }),
        transformResponse: ({ newPlays, popularPlays, trendingPlays }) => {
            return {
                newPlays: newPlays.map(processCommunityPlay),
                popularPlays: popularPlays.map(processCommunityPlay),
                trendingPlays: trendingPlays.map(processCommunityPlay),
            };
        },
        providesTags: ({ newPlays, popularPlays, trendingPlays }) => [
            ...newPlays.map((play) => ({ type: DataTags.COMMUNITY_PLAY, id: play.id })),
            ...popularPlays.map((play) => ({ type: DataTags.COMMUNITY_PLAY, id: play.id })),
            ...trendingPlays.map((play) => ({ type: DataTags.COMMUNITY_PLAY, id: play.id })),
            { type: DataTags.COMMUNITY_PLAY, id: 'NEW_LIST' },
            { type: DataTags.COMMUNITY_PLAY, id: 'POPULAR_LIST' },
            { type: DataTags.COMMUNITY_PLAY, id: 'TRENDING_LIST' },
        ],
    } as CustomQueryDefinition<void, any>,

    getCommunityPlayComments: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        isValid: ({ id }) => Boolean(id),
        query: ({ id }) => ({
            path: 'community/getCommunityPlayComments',
            query: { id },
        }),
        transformResponse: (response) => {
            return response.map((comment) => ({
                ...comment,
                createdDate: new Date(comment.createdDate),
            }));
        },
        providesTags: (result, meta, { id }) => [{ type: DataTags.COMMUNITY_COMMENT, id: `${id}_LIST` }],
    } as CustomQueryDefinition<{ id: string }, any>,

    getCommunityPlay: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        isValid: ({ id }) => Boolean(id),
        query: ({ id }) => ({
            path: 'community/getCommunityPlay',
            query: { id },
        }),
        transformResponse: (response, meta, { id }) => {
            return {
                id,
                ...response.play,
                ...processCommunityPlay(response.playlistItem),
            };
        },
        onQueryEnded: async ({ data: play }, { dispatch }) => {
            dispatch(playEditorActions.PlayLoaded(play));

            if (play.branding) {
                await dispatch(teamsApi.endpoints.loadEntityLogo.initiate({ programId: play.branding.logoEntityId }));
            }
        },
        providesTags: (result) => [{ type: DataTags.COMMUNITY_PLAY, id: result.id }],
    } as CustomQueryDefinition<{ id: string }, any>,

    addCommunityPlayComment: {
        type: 'mutation',
        isValid: ({ id }) => Boolean(id),
        query: ({ id, text }) => ({
            path: 'community/addCommunityPlayComment',
            body: { id, text },
        }),
        invalidatesTags: (result, meta, { id }) => [{ type: DataTags.COMMUNITY_COMMENT, id: `${id}_LIST` }],
    } as CustomMutationDefinition<{ id: string; text: string }, any>,

    addCommunityPlayLike: {
        type: 'mutation',
        isValid: ({ id }) => Boolean(id),
        query: ({ id }) => ({
            path: 'community/addCommunityPlayLike',
            body: { id },
        }),
        invalidatesTags: (result, meta, { id }) => [{ type: DataTags.COMMUNITY_PLAY, id }],
    } as CustomMutationDefinition<{ id: string }, any>,

    setupCommunityProfile: {
        type: 'mutation',
        query: ({ screenName, description, birthdate }) => ({
            path: 'community/setupCommunityProfile',
            body: {
                screenName,
                description,
                birthdate,
            },
        }),
        invalidatesTags: (result, meta, { screenName }) => [
            { type: DataTags.COMMUNITY_PROFILE, id: screenName },
            { type: DataTags.PROFILE },
        ],
    } as CustomMutationDefinition<
        {
            screenName: string;
            description: string;
            birthdate?: Date;
        },
        any
    >,

    getCommunityProfile: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        isValid: ({ screenName }) => Boolean(screenName),
        query: ({ screenName = 'me' }) => ({
            path: 'community/getCommunityProfile',
            query: { screenName },
        }),
        transformResponse: ({ profile }) => {
            return profile
                ? {
                      ...profile,
                      sharedPlays: profile.sharedPlays?.map(processCommunityPlay) ?? [],
                  }
                : null;
        },
        onQueryEnded: ({ data: communityProfile }, { dispatch, getState }, { screenName }) => {
            const state = getState();
            if (screenName !== 'me' && state.auth.user && communityProfile?.uid === state.auth.user.uid) {
                dispatch(routerActions.SetCommunityProfileId('me'));
            }
        },
        providesTags: (result, meta, { screenName }) => [{ type: DataTags.COMMUNITY_PROFILE, id: screenName }],
    } as CustomQueryDefinition<{ screenName: string }, any>,

    shareToCommunity: {
        type: 'mutation',
        query: ({ playId, name, description, tags, srcPlaybookId, programId }) => ({
            path: 'community/shareToCommunity',
            body: {
                playId,
                shareLevel: ShareLevels.COMMUNITY,
                srcPlaybookId,
                name,
                description,
                tags,
                programId,
            },
        }),
        onQueryEnded: (result, { dispatch }, { name }) => {
            dispatch(mainActions.GenericAlert(`${name} was shared to the Community playbook`));
        },
        invalidatesTags: () => [
            { type: DataTags.COMMUNITY_PROFILE, id: 'me' },
            { type: DataTags.COMMUNITY_PLAY, id: 'LIST' },
        ],
    } as CustomMutationDefinition<
        {
            playId: string;
            name: string;
            description: string;
            tags: string[];
            srcPlaybookId: string;
            programId?: string;
        },
        any
    >,

    unshareCommunity: {
        type: 'mutation',
        query: ({ playId, srcPlaybookId, programId }) => ({
            path: 'community/shareToCommunity',
            body: {
                playId,
                shareLevel: ShareLevels.NOONE,
                srcPlaybookId,
                programId,
            },
        }),
        onQueryEnded: (response, { dispatch }) => {
            dispatch(mainActions.GenericAlert(`The play was removed from the Community playbook`));
        },
        invalidatesTags: () => [
            { type: DataTags.COMMUNITY_PROFILE, id: 'me' },
            { type: DataTags.COMMUNITY_PLAY, id: 'LIST' },
        ],
    } as CustomMutationDefinition<
        {
            playId: string;
            srcPlaybookId: string;
            programId: string;
        },
        any
    >,

    searchCommunity: {
        type: 'query',
        extraOptions: {
            anonymousHooks: true,
        },
        isValid: ({ query }) => Boolean(query),
        query: ({ query, page = 1 }) => ({
            path: 'search/community',
            query: {
                query,
                page,
            },
        }),
        transformResponse: ({ plays, results, estimatedPages }) => {
            return { plays: plays.map(processCommunityPlay), results: results.hits, estimatedPages };
        },
    } as CustomQueryDefinition<{ query: string; page?: number }, any>,

    copyCommunityPlay: {
        type: 'mutation',
        isValid: ({ id }) => Boolean(id),
        query: ({ id }) => ({
            path: 'community/copyCommunityPlay',
            body: { id },
        }),
        onQueryEnded: (response, { dispatch }) => {
            dispatch(mainActions.GenericAlert('The play was copied to your playbook'));
        },
        invalidatesTags: (result, meta, { playbookId }) => [
            { type: DataTags.COMMUNITY_PLAY, id: 'LIST' },
            { type: DataTags.PLAYLIST_ITEM, id: `${playbookId}_LIST` },
        ],
    } as CustomMutationDefinition<{ id: string; playbookId: string }, any>,
});

export const {
    useGetCommunityPlaybookQuery,
    useGetCommunityPlayCommentsQuery,
    useGetCommunityProfileQuery,
    useGetCommunityPlayQuery,
    useAddCommunityPlayCommentMutation,
    useAddCommunityPlayLikeMutation,
    useSetupCommunityProfileMutation,
    useShareToCommunityMutation,
    useUnshareCommunityMutation,
    useSearchCommunityQuery,
    useCopyCommunityPlayMutation,
    useGetCommunityHomepageQuery,
} = communityApi;
