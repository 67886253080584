import { useState, useEffect, useMemo, createContext, useContext } from 'react';

import { FieldViewport } from '../models/index.js';
import { getDefaultZoomOffset, getDefaultZoomFactor, ZoomOffset } from '../utils/index.js';

import { usePlayEditor } from './playEditor.js';

export interface FieldViewportValue {
    fieldViewport: FieldViewport;
    zoomOffset: ZoomOffset;
    setZoomFactor: (zoomFactor: number) => void;
    setZoomOffset: (zoomOffset: ZoomOffset) => void;
    zoomIn: () => void;
    zoomOut: () => void;
    enableZoomIn: boolean;
    enableZoomOut: boolean;
}

export const FieldViewportContext = createContext<FieldViewportValue>(null);

export const useFieldViewport = (): FieldViewportValue => {
    return useContext(FieldViewportContext);
};

export const FieldViewportProvider: FC = ({ children }) => {
    const { dimensions, play, playData, setDisableAnimation, PlayConfig } = usePlayEditor();
    const [zoomFactor, setZoomFactor] = useState<number>(null);
    const [zoomOffset, setZoomOffset] = useState<{
        x: number;
        y: number;
    }>({
        x: 0,
        y: 0,
    });

    const { zoomLevels = [1, 1.5] } = PlayConfig.FieldSettings[play?.sport] ?? {};

    const fieldViewport = useMemo(
        () =>
            dimensions && play
                ? new FieldViewport(
                      play.field,
                      play.viewOptions.flipField,
                      zoomFactor ?? 1,
                      dimensions.width,
                      dimensions.height
                  )
                : null,
        [play, dimensions, zoomFactor, play?.viewOptions?.flipField]
    );

    useEffect(() => {
        setZoomFactor(getDefaultZoomFactor(PlayConfig, play));
    }, [playData?.id]);

    useEffect(() => {
        if (fieldViewport) {
            setZoomOffset(getDefaultZoomOffset(PlayConfig, fieldViewport, play.field, play.players));
        }
    }, [zoomFactor, fieldViewport?.h, fieldViewport?.w, play?.viewOptions?.flipField]);

    const updateZoomFactor = (newZoomFactor: number) => {
        setDisableAnimation(true);
        setZoomFactor(newZoomFactor);

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setDisableAnimation(false);
            });
        });
    };

    const currentZoomIdx = zoomLevels.indexOf(fieldViewport?.zoomFactor);

    const zoomIn = () => {
        const newZoomLevelIdx = Math.min(zoomLevels.length - 1, currentZoomIdx + 1);
        updateZoomFactor(zoomLevels[newZoomLevelIdx]);
    };

    const zoomOut = () => {
        const newZoomLevelIdx = Math.max(0, currentZoomIdx - 1);
        updateZoomFactor(zoomLevels[newZoomLevelIdx]);
    };

    return (
        <FieldViewportContext.Provider
            value={{
                fieldViewport,
                zoomOffset,
                setZoomFactor: updateZoomFactor,
                setZoomOffset,
                zoomIn,
                zoomOut,
                enableZoomIn: currentZoomIdx < zoomLevels.length - 1,
                enableZoomOut: currentZoomIdx > 0,
            }}
        >
            {children}
        </FieldViewportContext.Provider>
    );
};
