import { RefObject, useState } from 'react';
import { useSelector } from 'react-redux';

import { DropdownMenu, PlayTagDisplay } from '@labradorsports/components';
import { PlaybookTabs, ShareLevels } from '@labradorsports/constants';
import { countDisplay, getAllowedPlayOperations, noPropagation } from '@labradorsports/utils';

import { useNavigateWithQuery } from '../../shared/providers/index.js';
import {
    RootState,
    Selectors,
    useShiftPlaybookItemsMutation,
    useShareItemMutation,
    ShareConfig,
} from '../../store/index.js';
import TeamItemsDropdown from '../team-items-dropdown/team-items-dropdown.js';

interface Props {
    play: any;
    readOnly: boolean;
    onClick: AnyFunction;
    moveToFolder?: AnyFunction;
    deleteClick?: AnyFunction;
    unshareClick?: AnyFunction;
    playbookType: string;
    copyPlay: AnyFunction;
    hideDropdown?: boolean;
    onRename: AnyFunction;
    getMenuPosition?: (menuHeight: number) => 'bottom' | 'top';
    dropdownMenuRef?: RefObject<HTMLDivElement>;
    startShare?: (shareConfig?: ShareConfig) => void;
}

const PlayListPlay: FC<Props> = ({
    play,
    readOnly,
    onClick,
    deleteClick,
    unshareClick,
    copyPlay,
    moveToFolder,
    playbookType,
    hideDropdown,
    onRename,
    getMenuPosition,
    dropdownMenuRef,
    startShare,
}) => {
    const [shareItem] = useShareItemMutation();
    const [movePlaybookItem] = useShiftPlaybookItemsMutation();
    const activeTeam = useSelector(Selectors.activeTeam);
    const viewed = useSelector((state: RootState) => state.playbook.viewedPlayIds);
    const team = useSelector(Selectors.currentTeam);
    const teamRole = useSelector(Selectors.currentUserTeamRole);
    const isTeamPlaybook = playbookType === PlaybookTabs.TEAM;

    const [tagsExpanded, setTagsExpanded] = useState(false);
    const navigate = useNavigateWithQuery();

    const _onClick = (evt: any) => {
        // Ignore clicks from the dropdown menu
        if (!evt.target.closest('.dropdown-menu-container')) {
            onClick();
        }
    };

    const showViewerDetailsClick = noPropagation(() => {
        navigate('/playbook/usage', {
            viewerDetailsPlayId: play.id,
        });
    });

    const sharePlayClick = () => {
        startShare({
            itemIds: [play.id],
        });
    };

    const setShareAudience = (audience: string) => {
        shareItem({
            itemId: play.id,
            proxyShare: false,
            modifications: {
                [activeTeam]: audience,
            },
        });
    };

    const moveItem = (up: boolean) => {
        movePlaybookItem({
            itemIds: [play.id],
            up,
        });
    };

    const allowedTo = getAllowedPlayOperations(team, play, readOnly, playbookType, teamRole);

    const menuOptions: {
        text: string;
        onClick?: () => any;
        show: boolean;
    }[] = [
        {
            text: 'Rename',
            onClick: onRename,
            show: allowedTo.RENAME,
        },
        {
            text: 'Move to Folder',
            onClick: moveToFolder,
            show: allowedTo.MOVE,
        },
        {
            text: 'Share',
            onClick: sharePlayClick,
            show: allowedTo.SHARE,
        },
        {
            text: 'Delete',
            onClick: deleteClick,
            // TeamItemsDropdown handles remove button
            show: allowedTo.DELETE && !isTeamPlaybook,
        },
        {
            text: 'Copy',
            onClick: copyPlay,
            show: allowedTo.COPY,
        },
        {
            text: 'Move Up',
            onClick: () => moveItem(true),
            show: allowedTo.MOVE,
        },
        {
            text: 'Move Down',
            onClick: () => moveItem(false),
            show: allowedTo.MOVE,
        },
    ].filter(({ show }) => show);

    const toggleShowTags = noPropagation(() => setTagsExpanded(!tagsExpanded));

    const playName = `${play.name ?? 'New Play'}${play.coachOnly ? ' *' : ''}`;

    const classNames = ['play-list-play', 'list-item-content'];

    if (!isTeamPlaybook || !viewed || viewed.includes(play.id)) {
        classNames.push('viewed');
    }

    if (play.hidden) {
        classNames.push('blurred');
    }

    return (
        <div className={classNames.join(' ')} onClick={!play.hidden ? _onClick : null}>
            <div className="play-name-container">
                <span className="play-name">{playName}</span>
                <div className={`play-tag-collapse ${tagsExpanded ? 'expanded' : ''}`}>
                    <PlayTagDisplay tags={play.tags} />
                    {play.tags?.length > 2 ? (
                        <button className="button-link" onClick={toggleShowTags}>
                            {tagsExpanded ? 'Hide' : 'See All'}
                        </button>
                    ) : null}
                </div>
                {readOnly || !isTeamPlaybook ? undefined : (
                    <div className="viewer-count">
                        <button className="button-link small" onClick={showViewerDetailsClick}>
                            {countDisplay('Viewer', play.viewerCount ?? 0)}
                        </button>
                    </div>
                )}
            </div>

            {menuOptions.length > 0 && !play.hidden && !hideDropdown ? (
                <DropdownMenu
                    options={menuOptions}
                    getMenuPosition={getMenuPosition}
                    DropdownComponent={isTeamPlaybook && !readOnly ? TeamItemsDropdown : null}
                    containerRef={dropdownMenuRef}
                    dropdownProps={{
                        allowedTo,
                        items: [play],
                        shareAudience: play.coachOnly ? ShareLevels.COACHES : ShareLevels.TEAM,
                        deleteClick,
                        unshareClick,
                        edit: onClick,
                        setShareAudience,
                    }}
                />
            ) : null}
        </div>
    );
};

export default PlayListPlay;
