import { AllErrors, Exception } from '@labradorsports/utils';

import MailTo from '../mailto/mailto.js';
import SimpleModal from '../simple-modal/simple-modal.js';

interface Props {
    error: Exception;
    onClose: AnyFunction;
    extraText?: string;
}

const ErrorDetails: FC<Props> = ({ error, onClose, extraText }) => {
    const errorDetails = AllErrors[error?.code];

    return (
        <SimpleModal open={Boolean(error)} onClose={onClose}>
            {error ? (
                <>
                    <p>
                        {errorDetails ? errorDetails.message : 'Something went wrong.'}
                        <br />
                        {extraText}
                    </p>
                    <p className="small">
                        If this keeps happening, please contact us at <MailTo email="support@labradorsports.com" />
                    </p>
                    <p className="small text-muted">
                        Error code: {error.code}-{error.id}
                    </p>
                </>
            ) : null}
        </SimpleModal>
    );
};

export default ErrorDetails;
