import { useSelector } from 'react-redux';

import { useDispatcher } from '../../shared/hooks/index.js';
import { useNavigateWithQuery } from '../../shared/providers/index.js';
import { playbookActions, ShareConfig, Selectors, playbookApi } from '../../store/index.js';

export default function useStartSharing() {
    const navigate = useNavigateWithQuery();
    const setSharing = useDispatcher(playbookActions.SetSharing);
    const activeTeam = useSelector(Selectors.activeTeam);
    const { data: { plays, folders } = {} } = playbookApi.endpoints.loadMyPlaybook.useQueryState(undefined);
    const { data: { shared, folders: teamFolders } = {} } = playbookApi.endpoints.loadTeamPlaybook.useQueryState({
        activeTeam,
    });

    return (shareConfig?: ShareConfig) => {
        setSharing(shareConfig);
        if (!shareConfig) {
            navigate('/playbook');
        } else if (shareConfig.myPlaybook) {
            navigate('/playbook/share/my/playbook');
        } else if (shareConfig.itemIds) {
            const [myPlayIds, teamPlayIds, myFolderIds, teamFolderIds] = shareConfig.itemIds.reduce(
                (acc, itemId) => {
                    if (plays?.some((play) => play.id === itemId)) {
                        return [acc[0].concat([itemId]), acc[1], acc[2], acc[3]];
                    }
                    if (shared?.some((play) => play.id === itemId)) {
                        return [acc[0], acc[1].concat([itemId]), acc[2], acc[3]];
                    }
                    if (folders?.some((folder) => folder.id === itemId)) {
                        return [acc[0], acc[1], acc[2].concat([itemId]), acc[3]];
                    }
                    if (teamFolders?.some((folder) => folder.id === itemId)) {
                        return [acc[0], acc[1], acc[2], acc[3].concat([itemId])];
                    }
                    return acc;
                },
                [[], [], [], []]
            );
            const isPlay = myPlayIds.length > 0 || teamPlayIds.length > 0;
            if (shareConfig.itemIds.length === 1 && isPlay) {
                if (teamPlayIds.length > 0) {
                    navigate('/playbook/share/team/play');
                } else {
                    navigate('/playbook/share/my/play');
                }
            } else {
                if (teamPlayIds.length > 0 || teamFolderIds.length > 0) {
                    navigate('/playbook/share/team/items/team');
                } else {
                    navigate('/playbook/share/my/items/team');
                }
            }
        }
    };
}
