import { FC } from 'react';
import { NavLink, useSearchParams, NavLinkProps } from 'react-router';

import { linkWithQuery } from '@labradorsports/utils';

type NavLinkWithQueryProps = Omit<NavLinkProps, 'to'> & {
    to: string;
};

export const NavLinkWithQuery: FC<NavLinkWithQueryProps> = ({ to, ...rest }) => {
    const [searchParams] = useSearchParams();

    return <NavLink to={linkWithQuery(to, searchParams)} {...rest} />;
};

export default NavLinkWithQuery;
