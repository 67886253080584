import { EventTypes, Plans, PlaybookTabs, ScheduleTabs } from '@labradorsports/constants';
import { objectMerge, objectSet, processParamConfig } from '@labradorsports/utils';

export const States = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
];

export const Provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

export const Countries = ['US', 'CA'];

export const CouponLimit = 1;

export const UnsavedPlayMessage =
    'You have unsaved changes. If you navigate away, these changes will be lost. Are you sure you want to continue?';

export const PlayerType = 'ROSTER_PLAYER';

export const AffiliateCouponCookieName = 'affiliateCode';

export const EventColorClasses = {
    [EventTypes.GAME]: 'event-type-game',
    [EventTypes.PRACTICE]: 'event-type-practice',
    [EventTypes.SCRIMMAGE]: 'event-type-scrimmage',
    [EventTypes.CUSTOM]: 'event-type-custom',
};

export const VideoDomains = ['youtube.com', 'youtu.be', 'vimeo.com', 'hudl.com'];

export const DataTags = {
    POST: 'Post',
    EVENT: 'Event',
    PLAY: 'Play',
    PLAYLIST_ITEM: 'PlaylistItem',
    FOLDER: 'Folder',
    FORMATION: 'Formation',
    NCAA_TEAM: 'NCAATeam',
    SCOUTING_REPORT: 'ScoutingReport',
    BILLING_ENTITY: 'BillingEntity',
    INVOICE: 'Invoice',
    PAYMENT_METHOD: 'PaymentMethod',
    BILLING_DETAILS: 'BillingDetails',
    PROFILE: 'Profile',
    PROFILE_IMAGE: 'ProfileImage',
    TEAM_DOCUMENT: 'TeamDocument',
    PRACTICE_TEMPLATE: 'PracticeTemplate',
    OWNED_TEAM: 'OwnedTeam',
    OWNED_PROGRAM: 'OwnedProgram',
    JOINED_TEAM: 'JoinedTeam',
    PENDING_TEAM: 'PendingTeam',
    ENTITY_LOGO: 'EntityLogo',
    TEAM_MEMBER: 'TeamMember',
    ROSTER_PERSON: 'RosterPerson',
    DEPTH_CHART: 'DepthChart',
    PLAYER_RATING: 'PlayerRating',
    VIDEO_DETAILS: 'VideoDetails',
    COMMUNITY_PLAY: 'CommunityPlay',
    COMMUNITY_COMMENT: 'CommunityComment',
    COMMUNITY_PROFILE: 'CommunityProfile',
    PENDING_ROSTER: 'PendingRoster',
} as const;

export const PlayType = 'PLAY';

// empty config objects ensure that the parent config is passed to the child paths
// TODO: improve typing
export const paramSetup = processParamConfig({
    global: {
        t: {
            stateKey: 'activeTeam',
            initialState: '',
        },
    },
    '/login': {
        d: { stateKey: 'deeplink', initialState: '' },
    },
    '/signup': {
        d: { stateKey: 'deeplink', initialState: '' },
    },
    '/payment/*': {
        l: {
            stateKey: 'invoiceLinkId',
            initialState: '',
        },
        e: {
            stateKey: 'invoiceError',
            initialState: '',
        },
    },
    '/playbook': [
        {},
        {
            '*': {
                p: {
                    stateKey: 'playId',
                    initialState: '',
                },
                sp: {
                    stateKey: 'shownPlaybook',
                    initialState: PlaybookTabs.MY,
                    options: {
                        parse: (value: string): string => {
                            // Only 3 valid values
                            if (value === PlaybookTabs.TEAM || value === PlaybookTabs.PUBLIC) return value;

                            return PlaybookTabs.MY;
                        },
                    },
                },
                f: {
                    stateKey: 'folderId',
                    initialState: '',
                },
                cpg: {
                    stateKey: 'communityPageNumber',
                    initialState: 1,
                    options: {
                        parse: Number,
                    },
                },
            },
            'usage/': {
                vd: {
                    stateKey: 'viewerDetailsPlayId',
                    initialState: '',
                },
            },
        },
    ],
    '/team/player/*': {
        p: {
            stateKey: 'rosterPlayerId',
            initialState: '',
        },
    },
    '/join-team': {
        k: { stateKey: 'joinTeamCode', initialState: '' },
        j: { stateKey: 'joinTeamId', initialState: '' },
    },
    '/email-action': {
        mode: { stateKey: 'emailAction.mode', initialState: '' },
        oobCode: { stateKey: 'emailAction.oobCode', initialState: '' },
        apiKey: { stateKey: 'emailAction.apiKey', initialState: '' },
        continueUrl: {
            stateKey: 'emailAction.continueUrl',
            initialState: '',
        },
    },
    '/profile/teamadmin': [
        {},
        {
            'create/': {
                plan: {
                    stateKey: 'billing.creatingBilling.plan',
                    initialState: '',
                    options: {
                        parse: (val: string): string => {
                            if (val in Plans) {
                                return val;
                            }

                            return '';
                        },
                    },
                },
            },
            'edit/*': {
                e: {
                    stateKey: 'editBillingEntityId',
                    initialState: '',
                },
            },
            'billing/': {
                e: {
                    stateKey: 'editBillingEntityId',
                    initialState: '',
                },
            },
        },
    ],
    '/profile/billing/team': {
        e: {
            stateKey: 'editBillingEntityId',
            initialState: '',
        },
    },
    '/schedule': [
        {
            st: {
                stateKey: 'shownScheduleTab',
                initialState: ScheduleTabs.FEED,
                options: {
                    parse: (value: string): string => {
                        // Only 2 valid values
                        if (value === ScheduleTabs.PRACTICE_TEMPLATES) return value;

                        return ScheduleTabs.FEED;
                    },
                },
            },
        },
        {
            'series/*': {},
            event: [
                {
                    ev: {
                        stateKey: 'viewEventId',
                        initialState: '',
                    },
                },
                {
                    '*': {},
                    'edit/*': {},
                    'scouting-report/*': {},
                },
            ],
            'practice/*': {
                p: {
                    stateKey: 'viewPracticeTemplateId',
                    initialState: '',
                },
            },
            scouting: [
                {
                    ev: {
                        stateKey: 'viewEventId',
                        initialState: '',
                    },
                },
                {
                    view: {},
                    'edit/*': {},
                },
            ],
        },
    ],
    '/schedule/update-rsvp': {
        r: {
            stateKey: 'viewEventId',
            initialState: '',
        },
        s: {
            stateKey: 'rsvpUpdateStatus',
            initialState: '',
        },
    },
    '/feed/post': {
        p: {
            stateKey: 'viewPostId',
            initialState: '',
        },
    },
    '/feed/documents/acks': {
        d: {
            stateKey: 'viewDocumentAcksId',
            initialState: '',
        },
    },
    '/play/*': {
        l: {
            stateKey: 'publicLinkId',
            initialState: '',
        },
    },
    '/community/latest': {
        pg: {
            stateKey: 'communityPageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
    '/community/popular': {
        pg: {
            stateKey: 'communityPageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
    '/community/trending': {
        pg: {
            stateKey: 'communityPageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
    '/community/play': {
        p: {
            stateKey: 'communityPlayId',
            initialState: '',
        },
    },
    '/community/profile': {
        pr: {
            stateKey: 'communityProfileId',
            initialState: '',
        },
    },
    '/community/search': {
        q: {
            stateKey: 'communitySearchText',
            initialState: '',
        },
        pg: {
            stateKey: 'communityPageNumber',
            initialState: 1,
            options: {
                parse: Number,
            },
        },
    },
});

export const baseParamState = Object.values(paramSetup).reduce((acc, pathConfig) => {
    const routeState = {};

    Object.values(pathConfig).forEach((paramConfig) => {
        objectSet(routeState, paramConfig.stateKey, paramConfig.initialState);
    });

    return objectMerge(acc, routeState);
}, {});

export const RosterPresets = {
    ALL: 'ALL',
    PLAYERS: 'PLAYERS',
    ALTERNATES: 'ALTERNATES',
} as const;

export type RosterPreset = typeof RosterPresets[keyof typeof RosterPresets];
