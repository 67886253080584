import {
    Assign,
    MovePlayer,
    PassBall,
    SetPick,
    Shoot,
    Comment,
    GroundBall,
    WaveMove,
    ArrowMoveIcon,
    PickMoveIcon,
    SquiggleMoveIcon,
    DrawRectangle,
    DrawEllipse,
    ZoneMove,
    ZoneIcon,
} from '@labradorsports/assets';

import PlayConfig from './play-config.js';

const { ToolTypes } = PlayConfig;

const Tools = {
    Move: {
        name: 'Move',
        type: ToolTypes.MOVE,
        icon: MovePlayer,
    },
    Move2: {
        name: 'Move 2',
        type: ToolTypes.MOVE2,
        icon: WaveMove,
    },
    Zone: {
        name: 'Zone',
        type: ToolTypes.ZONE,
        icon: ZoneMove,
    },
    Pick: {
        name: 'Pick',
        type: ToolTypes.PICK,
        icon: SetPick,
    },
    Pass: {
        name: 'Pass',
        icon: PassBall,
        type: ToolTypes.PASS,
    },
    Shoot: {
        name: 'Shoot',
        type: ToolTypes.SHOOT,
        icon: Shoot,
    },
    Option: {
        name: 'Option',
        type: ToolTypes.OPTION,
        icon: Assign,
    },
    Groundball: {
        name: 'Ground Ball',
        type: ToolTypes.GROUNDBALL,
        icon: GroundBall,
    },
    Assign: {
        name: 'Assign',
        type: ToolTypes.ASSIGN,
        icon: Assign,
    },
    Comment: {
        name: 'Comment',
        icon: Comment,
        type: ToolTypes.COMMENT,
    },
    Rectangle: {
        name: 'Rectangle',
        icon: DrawRectangle,
        type: ToolTypes.RECTANGLE,
    },
    Ellipse: {
        name: 'Ellipse',
        icon: DrawEllipse,
        type: ToolTypes.ELLIPSE,
    },
};

const drillToolGroups: any = [
    {
        ...Tools.Move,
        id: 'MOVE',
        tools: [Tools.Pick, Tools.Move2, Tools.Zone],
    },
    {
        ...Tools.Pass,
        id: 'OFFENSE_PASS',
        tools: [Tools.Shoot, Tools.Option, Tools.Groundball],
    },
    Tools.Assign,
    Tools.Comment,
];

// Not using pre-defined Tools.Move to separate OMOVE vs DMOVE
const offenseToolGroups: any = [
    {
        name: 'Move',
        id: 'OFFENSE_MOVE',
        type: ToolTypes.OMOVE,
        icon: MovePlayer,
        tools: [Tools.Pick, Tools.Move2],
    },
    {
        ...Tools.Pass,
        id: 'OFFENSE_PASS',
        tools: [Tools.Shoot, Tools.Option, Tools.Groundball],
    },
];

const defenseToolGroups = [
    {
        name: 'Move',
        id: 'DEFENSE_MOVE',
        type: ToolTypes.DMOVE,
        icon: MovePlayer,
        tools: [Tools.Zone],
    },
    Tools.Assign,
];

const otherToolGroups = [
    Tools.Comment,
    {
        name: 'Shapes',
        type: ToolTypes.RECTANGLE,
        icon: DrawRectangle,
        tools: [Tools.Rectangle, Tools.Ellipse],
    },
];

const moveShapes = [
    {
        type: ToolTypes.MOVE,
        icon: ArrowMoveIcon,
    },
    {
        type: ToolTypes.PICK,
        icon: PickMoveIcon,
    },
    {
        type: ToolTypes.MOVE2,
        icon: SquiggleMoveIcon,
    },
    {
        type: ToolTypes.ZONE,
        icon: ZoneIcon,
    },
];

export default {
    offenseToolGroups,
    defenseToolGroups,
    otherToolGroups,
    drillToolGroups,
    moveShapes,
};
