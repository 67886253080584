import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Selectors } from '../../../store/index.js';

const OnlineState = {
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE',
    BACK_ONLINE: 'BACK_ONLINE',
};

const OfflineBanner: FC = () => {
    const [state, setState] = useState(OnlineState.ONLINE);
    const timerRef = useRef<number>();
    const offline = useSelector(Selectors.offline);

    useEffect(() => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }

        if (offline) {
            setState(OnlineState.OFFLINE);
        } else if (state === OnlineState.OFFLINE) {
            setState(OnlineState.BACK_ONLINE);

            timerRef.current = window.setTimeout(() => {
                setState(OnlineState.ONLINE);
            }, 3000);
        }
    }, [offline]);

    if (state === OnlineState.ONLINE) {
        return null;
    }

    return (
        <div className="offline-banner">
            {state === OnlineState.OFFLINE ? (
                <div className="offline">You are offline. Some features may be limited.</div>
            ) : (
                <div className="back-online">You are back online!</div>
            )}
        </div>
    );
};

export default OfflineBanner;
