import { createContext, useContext, useMemo, useState } from 'react';

import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import {
    Move,
    findFurthestPoints,
    findBestPair,
    centerOfMass,
    arePointsSeparatedByLine,
    useFieldViewport,
    usePlayEditor,
    handleDrag,
    handleDragEnd,
    PluginContext,
    useSelected,
    generateAnchors,
    generateLines,
    generateSelectedAnchors,
    getDefaultMove,
    FrameSnapshot,
    PluginApi,
    angleBetweenPoints,
    getPresetCoords,
    distance,
    distanceFromLine,
    getMidpoint,
} from '@labradorsports/play-rendering';
import { createException, PlayEditorErrors } from '@labradorsports/utils';

import { useDispatcher } from '../../shared/hooks/index.js';
import { useLogger } from '../../shared/providers/index.js';
import { playEditorActions, setVideoLink, useSavePlayMutation } from '../../store/index.js';

// Find a better way to handle these props
export interface PluginsValue {
    api: PluginApi;
    updatePiece: (item: any, offset: FieldPoint, stroke?: Point[]) => void;
    handlePenStroke: (stroke: Point[][], rawStroke: Point[], closestStart: any, closestEnd: any) => void;
    handleDragEnd?: (anchor: Anchor, offset: FieldPoint) => void;
    handleDrag?: (anchor: Anchor, offset: FieldPoint) => FrameSnapshot[];
    getDefaultMove?: (type: string, offset: FieldPoint) => Move;
    pluginContext: PluginContext;
    plugins: PlayPlugin<PluginContext>[];
    anchors: Anchor[];
    lines: AnchorLine[];
    overlayItems: any[];
}

export const PluginsContext = createContext<PluginsValue>(null);

export const usePlugins = (): PluginsValue => {
    return useContext(PluginsContext);
};

export const PluginsProvider: FC = ({ children }) => {
    const [savePlay] = useSavePlayMutation();
    const updateMove = useDispatcher(playEditorActions.UpdateMove);
    const updatePass = useDispatcher(playEditorActions.UpdatePass);
    const updateAssignment = useDispatcher(playEditorActions.UpdateAssignment);
    const updatePlayer = useDispatcher(playEditorActions.UpdatePlayer);
    const updateOption = useDispatcher(playEditorActions.UpdateOption);
    const updateShot = useDispatcher(playEditorActions.UpdateShot);
    const updateFieldComment = useDispatcher(playEditorActions.UpdateFieldComment);
    const updateFieldPiece = useDispatcher(playEditorActions.UpdateFieldPiece);
    const moveInitialBall = useDispatcher(playEditorActions.MoveInitialBall);
    const updatePlayerRotation = useDispatcher(playEditorActions.UpdatePlayerRotation);
    const updateFrameRole = useDispatcher(playEditorActions.UpdateFrameRole);
    const removeMove = useDispatcher(playEditorActions.RemoveMove);
    const removeBallMovement = useDispatcher(playEditorActions.RemoveBallMovement);
    const updateMarkers = useDispatcher(playEditorActions.UpdateMarkers);
    const removeFieldPiece = useDispatcher(playEditorActions.RemoveFieldPiece);
    const _updateVideoLink = useDispatcher(setVideoLink);
    const updateFrameTheme = useDispatcher(playEditorActions.UpdateFrameTheme);
    const deletePlayer = useDispatcher(playEditorActions.DeletePlayer);
    const saveComment = useDispatcher(playEditorActions.SaveComment);

    const logger = useLogger();
    const { play, currentFrame, currentFrameIdx, PlayConfig, Plugins, debugOptions } = usePlayEditor();
    const { fieldViewport } = useFieldViewport();
    const { selected } = useSelected();
    const [overlayItems, setOverlayItems] = useState([]);
    const { MoveConfig, PlayTypes } = PlayConfig;

    const doUpdateMove = (move: Move, frameIdx?: number) => {
        const moveObj = move.toObj();
        delete moveObj.origin;

        updateMove({ ...moveObj, frameIdx });
    };

    const doUpdatePass = (pass: any, frameIdx?: number) => {
        updatePass({ ...pass, frameIdx });
    };

    const doUpdatePlayer = (playerId: number, updates: any) => {
        // Frame role and rotation are set per-frame, so must have special actions
        const { frameRole, rotation, ...rest } = updates;

        if (typeof frameRole !== 'undefined') {
            updateFrameRole(playerId, frameRole);
        }

        if (typeof rotation !== 'undefined') {
            updatePlayerRotation({
                id: playerId,
                rotation,
            });
        }

        // Player fields that can be updated on any frame
        const lateFrameUpdateAllowed = ['jersey', 'role', 'color', 'shape'];
        const restKeys = Object.keys(rest);
        if (restKeys.length > 0) {
            if (currentFrameIdx !== 0 && restKeys.some((key) => !lateFrameUpdateAllowed.includes(key))) {
                logger.exception(
                    createException(PlayEditorErrors.INVALID_PLAYER_UPDATE, {
                        details: JSON.stringify({
                            playerId,
                            updates,
                            currentFrameIdx,
                        }),
                    })
                );

                return;
            }

            updatePlayer({
                ...rest,
                id: playerId,
            });
        }
    };

    const doRemoveMove = (remove: Move) => {
        if (remove.type === ToolTypes.BALL) {
            removeBallMovement(remove.id);
        } else {
            removeMove(remove);
        }
    };

    const updateVideoLink = async (url: string) => {
        await _updateVideoLink(url);
        await savePlay({ autosave: true });
    };

    const debugEnabled = Object.values(debugOptions ?? {}).some(Boolean);

    const debug = {
        enabled: debugEnabled,
        points: (points) => {
            if (debugEnabled) {
                updateMarkers(points.filter(Boolean));
            }
        },
        lines: (lines) => {
            if (debugEnabled) {
                updateMarkers(lines.filter(Boolean));
            }
        },
        log: (msg: string, data: any) => {
            if (debugEnabled) {
                logger.log(msg, data);
            }
        },
    };

    const api = {
        updateFieldPiece,
        updatePlayer: doUpdatePlayer,
        updatePlayerRotation,
        updateMove: doUpdateMove,
        updateOption,
        updateAssignment,
        updatePass: doUpdatePass,
        updateShot,
        removeMove: doRemoveMove,
        removeBallMovement,
        moveInitialBall,
        updateVideoLink,
        removeFieldPiece,
        updateFieldComment,
        deletePlayer,
        saveComment,
        showOverlayItems: setOverlayItems,
        updateFrameTheme,
        debug,
    };

    const baseContext = {
        play,
        frame: currentFrame,
        fieldViewport,
        api,
    };

    const baseAnchors = useMemo(
        () => (fieldViewport && currentFrame ? generateAnchors(Plugins, baseContext) : []),
        [play, currentFrame, fieldViewport]
    );

    const lines = useMemo(
        () => (fieldViewport && currentFrame ? generateLines(Plugins, baseContext) : []),
        [play, currentFrame, fieldViewport]
    );

    const selectedAnchors = useMemo(
        () => (selected ? generateSelectedAnchors(Plugins, baseContext, selected) : []),
        [play, currentFrame, fieldViewport, selected]
    );

    const anchors = baseAnchors.concat(selectedAnchors);

    const pluginContext = {
        ...baseContext,
        anchors,
        lines,
    };

    const wrappedHandleDragEnd = (anchor: Anchor, offset: FieldPoint) => {
        handleDragEnd(Plugins, pluginContext, anchor, offset);

        if (debugEnabled) {
            updateMarkers();
        }
    };

    const wrappedHandleDrag = (anchor: Anchor, offset: FieldPoint) => {
        if (debugEnabled) {
            updateMarkers();
        }

        return handleDrag(Plugins, pluginContext, anchor, offset);
    };

    const wrappedGetDefaultMove = (type: string, offset: FieldPoint) => {
        return getDefaultMove(Plugins, pluginContext, type, offset);
    };

    // Used during move drop and anchor drag end updates
    const updatePiece = (item: Anchor, offset: FieldPoint, stroke?: ScreenPoint[]) => {
        if (MoveConfig[item.type]?.baseType === ToolBaseTypes.PRESET) {
            const { layout } = MoveConfig[item.type];

            getPresetCoords(layout, offset, play.field.width).forEach((coords, idx) => {
                updatePiece(
                    {
                        ...item,
                        type: layout[idx].type,
                        ...(layout[idx].props ?? {}),
                    },
                    coords
                );
            });
        } else if (item.type === SpecialAnchorTypes.PEN_PLAYER) {
            const move = currentFrame.moves.find((mv: any) => mv.target === item.target);
            const newMove = move.copy();
            if (!stroke || stroke.length < 3) {
                newMove.updateAnchor(0, offset, play.field);
            } else {
                newMove.updateAnchor(0, fieldViewport.getFieldCoords(stroke[1]), play.field);
                newMove.updateAnchor(1, offset, play.field);
            }
            doUpdateMove(newMove);
        } else {
            wrappedHandleDragEnd(item, offset);
        }
    };

    const handlePenStroke = (stroke: Point[][], rawStroke: Point[], closestStart: any, closestEnd: any) => {
        // Multiple strokes means either a pass or a shot
        if (stroke.length > 1) {
            const startPoint = stroke[0][0];
            const lastPart = stroke[stroke.length - 1];
            const endPoint = lastPart[lastPart.length - 1];

            const startBallCarrier = currentFrame.hasBall().find((plr) => plr.id === closestStart.target);

            if (
                closestStart.type === SpecialAnchorTypes.PEN_PLAYER &&
                distance(closestStart, startPoint) < 30 &&
                closestEnd.type === SpecialAnchorTypes.PEN_PLAYER &&
                distance(closestEnd, endPoint) < 30 &&
                startBallCarrier
            ) {
                const ball = currentFrame.getBallsByCarrier(startBallCarrier.id)[0];
                const startPlayer = currentFrame.getPlayer(closestStart.target);
                const endPlayer = currentFrame.getPlayer(closestEnd.target);
                const endPlayerPiece = currentFrame.getFieldPiece(closestEnd.pieceId);

                if (endPlayerPiece?.type === ToolTypes.GOAL) {
                    updateShot({
                        ball: ball.id,
                        type: ToolTypes.SHOOT,
                        target: closestEnd.target,
                    });
                } else if (play.type === PlayTypes.DRILL || startPlayer.type === endPlayer.type) {
                    updatePass({
                        ball: ball.id,
                        type: ToolTypes.PASS,
                        target: closestEnd.target,
                    });
                }
            }
        } else {
            const strokePart = stroke[0];

            if (strokePart.length < 2) {
                return;
            }

            const [startPoint, ...rest] = strokePart;
            const endPoint = rest[rest.length - 1];

            // Starting from a player means this is a move
            if (closestStart?.type === SpecialAnchorTypes.PEN_PLAYER && distance(closestStart, startPoint) < 30) {
                const newMove: any = {
                    type: ToolTypes.MOVE,
                    target: closestStart.target,
                    origin: fieldViewport.getFieldCoords(startPoint),
                };

                if (rest.length < 3) {
                    newMove.curved = false;
                    newMove.anchors = rest.map((pt) => fieldViewport.getFieldCoords(pt));
                } else {
                    // Use the furthest point from the line between the endpoints as the midpoint of the curve
                    // This can be thought of as the "peak" of the curve
                    const furthestPoint = rest.sort(
                        (a, b) =>
                            distanceFromLine(b, { a: startPoint, b: endPoint }) -
                            distanceFromLine(a, { a: startPoint, b: endPoint })
                    )[0];

                    newMove.anchors = [furthestPoint, endPoint].map((point) => fieldViewport.getFieldCoords(point));
                    newMove.curved = true;
                }

                updateMove(newMove);
            } else if (distance(startPoint, endPoint) < 30) {
                // Ending close to the start means we are drawing a closed shape
                if (rest.length < 4) {
                    // fewer than 4 points (excluding the start) means only 3 sides, AKA a triangle
                    return;
                }

                if (rest.length === 4) {
                    // 4 sides is a rectangle
                    const side1: [Point, Point] = [strokePart[0], strokePart[1]];
                    const side2: [Point, Point] = [strokePart[1], strokePart[2]];

                    const [widthSide, heightSide] =
                        distance(...side1) > distance(...side2) ? [side1, side2] : [side2, side1];

                    updateFieldPiece({
                        type: ToolTypes.RECTANGLE,
                        origin: fieldViewport.getFieldCoords(getMidpoint(strokePart[0], strokePart[2])),
                        width: fieldViewport.getFieldLength(distance(...widthSide)),
                        height: fieldViewport.getFieldLength(distance(...heightSide)),
                        rotation: angleBetweenPoints(...widthSide),
                    });
                } else {
                    // More than 4 sides is an ellipse
                    const center = centerOfMass(rawStroke);

                    // If any point in the simplified curve is on the same side of the line created by its neighbors as the center point
                    // This will not closely represent an ellipse and can be ignored
                    const isConcave = rest.some((point, index) => {
                        // Add length to enable negative wraparound with modulo
                        const prevPoint = rest[(rest.length + index - 1) % rest.length];
                        const nextPoint = rest[(index + 1) % rest.length];

                        const separated = arePointsSeparatedByLine(center, point, prevPoint, nextPoint);

                        if (separated) return false;

                        const centerDistance = distanceFromLine(center, { a: prevPoint, b: nextPoint });
                        const pointDistance = distanceFromLine(point, { a: prevPoint, b: nextPoint });

                        return pointDistance < centerDistance;
                    });

                    if (isConcave) {
                        return;
                    }

                    // Exclude the startPoint here because startPoint and endPoint can be considered as the same
                    const restRaw = rawStroke.slice(1);
                    const [leg1A, leg1B] = findFurthestPoints(restRaw);
                    const [leg2A, leg2B] = findBestPair(restRaw, (a, b) => {
                        const pointsSeparated = arePointsSeparatedByLine(a, b, leg1A, leg1B);

                        // If the points are both on the same side of leg 1, they are not a valid pair
                        if (!pointsSeparated) return 0;

                        const distA = distanceFromLine(a, { a: leg1A, b: leg1B });
                        const distB = distanceFromLine(b, { a: leg1A, b: leg1B });

                        return distA ** 2 + distB ** 2;
                    });

                    updateFieldPiece({
                        type: ToolTypes.ELLIPSE,
                        origin: fieldViewport.getFieldCoords(center),
                        width: fieldViewport.getFieldLength(distance(leg1A, leg1B)),
                        height: fieldViewport.getFieldLength(distance(leg2A, leg2B)),
                        rotation: angleBetweenPoints(leg1A, leg1B),
                    });
                }
            }
        }
    };

    return (
        <PluginsContext.Provider
            value={{
                api,
                anchors,
                lines,
                plugins: Plugins,
                pluginContext,
                handleDrag: wrappedHandleDrag,
                getDefaultMove: wrappedGetDefaultMove,
                updatePiece,
                handlePenStroke,
                overlayItems,
            }}
        >
            {children}
        </PluginsContext.Provider>
    );
};
