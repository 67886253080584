import { HudlLogo } from '@labradorsports/assets';
import {
    createException,
    findAddressComponents,
    GeneralErrors,
    getPlaceAPI,
    getVimeoVideoID,
    getYouTubeVideoID,
    isHudlUrl,
} from '@labradorsports/utils';

import { DataTags } from '../../shared/constants.js';
import { getAssetUrl } from '../../shared/utils.js';
import { createApiSlice } from '../api.js';
import { CustomQueryDefinition } from '../types.js';

interface PlaceResult {
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    place: {
        id: string;
        lat: number;
        lng: number;
    };
    name: string;
}

export const mainApi = createApiSlice({
    getVideoDetails: {
        type: 'query',
        extraOptions: {
            retryable: true,
        },
        isValid: ({ videoLink }) => Boolean(videoLink),
        queryFn: async ({ videoLink }, { extra }) => {
            const { cff } = extra;
            const hudlUrl = isHudlUrl(videoLink);
            const youtubeUrl = Boolean(getYouTubeVideoID(videoLink));
            const vimeoUrl = Boolean(getVimeoVideoID(videoLink));

            if (videoLink && hudlUrl) {
                return {
                    data: {
                        thumbnailUrl: getAssetUrl(HudlLogo),
                        title: 'Hudl Video',
                        description: '',
                    },
                };
            }

            if (youtubeUrl || vimeoUrl) {
                const videoDetails = await cff.fetch('main/getVideoDetails', { videoLink }, null, true);

                return {
                    data: videoDetails,
                };
            }

            return {
                data: null,
            };
        },
        providesTags: (result: any[], error, { videoLink }) => [{ type: DataTags.VIDEO_DETAILS, id: videoLink }],
    } as CustomQueryDefinition<{ videoLink: string }, any>,

    getVersion: {
        type: 'query',
        query: () => ({
            path: 'main/version',
        }),
        transformResponse: ({ version, deployed }) => {
            return [version, new Date(deployed)];
        },
    } as CustomQueryDefinition<void, any>,

    getGlobalSearch: {
        type: 'query',
        extraOptions: {
            unloggableArg: ['query'],
        },
        isValid: ({ query, playbookId }) => Boolean(query && playbookId),
        query: ({ query, playbookId, teamIds }) => ({
            path: 'search/global',
            query: {
                query,
                playbookId,
                teamIds: teamIds.join(','),
            },
        }),
        onQueryEnded: ({ data }, { extra }) => {
            const { logger } = extra;
            const { posts, events, roster, plays } = data;

            logger.log('getGlobalSearch complete', {
                posts: posts.length,
                events: events.length,
                roster: roster.length,
                plays: plays.length,
            });
        },
    } as CustomQueryDefinition<
        { query: string; playbookId: string; teamIds: string[] },
        { posts: []; events: []; roster: []; plays: [] }
    >,

    searchPlaces: {
        type: 'query',
        extraOptions: {
            unloggableArg: ['query'],
        },
        isValid: ({ query }) => Boolean(query),
        queryFn: async ({ query }) => {
            const request = {
                textQuery: query,
                fields: ['formattedAddress', 'addressComponents', 'location'],
                language: 'en-US',
                maxResultCount: 5,
                region: 'us',
            };

            const placeAPI = await getPlaceAPI();

            try {
                const { places } = await placeAPI.searchByText(request);

                return {
                    data: places.map((place) => ({
                        ...findAddressComponents(place),
                        place: {
                            id: place.id,
                            lat: place.location.lat(),
                            lng: place.location.lng(),
                        },
                        name: place.formattedAddress,
                    })),
                };
            } catch (err) {
                throw createException(GeneralErrors.GOOGLE_MAPS_ERROR, {
                    nestedError: err,
                    details: 'Error searching for places.',
                });
            }
        },
    } as CustomQueryDefinition<{ query: string }, PlaceResult[]>,
});

export const { useGetVideoDetailsQuery, useGetVersionQuery, useGetGlobalSearchQuery, useSearchPlacesQuery } = mainApi;
