import { FC } from 'react';
import { NavigateProps, Navigate, useSearchParams } from 'react-router';

import { linkWithQuery } from '@labradorsports/utils';

type NavigateWithQueryProps = Omit<NavigateProps, 'to'> & {
    to: string;
};

export const NavigateWithQuery: FC<NavigateWithQueryProps> = ({ to, ...rest }) => {
    const [searchParams] = useSearchParams();

    return <Navigate to={linkWithQuery(to, searchParams)} {...rest} />;
};

export default NavigateWithQuery;
