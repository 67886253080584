import { SpecialAnchorTypes, ToolBaseTypes } from '@labradorsports/constants';

import Move from '../models/move.js';
import { PluginContext } from '../plugins/api.js';

import { findClosestPiece } from './geometry.js';
import {
    defaultMove,
    calculatePartialMovement,
    findClosestPassablePoint,
    findClosestPointAlongMove,
    getMoveLines,
    getRestrictedZonePath,
    getCurvedMovePoints,
} from './moves.js';

export function generatePassAnchors({ play, frame }) {
    const anchors = [];

    if (!play.fieldSetup && frame.passes) {
        frame.passes.forEach((pass) => {
            anchors.push({
                type: pass.type,
                id: pass.id,
                target: pass.target,
                ...pass.anchors[0],
                space: 'screen' as const,
            });

            anchors.push({
                type: SpecialAnchorTypes.PASS_START,
                id: pass.id,
                target: pass.target,
                ...pass.origin,
                space: 'screen' as const,
            });
        });
    }

    return anchors;
}

export function generatePassSelectedAnchors({ play, frame }, selected) {
    const anchors = [];

    if (play.config.MoveConfig[selected.type].baseType === ToolBaseTypes.PASS) {
        if (!play.fieldSetup && frame.passes) {
            frame.passes.forEach((pass) => {
                anchors.push({
                    type: pass.type,
                    id: pass.id,
                    target: pass.target,
                    ...pass.anchors[0],
                    space: 'screen' as const,
                });

                anchors.push({
                    type: SpecialAnchorTypes.PASS_START,
                    id: pass.id,
                    target: pass.target,
                    ...pass.origin,
                    space: 'screen' as const,
                });
            });
        }
    }

    return anchors;
}

export function getDefaultPassMove({ play, lines, fieldViewport, frame }, type, offset) {
    const closestCarrier = findClosestPiece(offset, frame.hasBall());

    if (!closestCarrier) {
        return null;
    }

    if (frame.balls.length > 1) {
        return defaultMove(frame.field, closestCarrier, type);
    }

    const carrierBall = frame.getBallsByCarrier(closestCarrier.id)[0];
    const { closestPassable, percentage, closestMove } = findClosestPassablePoint(
        getMoveLines(play.config, lines, frame.idx),
        fieldViewport,
        frame,
        offset,
        type,
        carrierBall.id
    );

    if (!closestPassable) {
        return null;
    }

    const endpoint = closestMove ? calculatePartialMovement(closestMove, percentage) : closestPassable.origin;

    return new Move({
        type,
        origin: closestCarrier.origin,
        anchors: [endpoint],
    });
}

export function handlePassDrag(
    { play, lines, api, fieldViewport, frame }: PluginContext,
    anchor: Anchor,
    offset: FieldPoint
) {
    const pass = frame.passes.find((p) => p.id === anchor.id);

    if (anchor.type === SpecialAnchorTypes.PASS_START) {
        const pass = frame.passes.find((p) => p.id === anchor.id);
        const hasBall = frame.getPlayer(frame.getBall(pass.props.ball).props.carrier);
        const carrierMove = frame.getMove(hasBall.id);

        if (!carrierMove) {
            return;
        }

        const { percentage } = findClosestPointAlongMove(carrierMove, offset);
        const maxPercentage = pass.props?.end ?? 1;
        const finalPercentage = Math.min(percentage, maxPercentage);

        api.showOverlayItems([
            {
                points: getRestrictedZonePath(carrierMove, 1, maxPercentage),
                strokeWidth: 4,
                stroke: '#FF0000AA',
            },
        ]);

        frame.updatePass(anchor.id, {
            origin: calculatePartialMovement(carrierMove, finalPercentage),
        });
    } else {
        const {
            closestLineDistance,
            closestDistance,
            closestPoint,
            closestLine,
            closestPassable,
            percentage,
            closestMove,
        } = findClosestPassablePoint(
            getMoveLines(play.config, lines, frame.idx),
            fieldViewport,
            frame,
            offset,
            anchor.type,
            pass.props.ball
        );

        api.debug.points([
            offset,
            closestPoint ? fieldViewport.getFieldCoords(closestPoint) : null,
            closestLine ? fieldViewport.getFieldCoords(closestLine.a) : null,
            closestLine ? fieldViewport.getFieldCoords(closestLine.b) : null,
            ...(closestMove?.curved ? getCurvedMovePoints(closestMove) : []),
        ]);

        if (closestLineDistance < closestDistance) {
            const minPercentage = pass.props?.start ?? 0;
            const finalPercentage = Math.max(percentage, minPercentage);

            api.showOverlayItems([
                {
                    points: getRestrictedZonePath(closestMove, minPercentage, 0),
                    strokeWidth: 4,
                    stroke: '#FF0000AA',
                },
            ]);

            frame.updatePass(anchor.id, {
                targetId: closestPassable.id,
                endpoint: calculatePartialMovement(closestMove, finalPercentage),
            });
        } else {
            frame.updatePass(anchor.id, { targetId: closestPassable.id });
        }
    }
}

export function handlePassDragEnd(
    { api, play, lines, fieldViewport, frame }: PluginContext,
    anchor: Anchor,
    offset: FieldPoint
) {
    // Pass ID will use ball ID when left blank
    const closestCarrier = findClosestPiece(offset, frame.hasBall());
    const closestBalls = frame.getBallsByCarrier(closestCarrier.id);
    const pass = frame.passes?.find((p) => p.id === anchor.id);

    if (anchor.type === SpecialAnchorTypes.PASS_START) {
        const hasBall = frame.getPlayer(frame.getBall(pass.props.ball).props.carrier);
        const carrierMove = frame.getMove(hasBall.id);

        if (!carrierMove) {
            return;
        }

        const { percentage } = findClosestPointAlongMove(carrierMove, offset);
        const passEnd = pass.props?.end ?? 1;

        api.updatePass({
            id: anchor.id,
            start: Math.min(percentage, passEnd),
        });
    } else {
        // New passes
        if (typeof anchor.target === 'undefined' && frame.balls.length > 1) {
            const targetBall = closestBalls.find((ball) => !frame.passes?.some((pass) => pass.props.ball === ball.id));

            api.updatePass({
                type: anchor.type,
                ball: (targetBall ?? closestBalls[0]).id,
                target: null,
            });
        } else {
            const commonUpdate = {
                id: anchor.id,
                ball: pass?.props?.ball ?? closestBalls[0].id,
                type: anchor.type,
            };

            const { closestLineDistance, closestDistance, percentage, closestMove, closestPassable } =
                findClosestPassablePoint(
                    getMoveLines(play.config, lines, frame.idx),
                    fieldViewport,
                    frame,
                    offset,
                    anchor.type,
                    closestBalls[0].id
                );

            if (closestLineDistance < closestDistance) {
                const passStart = pass?.props?.start ?? 0;

                api.updatePass({
                    ...commonUpdate,
                    end: Math.max(percentage, passStart),
                    target: closestMove.target,
                });
            } else {
                api.updatePass({
                    ...commonUpdate,
                    target: closestPassable.id,
                });
            }
        }
    }
}
